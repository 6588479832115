import React, { useState, useEffect } from "react";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useParams } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { toast } from "react-toastify";

import axios from "axios";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from "react-grid-dnd";
import { TextField, Button } from "@material-ui/core";

let url = process.env.REACT_APP_API_URL;
function MobileView() {
  const param = useParams();
  const [allCategory, setAllCategory] = useState([]);
  const [product, setProduct] = useState([]);
  const [search, setSearchItem] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [category, setCategory] = useState([]);
  const [active, setActive] = useState();
  const [editActive, setEditActive] = useState();
  const [height, setHeight] = useState();
  const [heightRight, setHeightRight] = useState();
  const [rightItems,setRightItems]=useState([])
  const [searchValue, setSearchValue] = useState("");
  const [feildData, setfeildData] = useState("");

  const [items, setItems] = React.useState({
    left: [],
    right: [],
  });
  const postData = () => {
    if (allCategory.some((e) => e.id === "")) {
      toast.error("Please select the Product Or Delete The Category");
    } else {
      axios.post(`${url}/admin/update-ipad-layout-name`, {
        id: param.id,
        name: feildData,
      });
      toast.success("Updating layout...");
      axios
        .post(`${url}/admin/update-ipad-layout`, {
          id: param.id,
          categories: allCategory,
        })
        .then((res) => {
          toast.success("Layout updated successfully!", {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };
  useEffect(() => {
    axios.get(`${url}/admin/ipad-layout/${param.id}`).then((res) => {
      setfeildData(res.data.ipad_layout.name);

      if (res.data.message === "success.") {
        res.data.ipad_layout.categories.map((v, k) => {
          let c = allCategory;
          c.push({ id: v.id, name: v.name, products: [] });
          setAllCategory([...c]);

          v.product_list.map((p, i) => {
            let a = allCategory;
            a[k].products.push(p.id);
            setAllCategory([...a]);
          });
        });
      }
    });
  }, []);

  useEffect(() => {
    let check = false;
    if (allCategory.length > 0) {
      let check = allCategory.some((v) => v.id === "");
    }
    if (check === false) {
      // postData();
    }
  }, [allCategory]);

  useEffect(() => {
    let a = allCategory;
    let right = [];
    items.right.map((v) => {
      right.push(v.id);
    });
    if (a[active]?.products) {
      a[active].products = right;
      setAllCategory([...a]);
    }
    console.log(items?.left,items?.right,"hrer")
    setRightItems([...items?.right])
    setSearchItem([...items?.left]);

    // postData();
  }, [items]);
  useEffect(() => {
    setHeight(search?.length * 75);
    setHeightRight((5+rightItems.length)/5*75)
  }, [search,rightItems]);

  useEffect(() => {}, [allCategory]);

  useEffect(() => {
    let a = allCategory;
    if (a[active]?.products) {
      let left = product.filter((v) => !a[active]?.products.includes(v.id));
      let right = product.filter((v) => a[active]?.products.includes(v.id));
      setItems({ left: [...left], right: [...right] });
    }
  }, [active]);

  useEffect(() => {
    axios
      .get(
        `${url}/admin/get-product-list?limit=30&page=1&search=${searchValue}`
      )
      .then((res) => {
        let arr = [];
        res.data.products.rows.map((v, k) => {
          arr.push({ id: v.id, name: v.name });
        });
        setProduct([...arr]);
        setItems({ ...items, left: [...arr] });
      });
  }, [searchValue]);

  useEffect(() => {
    axios
      .get(`${url}/admin/get-category-list?limit=1000&page=1`)
      .then((res) => {
        let arr = [];
        res.data.categories.rows.map((v, k) => {
          arr.push({ id: v.id, name: v.name });
        });
        setCategory(arr);
      });
  }, []);

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    if (targetId) {
      const result = move(
        items[sourceId],
        items[targetId],
        sourceIndex,
        targetIndex
      );
      return setItems({
        ...items,
        [sourceId]: result[0],
        [targetId]: result[1],
      });
    }

    const result = swap(items[sourceId], sourceIndex, targetIndex);
    return setItems({
      ...items,
      [sourceId]: result,
    });
  }
  const addCategory = () => {
    setAllCategory([...allCategory, { id: "", name: "", products: [] }]);
  };
  const deleteHandler = (f) => {
    let obj = allCategory;
    obj.splice(f, 1);
    setAllCategory([...obj]);
  };
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const activeHandler = (id) => {
    if (id !== active) {
      setEditActive("");
      setActive(id);
    } else {
      setActive(id);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseMenu = (i, name, id) => {
    if (name === "" || id === "") {
    } else {
      setEditActive("");
      let c = allCategory;
      c[i].id = id;
      c[i].name = name;
      setAllCategory([...c]);
      setAnchorEl(null);
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          name="Product Name"
          size="small"
          margin="dense"
          id="currenct"
          placeholder="Search Product "
          type="text"
          style={{ width: "34%" }}
          variant="outlined"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
        <TextField
          size="small"
          margin="dense"
          id="currenct"
          style={{ width: "50%" }}
          variant="outlined"
          placeholder="Layout Name"
          type="text"
          fullWidth
          value={feildData}
          onChange={(e) => setfeildData(e.target.value)}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => postData()}
          style={{ height: "35px", borderRadius: "0px" }}
        >
          Update
        </Button>
      </div>
      <GridContextProvider onChange={onChange}>
        <div className="containerdropzone">
          <div
            style={{
              border: "1px solid rgba(0, 0, 0, 0.1)",

              height: "400px",
              overflowY: "scroll",
              overflowX: "hidden",
              width: "35%",
            }}
          >
            <GridDropZone
              style={{ height: `${height}px`, border: "0px" }}
              className="dropzone left"
              id="left"
              boxesPerRow={1}
              rowHeight={70}
            >
              {search.map((item) => (
                <GridItem key={item.id}>
                  <div className="grid-item" style={{ userSelect: "none" }}>
                    <div
                      className="grid-item-content"
                      style={{
                        width: "100%",
                        borderRadius: "0px",
                        background: "#E5E6E6",
                        color: "black",
                        // 1BC5BD green
                        // #B5B5C3 grey
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                </GridItem>
              ))}
            </GridDropZone>
          </div>
          <div
            style={{
              border: "1px solid rgba(0, 0, 0, 0.1)",
              height: "400px",
              overflowY: "scroll",
              overflowX: "hidden",
              width: "65%",
            }}
          >
            {" "}
            <GridDropZone
              className="dropzone right"
              id="right"
              boxesPerRow={4}
              rowHeight={70}
              style={{
                background: "#E4F0F2",
                width: "100%",
                height: `${heightRight<401?400:heightRight}px`,
              }}
            >
              {items.right.map((item) => (
                <GridItem key={item.id}>
                  <div className="grid-item" style={{ userSelect: "none" }}>
                    <div
                      className="grid-item-content"
                      style={{
                        width: "100%",
                        borderRadius: "0px",
                      }}
                    >
                      {item.name}
                    </div>
                  </div>
                </GridItem>
              ))}
            </GridDropZone>
          </div>
        </div>
      </GridContextProvider>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          overflowX: "scroll",
          width: "100%",
        }}
      >
        {allCategory?.map((v, k) => (
          <>
            <div
              onClick={() => activeHandler(k)}
              style={{
                display: "flex",
                paddingRight: "10px",
                border: "0.8px solid #D3D3D3",
                flex: "0 0 20em",
                padding: "10px",
                alignContent: "center",
                cursor: "pointer",
                justifyContent: "space-between",
                background: active === k ? "#3FB27F" : "",
                color: active === k ? "white" : "",
              }}
            >
              {editActive === k ? (
                <Autocomplete
                  // value={products.find(
                  //   (e) => variants[k].options[i].id === e.id
                  // )}
                  onChange={(event, newValue) => {
                    handleCloseMenu(k, newValue.name, newValue.id);
                  }}
                  size="small"
                  style={{
                    backgroundColor: "white",
                    width: "75%",
                    borderRadius: "0px",
                    height: "31px",
                  }}
                  id="combo-box-demo"
                  options={category}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => (
                    <p style={{ fontSize: "11.5px" }}>{option?.name}</p>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Category"
                      variant="outlined"
                    />
                  )}
                />
              ) : (
                <>{v.name}</>
              )}

              <div>
                <EditIcon
                  //   aria-label="more"
                  //   aria-haspopup="true"
                  onClick={(e) => {
                    e.stopPropagation();
                    setActive(k);
                    setEditActive(k);
                  }}
                />
                <DeleteIcon onClick={(e) => deleteHandler(k, e)} />
              </div>
            </div>
            {/* <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {category?.map((cv, ck) => (
                <MenuItem
                  onClick={() => handleCloseMenu(k, cv.name, cv.id)}
                  style={{ textTransform: "capitalize" }}
                >
                  {cv.name}
                </MenuItem>
              ))}
            </Menu> */}
          </>
        ))}
        <div
          onClick={addCategory}
          style={{
            display: "flex",
            paddingRight: "10px",
            border: "0.8px solid #D3D3D3",
            flex: "0 0 15em",
            padding: "10px",
            alignContent: "center",
            cursor: "pointer",
          }}
        >
          <AddIcon /> Add a Category
        </div>
      </div>
    </>
  );
}
export default MobileView;
