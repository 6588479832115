import React from "react";
import { withStyles } from "@material-ui/styles";

import { TableCell } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontSize: 13.5,
    paddingBottom: "10px !important",
  },
  body: {
    paddingBottom: "10px !important",
    paddingTop: "10px !important",

    fontSize: 12,
  },
}))(TableCell);
export default StyledTableCell;
