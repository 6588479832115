import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import clsx from "clsx";
import PropTypes from "prop-types";
import lighten from "@material-ui/core/styles/makeStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StyledTableCell from "../../component/styleCell";
import StyleTableRow from "../../component/styleRow";
import {
  IconButton,
  Tooltip,
  Typography,
  Paper,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

import axios from "axios";
import AlertDialogSlide from "../../component/DeleteModal/index";

function createData(
  id,
  firstName,
  lastName,
  email,
  birth_date,
  contact_no,
  address,
  address_2,
  member_id,
  city,
  state,
  post_code,
  customer_type
) {
  return {
    id,
    firstName,
    lastName,
    email,
    birth_date,
    contact_no,
    address,
    address_2,
    member_id,
    city,
    state,
    post_code,
    customer_type,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "name",
    numeric: true,
    center: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Email",
  },

  {
    id: "contactNo",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },

  {
    id: "city",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "City",
  },
  {
    id: "post_code",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Post Code",
  },
  {
    id: "Events",
    center: true,
    numeric: true,
    disablePadding: false,
    label: "Events ",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    length,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <StyledTableCell
            key={row.id}
            align={`${row.center === true ? "center" : "left"}`}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={length > 0 ? orderBy === row.id : ""}
              direction={length > 0 ? order : ""}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Customer
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <></>
        ) : (
          // <Tooltip title="Delete">
          //   <IconButton aria-label="Delete">
          //     <DeleteIcon />
          //   </IconButton>
          // </Tooltip>
          <Tooltip title="">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: "0px",
    height: "31px",
    fontSize: "11px",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    paddingLeft: "15px",
    paddingRight: "15px",
    overflowX: "auto",
  },
}));

function Customer() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const [rows, setRows] = useState([]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectId, setSelectId] = useState(null);
  const [feildData, setfeildData] = useState("");
  const [opens, setOpens] = useState(false);

  const [totalLength, setTotalLength] = useState(0);
  const [tag, setAllTags] = useState();
  const [selectedTag, setSelectedTag] = useState([]);
  const [formData, setformData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birth_date: new Date(),
    contact_no: "",
    address: "",
    address_2: "",
    member_id: "",
    city: "",
    state: "",
    post_code: "",
    customer_type: "self",
  });

  let url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);
  useEffect(() => {
    axios.get(`${url}/admin/get-tag-list?limit=1000&page=1`).then((res) => {
      if (res.data.tags.rows.length > 0) {
        let arr = [];
        res.data.tags.rows.map((v) => {
          arr.push({
            id: v.id,
            name: v.name,
          });
        });
        setAllTags(arr);
      }
    });
  }, []);
  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectId(null);
    setSelectedTag([]);

    setformData({
      firstName: "",
      lastName: "",
      email: "",
      birth_date: new Date(),
      contact_no: "",
      address: "",
      address_2: "",
      member_id: "",
      city: "",
      state: "",
      post_code: "",
      customer_type: "self",
    });
  };
  const getData = () => {
    axios
      .get(
        `${url}/admin/get-customers-list?limit=${rowsPerPage}&page=${parseInt(
          page + 1
        )}`
      )
      .then((res) => {
        if (res.data.customers.rows.length > 0) {
          let state = [];
          res.data.customers.rows.map((v, k) => {
            state.push(
              createData(
                v.id,
                v.firstName,
                v.lastName,
                v.email,
                v.birth_date,
                v.contact_no,
                v.address,
                v.address_2,
                v.member_id,
                v.city,
                v.state,
                v.post_code,
                v.customer_type
              )
            );
          });
          setRows(state);
          setTotalLength(res.data.customers.count);
        }
      });
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    if (event.target.value === 10) {
      if (page + 1 <= 1) {
        setPage(0);
      } else {
        setPage(page - 1);
      }
    } else if (event.target.value === 25) {
      if (page + 1 <= 5) {
        setPage(0);
      } else {
        setPage(page - 5);
      }
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleRequest = async () => {
    if (selectId !== null) {
      let tag = [];
      if (selectedTag.length > 0) {
        selectedTag.map((v, k) => {
          tag.push(v.id);
        });
      }
      if (formData.firstName === "") {
        toast.error("Please Enter First Name!");
      } else if (formData.lastName === "") {
        toast.error("Please Enter Last name!");
      } else if (formData.email === "") {
        toast.error("Email is Required!");
      } else if (formData.contact_no === "") {
        toast.error("Phone Number is Required!");
      } else if (formData.birth_date === "") {
        toast.error("Date Of Birth is Required!");
      } else if (formData.address === "") {
        toast.error("Please Enter your Address!");
      } else if (formData.city === "") {
        toast.error("City is Required!");
      } else if (formData.state === "") {
        toast.error("Please Enter Your State!");
      } else if (formData.member_id === "") {
        toast.error("Please Enter Your Member Id!");
      } else if (formData.member_id < 0) {
        toast.error("Please Enter Valid Member Id!");
      } else if (formData.post_code === "") {
        toast.error("Please Enter Your Post Code!");
      } else if (tag.length < 1) {
        toast.error("Please Select At Least One Tag!");
      } else {
        try {
          let obj = { ...formData, tag_ids: tag, id: selectId };
          axios
            .post(`${url}/admin/update-customer`, obj)
            .then((res) => {
              console.log(res);
            })
            .then((res) => {
              toast.success("Customer Updated Succesfully!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setOpen(false);

              setTimeout(() => {
                setformData({
                  firstName: "",
                  lastName: "",
                  email: "",
                  birth_date: new Date(),
                  contact_no: "",
                  address: "",
                  address_2: "",
                  member_id: "",
                  city: "",
                  state: "",
                  post_code: "",
                  customer_type: "self",
                });
                setSelectedTag([]);

                getData();
                setSelectId(null);
              }, 200);
            })
            .catch((err) => {
              var vals = [];
              console.log(err?.response.data?.errors, "eooo");
              if (err.response.data.errors) {
                vals = Object.keys(err.response.data.errors).map(function(key) {
                  let str = err.response.data.errors[key]
                    .replace("This", key)
                    .toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              } else {
                vals = Object.keys(err.response.data.message).map(function(
                  key
                ) {
                  let str = err.response.data.message.toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      try {
        let tag = [];
        selectedTag.map((v, k) => {
          tag.push(v.id);
        });
        if (formData.firstName === "") {
          toast.error("Please Enter First Name!");
        } else if (formData.lastName === "") {
          toast.error("Please Enter Last name!");
        } else if (formData.email === "") {
          toast.error("Email is Required!");
        } else if (formData.contact_no === "") {
          toast.error("Phone Number is Required!");
        } else if (formData.birth_date === "") {
          toast.error("Date Of Birth is Required!");
        } else if (formData.address === "") {
          toast.error("Please Enter your Address!");
        } else if (formData.city === "") {
          toast.error("City is Required!");
        } else if (formData.state === "") {
          toast.error("Please Enter Your State!");
        } else if (formData.member_id === "") {
          toast.error("Please Enter Your Member Id!");
        } else if (formData.member_id < 0) {
          toast.error("Please Enter Valid Member Id!");
        } else if (formData.post_code === "") {
          toast.error("Please Enter Your Post Code!");
        } else if (tag.length < 1) {
          toast.error("Please Select At Least One Tag!");
        } else {
          axios
            .post(`${url}/admin/create-customer`, {
              ...formData,
              tag_ids: tag,
            })
            .then((res) => {
              if (res.data.message === "customer created successfully.") {
                toast.success("Customer Created Succesfully!", {
                  position: "top-right",
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              setOpen(false);

              setTimeout(() => {
                setformData({
                  firstName: "",
                  lastName: "",
                  email: "",
                  birth_date: new Date(),
                  contact_no: "",
                  address: "",
                  address_2: "",
                  member_id: "",
                  city: "",
                  state: "",
                  post_code: "",
                  customer_type: "self",
                });
                setSelectedTag([]);

                getData();
              }, 200);
            })
            .catch((err) => {
              var vals = [];
              if (err.response.data.errors) {
                vals = Object.keys(err.response.data.errors).map(function(key) {
                  let str = err.response.data.errors[key]
                    .replace("This", key)
                    .toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              } else {
                vals = Object.keys(err.response.data.message).map(function(
                  key
                ) {
                  let str = err.response.data.message.toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              }
              toast.error(vals.length > 0 ? vals[0] : vals, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const updateHandler = async (row) => {
    setSelectId(row.id);
    axios.get(`${url}/admin/get-by-id-customer/${row.id}`).then((res) => {
      setOpen(true);

      let arr = [];
      res.data.customer.customer_tag.map((v, k) => {
        arr.push(v.tag_id);
      });
      if (arr.length > 0) {
        let updateApiTags = tag.filter((p) => arr.includes(p.id));
        setSelectedTag(updateApiTags);
      }
      setformData({
        firstName: res.data.customer.firstName,
        lastName: res.data.customer.lastName,
        email: res.data.customer.email,
        birth_date: res.data.customer.birth_date,
        contact_no: res.data.customer.contact_no,
        address: res.data.customer.address,
        address_2: res.data.customer.address_2,
        member_id: res.data.customer.member_id,
        city: res.data.customer.city,
        state: res.data.customer.state,
        post_code: res.data.customer.post_code,
        customer_type: "self",
      });
    });
  };

  const handleCloses = () => {
    setOpens(false);
    setSelectId(null);
  };
  const handleRequests = () => {
    axios
      .get(`${url}/admin/delete-customer/${selectId}`)
      .then((res) => {
        setOpens(false);
        toast.success("Customer Delete Succesfully!");
        setSelectId(null);
        setTimeout(() => getData(), 300);
      })
      .catch((err) => {
        toast.error("Unable to Delete Customer!");
      });
  };
  return (
    <>
      <AlertDialogSlide
        handleRequests={handleRequests}
        opens={opens}
        handleCloses={handleCloses}
        head="Customer"
        body="Are You Sure You Want to Delete Customer?"
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {selectId !== null ? "Update" : "Add"} Customer
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                First Name
              </div>
              <TextField
                name="firstName"
                value={formData.firstName}
                onChange={(e) => {
                  const re = /^[A-Za-z ]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    onChange(e);
                  }
                }}
                autoFocus
                size="small"
                margin="dense"
                id="currenct"
                placeholder="First Name"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Last Name
              </div>
              <TextField
                name="lastName"
                value={formData.lastName}
                onChange={(e) => {
                  const re = /^[A-Za-z ]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    onChange(e);
                  }
                }}
                margin="dense"
                id="currenct"
                placeholder="Last Name"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Email
              </div>
              <TextField
                name="email"
                value={formData.email}
                onChange={(e) => onChange(e)}
                margin="dense"
                id="currenct"
                placeholder="email"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Phone Number
              </div>
              <TextField
                name="contact_no"
                value={formData.contact_no}
                onChange={(e) => {
                  const re = /^[0-9\b ]+$/;
                  if (e.target.value.length < 15) {
                    if (e.target.value === "" || re.test(e.target.value)) {
                      onChange(e);
                    }
                  }
                }}
                margin="dense"
                id="currenct"
                placeholder="Number"
                type="number"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {" "}
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Birth Date
              </div>
              <TextField
                name="birth_date"
                value={formData.birth_date}
                onChange={(e) => onChange(e)}
                margin="dense"
                id="currenct"
                placeholder="date"
                type="date"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Address{" "}
              </div>
              <TextField
                name="address"
                value={formData.address}
                onChange={(e) => onChange(e)}
                margin="dense"
                id="currenct"
                placeholder="Address"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {" "}
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Address 2
              </div>
              <TextField
                name="address_2"
                value={formData.address_2}
                onChange={(e) => onChange(e)}
                margin="dense"
                id="currenct"
                placeholder=" Address 2"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {" "}
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                City{" "}
              </div>
              <TextField
                name="city"
                value={formData.city}
                onChange={(e) => {
                  const re = /^[A-Za-z ]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    onChange(e);
                  }
                }}
                margin="dense"
                id="currenct"
                placeholder="City"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {" "}
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                State
              </div>
              <TextField
                name="state"
                value={formData.state}
                onChange={(e) => onChange(e)}
                margin="dense"
                id="currenct"
                placeholder="State"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              {" "}
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Member Id
              </div>
              <TextField
                name="member_id"
                value={formData.member_id}
                onChange={(e) => onChange(e)}
                margin="dense"
                id="currenct"
                placeholder="Memeber"
                type="number"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              {" "}
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Post Code
              </div>
              <TextField
                name="post_code"
                value={formData.post_code}
                onChange={(e) => onChange(e)}
                margin="dense"
                id="currenct"
                placeholder="Post Code"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Cutomer Type
              </div>
              <Select
                disabled={true}
                name="customer_type"
                value={formData.customer_type}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder=" Cutomer Type"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                <MenuItem value="self" disabled>
                  Self
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Cutomer Tags
              </div>

              <Autocomplete
                value={selectedTag}
                onChange={(event, newValue) => {
                  setSelectedTag(newValue);
                }}
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
                multiple
                id="tags-outlined"
                options={tag}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                size="small"
                renderInput={(params) => (
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    {...params}
                    variant="outlined"
                    placeholder="Customer Tags"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: "25px" }}>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleRequest}
            color="primary"
            variant="contained"
            style={{ color: "white" }}
          >
            {selectId !== null ? "Update" : "ADD"}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <div className="p-5 d-flex justify-content-lg-between align-items-center ">
            <Typography variant="h5">Customers</Typography>
            <Button
              style={{ color: "white" }}
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Add New Customer
            </Button>
          </div>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                length={rows.length}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={totalLength}
              />
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyleTableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {row.firstName} {row.lastName}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.contact_no}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.city}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.post_code}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <button
                            onClick={() => updateHandler(row)}
                            class="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                            title="Edit details"
                            ng-click="UpdateData(role)"
                          >
                            <span class="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                  ></path>
                                  <path
                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button>
                          <button
                            class="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                            title="Delete"
                            onClick={() => {
                              setOpens(true);
                              setSelectId(row.id);
                            }}
                          >
                            <span class="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                  ></path>
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button>
                        </StyledTableCell>
                      </StyleTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalLength}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}

export default Customer;
