import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import StyledTableCell from "../../component/styleCell";
import StyleTableRow from "../../component/styleRow";
import clsx from "clsx";
import PropTypes from "prop-types";
import lighten from "@material-ui/core/styles/makeStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  IconButton,
  Tooltip,
  Typography,
  Paper,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import AlertDialogSlide from "../../component/DeleteModal/index";

import axios from "axios";
function createData(
  id,
  firstName,
  lastName,
  email,
  contactNo,
  role,
  storeName,

  pin,
  password,
  storeID
) {
  return {
    id,
    firstName,
    lastName,
    email,
    contactNo,
    role,
    storeName,
    pin,
    password,
    storeID,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  console.log(array);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "id",
    numeric: true,
    center: false,
    disablePadding: false,
    label: "Serial No.",
  },
  {
    id: "firstName",
    numeric: false,
    center: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Email",
  },

  {
    id: "phone_number",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },

  {
    id: "role",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "storeName",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Store",
  },
  {
    id: "Events",
    center: true,
    numeric: true,
    disablePadding: false,
    label: "Events ",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    length,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <StyledTableCell
            key={row.id}
            align={`${row.center === true ? "center" : "left"}`}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={length > 0 ? orderBy === row.id : ""}
              direction={length > 0 ? order : ""}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Employee
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <></>
        ) : (
          // <Tooltip title="Delete">
          //   <IconButton aria-label="Delete">
          //     <DeleteIcon />
          //   </IconButton>
          // </Tooltip>
          <Tooltip title="">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: "0px",
    height: "31px",
    fontSize: "11px",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    paddingLeft: "15px",
    paddingRight: "15px",
    overflowX: "auto",
  },
}));

function Employ() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = useState(false);

  const [rows, setRows] = useState([]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectId, setSelectId] = useState(null);
  const [feildData, setfeildData] = useState("");
  const [totalLength, setTotalLength] = useState(0);
  const [allStore, setAllStore] = useState();
  const [formData, setformData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    role: "",
    store_id: 0,
    password: "",
    pin: "",
  });

  let url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);
  useEffect(() => {
    axios.get(`${url}/admin/get-stores-list?limit=1000&page=1`).then((res) => {
      if (res.data.stores.rows.length > 0) {
        let arr = [];
        res.data.stores.rows.map((v) => {
          arr.push({
            id: v.id,
            name: v.name,
          });
        });
        setAllStore(arr);
      }
    });
  }, []);
  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectId(null);
    setformData({
      firstName: "",
      lastName: "",
      email: "",
      contactNo: "",
      role: "",
      store_id: "",
      password: "",
      pin: "",
    });
  };
  const getData = () => {
    axios
      .get(
        `${url}/admin/get-employes-list?limit=${rowsPerPage}&page=${parseInt(
          page + 1
        )}`
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.employes.rows.length > 0) {
          let state = [];
          res.data.employes.rows.map((v, k) => {
            state.push(
              createData(
                v.id,
                v.firstName,
                v.lastName,
                v.email,
                v.contactNo,
                v.role,
                v?.stores?.name,
                v.pin,
                v.password,
                v?.stores?.id
              )
            );
          });
          setRows(state);
          setTotalLength(res.data.employes.count);
        }
      });
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    if (event.target.value === 10) {
      if (page + 1 <= 1) {
        setPage(0);
      } else {
        setPage(page - 1);
      }
    } else if (event.target.value === 25) {
      console.log("heelloo");
      if (page + 1 <= 5) {
        setPage(0);
      } else {
        setPage(page - 5);
      }
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalLength - page * rowsPerPage);

  const handleRequest = async () => {
    if (selectId !== null) {
      try {
        if (formData.firstName === "") {
          toast.error("First Name is required !");
        } else if (formData.lastName === "") {
          toast.error("Last Name is required !");
        } else if (formData.contactNo === "") {
          toast.error("Phone Number is required !");
        } else if (formData.store_id === "") {
          toast.error("Please Select The Store !");
        } else if (formData.email === "") {
          toast.error("Email is required !");
        } else if (formData.role === "") {
          toast.error("Please Select The Role !");
        } else if (formData.pin === "") {
          toast.error("Pin is required !");
        } else if (formData.pin.toString().length < 4) {
          toast.error("Pin Length shoud be greater than 3 !");
        } else {
          let obj = { ...formData, employe_id: selectId };
          axios
            .post(`${url}/admin/update-employe`, obj)
            .then((res) => {
              if (res.data.message === "employe updated successfully.") {
                setOpen(false);

                setTimeout(() => {
                  getData();
                }, 200);
                setformData({
                  firstName: "",
                  lastName: "",
                  email: "",
                  contactNo: "",
                  role: "",
                  store_id: "",
                  password: "",
                  pin: "",
                });
                setSelectId(null);
                setOpen(false);
                toast.success("Employee updated succesffully!", {
                  position: "top-right",
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });

                setformData({
                  firstName: "",
                  lastName: "",
                  email: "",
                  contactNo: "",
                  role: "",
                  store_id: "",
                  password: "",
                  pin: "",
                });
              }
            })
            .catch((err) => {
              toast.error(err.response.data.message, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        if (formData.firstName === "") {
          toast.error("First Name is required !");
        } else if (formData.lastName === "") {
          toast.error("Last Name is required !");
        } else if (formData.contactNo === "") {
          toast.error("Phone Number is required !");
        } else if (formData.store_id === "") {
          toast.error("Please Select The Store !");
        } else if (formData.email === "") {
          toast.error("Email is required !");
        } else if (formData.role === "") {
          toast.error("Please Select The Role !");
        } else if (formData.password === "") {
          toast.error("Password is required !");
        } else if (formData.password.toString().length < 6) {
          toast.error("Password Length shoud be greater than 5 !");
        } else if (formData.pin === "") {
          toast.error("Pin is required !");
        } else if (formData.pin.toString().length < 4) {
          toast.error("Pin should be greater than 3 !");
        } else {
          axios
            .post(`${url}/admin/create-employe`, formData)
            .then((res) => {
              setformData({
                firstName: "",
                lastName: "",
                email: "",
                contactNo: "",
                role: "",
                store_id: "",
                password: "",
                pin: "",
              });
              setTimeout(() => {
                getData();
              }, 200);
              setOpen(false);
              setformData({
                firstName: "",
                lastName: "",
                email: "",
                contactNo: "",
                role: "",
                store_id: "",
                password: "",
                pin: "",
              });
              toast.success("Employee created Successfully!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              var vals = [];
              if (err.response.data.errors) {
                vals = Object.keys(err.response.data.errors).map(function(key) {
                  let str = err.response.data.errors[key]
                    .replace("This", key)
                    .toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              } else {
                vals = Object.keys(err.response.data.message).map(function(
                  key
                ) {
                  let str = err.response.data.message.toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              }
              toast.error(vals.length > 0 ? vals[0] : vals, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const updateHandler = async (row) => {
    setSelectId(row.id);
    console.log(row);

    setformData({
      firstName: row?.firstName,
      lastName: row?.lastName,
      email: row?.email,
      contactNo: row?.contactNo,
      role: row?.role === "admin" ? "" : row?.role,
      store_id: row?.role === "admin" ? "" : row?.storeID,
      pin: row?.pin,
    });
    setOpen(true);
  };
  const handleCloses = () => {
    setOpens(false);
    setSelectId(null);
  };
  const handleRequests = () => {
    axios
      .get(`${url}/admin/delete-employe/${selectId}`)
      .then((res) => {
        setOpens(false);
        toast.success("Employee Delete Succesfully!");
        setSelectId(null);
        setTimeout(() => getData(), 300);
      })
      .catch((err) => {
        toast.error("Unable to Delete Employee!");
      });
  };
  return (
    <>
      <AlertDialogSlide
        handleRequests={handleRequests}
        opens={opens}
        handleCloses={handleCloses}
        head="Employee"
        body="Are You Sure You Want to Delete Employee?"
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        // onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {selectId !== null ? "Update" : "Add"} Employee
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                First Name *
              </div>
              <TextField
                name="firstName"
                value={formData.firstName}
                onChange={(e) => {
                  const re = /^[A-Za-z ]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    onChange(e);
                  }
                }}
                inputProps={{ maxLength: 15 }}
                autoFocus
                size="small"
                margin="dense"
                id="currenct"
                placeholder="First Name"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Last Name *
              </div>
              <TextField
                name="lastName"
                value={formData.lastName}
                onChange={(e) => {
                  const re = /^[A-Za-z ]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    onChange(e);
                  }
                }}
                inputProps={{ maxLength: 15 }}
                margin="dense"
                id="currenct"
                placeholder="Last Name"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Phone Number *
              </div>
              <InputMask
                mask="+60 9-9999 9999"
                disabled={false}
                maskChar=" "
                value={formData.contactNo}
                onChange={(e) => {
                  onChange(e);
                }}
              >
                {() => (
                  <TextField
                    name="contactNo"
                    margin="dense"
                    id="currenct"
                    placeholder="Number"
                    type="text"
                    fullWidth
                    variant="outlined"
                    className=""
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Store *
              </div>
              <Select
                name="store_id"
                value={formData.store_id}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder="Role"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              >
                <MenuItem value={0} disabled>
                  Select Store
                </MenuItem>
                {allStore?.map((v, k) => (
                  <MenuItem value={v.id}>{v.name}</MenuItem>
                ))}
                {/* <MenuItem value="store manager">Store Manager</MenuItem>
                <MenuItem value="cashier">Cashier</MenuItem>
                <MenuItem value="back office">Back Office</MenuItem> */}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Email *
              </div>
              <TextField
                name="email"
                value={formData.email}
                onChange={(e) => onChange(e)}
                margin="dense"
                id="currenct"
                placeholder="Email"
                type="email"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Role *
              </div>
              <Select
                name="role"
                value={formData.role}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder="Role"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select Role *
                </MenuItem>{" "}
                <MenuItem value="store manager">Store Manager</MenuItem>
                <MenuItem value="cashier">Cashier</MenuItem>
                <MenuItem value="back office">Back Office</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Password *
              </div>
              <TextField
                name="password"
                value={formData.password}
                onChange={(e) => onChange(e)}
                margin="dense"
                id="currenct"
                placeholder="Password"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                PIN *
              </div>
              <TextField
                name="pin"
                value={formData.pin}
                onChange={(e) => onChange(e)}
                margin="dense"
                id="currenct"
                placeholder="PIN"
                type="number"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: "25px" }}>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleRequest}
            color="primary"
            variant="contained"
            style={{ color: "white" }}
          >
            {selectId !== null ? "Update" : "ADD"}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <div className="p-5 d-flex justify-content-lg-between align-items-center ">
            <Typography variant="h5">Employees</Typography>
            <Button
              style={{ color: "white" }}
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Add New Employee
            </Button>
          </div>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                length={rows.length}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={totalLength}
              />
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyleTableRow
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <span style={{ paddingLeft: "22px" }}>{row.id}</span>
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {row.firstName} {row.lastName}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.contactNo}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.role}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row?.storeName}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <button
                            onClick={() => updateHandler(row)}
                            class="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                            title="Edit details"
                            ng-click="UpdateData(role)"
                          >
                            <span class="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                  ></path>
                                  <path
                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button>
                          {/* <button
                            class="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                            title="Delete"
                            ng-click="Delete(role.srNo)"
                          >
                            <span class="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                  ></path>
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button> */}
                        </StyledTableCell>
                      </StyleTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalLength}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}

export default Employ;
