import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import CurrencyManagment from "./pages/CurrencyMangment/minCurrency";
import Taxes from "./pages/Taxes/tax.js";
import { DashboardPage } from "./pages/DashboardPage";
import MinCurrency from "./pages/minCurrency/minCurrency";
import Country from "./pages/Country/country";
import TimeZone from "./pages/TimeZone/timezone";
import CurrencyNote from "./pages/CurrencyNote/currencyNote";
import MeasureUnit from "./pages/MeasureUnit/measure";
import Printer from "./pages/PrinterMangment/printer";
import ThirdPartyCompany from "./pages/ThirdPartyCompany/ThirdPartyCompany";
import CategoryManagment from "./pages/CategoryManagment/categorymanagment";
import CutomerTag from "./pages/CutomerTag/CustomerTag";
import ProductTag from "./pages/ProductTag/ProductTag";
import Store from "./pages/Store/store";
import Employe from "./pages/Employe/employe";
import Supplier from "./pages/Supplier/supplier";
import Product from "./pages/Product/product";
import Customer from "./pages/Customer/customer";
import MobileView from "./pages/mobileView/mobileView";
import Mobile from "./pages/mobileView/mobile";
import Template from "./pages/ReceiptTemplates/ReceiptTemplate";
import PriceBook from "./pages/PriceBook/PriceBook";
import Promotion from "./pages/Promotions/Promotion";
import Purchase from "./pages/Purchase/purchase";
import AddPurchase from "./pages/Purchase/addPurchase";
import CustomerAds from "./pages/CustomerAds/CustomerAds";
import AccountSetting from "./pages/AccountSettings/account";
import StockReturn from "./pages/StockReturn/stockReturn";
import AddReturn from "./pages/StockReturn/addReturn";
import StockReceive from "./pages/StockReceive/stockReceive";
import AddReceive from "./pages/StockReceive/addReceive";
import SalesCash from "./pages/Reports/SalesCash";
import SalesShift from "./pages/Reports/SalesShift";
import SalesShiftTransaction from "./pages/Reports/SalesShiftTransaction";
import CategoryProduct from "./pages/Reports/SalesCategoryProduct";
import SalesProduct from "./pages/Reports/SalesProductReport";
import SalesEmployee from "./pages/Reports/SalesEmployReport";
import ProductStock from "./pages/Reports/ProductStock";
import PermotionReport from "./pages/Reports/PermotionReport";
import SalesProductVariant from "./pages/Reports/SalesProductVariantProduct";
import StockTransfer from "./pages/StockTransfer/stockTransfer";
import AddTransfer from "./pages/StockTransfer/addTransfer";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/admin/dashboard" />
        }
        <ContentRoute path="/admin/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Route
          path="/admin/finance/currencytags"
          component={CurrencyManagment}
        />
        <Route path="/admin/finance/mincurrency" component={MinCurrency} />
        <Route path="/admin/finance/taxes" component={Taxes} />
        <Route path="/admin/country" component={Country} />
        <Route path="/admin/timezone" component={TimeZone} />
        <Route path="/admin/finance/currencynote" component={CurrencyNote} />
        <Route path="/admin/measure" component={MeasureUnit} />
        <Route path="/admin/printer" component={Printer} />
        <Route path="/admin/thirdparty" component={ThirdPartyCompany} />
        <Route path="/admin/categorymanagment" component={CategoryManagment} />
        <Route path="/admin/cutomertag" component={CutomerTag} />
        <Route path="/admin/product-tag" component={ProductTag} />
        <Route path="/admin/store" component={Store} />
        <Route path="/admin/employe" component={Employe} />
        <Route path="/admin/supplier" component={Supplier} />
        <Route path="/admin/products" component={Product} />
        <Route exact path="/admin/mobileview" component={Mobile} />
        <Route exact path="/admin/mobileview/:id" component={MobileView} />
        <Route path="/admin/template" component={Template} />
        <Route path="/admin/customer" component={Customer} />
        <Route path="/admin/pricebook" component={PriceBook} />
        <Route path="/admin/promotion" component={Promotion} />
        <Route exact path="/admin/allpurchase" component={Purchase} />

        <Route exact path="/admin/purchase" component={AddPurchase} />
        <Route exact path="/admin/purchase/:id" component={AddPurchase} />

        <Route path="/admin/customerads" component={CustomerAds} />
        <Route path="/admin/account_setting" component={AccountSetting} />
        <Route exact path="/admin/allreturn" component={StockReturn} />

        <Route exact path="/admin/return" component={AddReturn} />
        <Route exact path="/admin/return/:id" component={AddReturn} />
        <Route exact path="/admin/allreceive" component={StockReceive} />
        <Route exact path="/admin/receive" component={AddReceive} />
        <Route exact path="/admin/receive/:id" component={AddReceive} />
        <Route exact path="/admin/alltransfer" component={StockTransfer} />
        <Route exact path="/admin/transfer" component={AddTransfer} />
        <Route exact path="/admin/transfer/:id" component={AddTransfer} />
        {/* <Route exact path="/admin/salescash" component={SalesCash} /> */}
        <Route exact path="/admin/sales_shift" component={SalesShift} />
        <Route exact path="/admin/sales_cash" component={SalesCash} />
        <Route
          exact
          path="/admin/sales_shift_transction"
          component={SalesShiftTransaction}
        />
        <Route
          exact
          path="/admin/sales_category_product"
          component={CategoryProduct}
        />
        <Route exact path="/admin/sales_product" component={SalesProduct} />
        <Route exact path="/admin/sales_employee" component={SalesEmployee} />
        <Route exact path="/admin/salesProductStock" component={ProductStock} />
        <Route
          exact
          path="/admin/product_permotion"
          component={PermotionReport}
        />
        <Route
          exact
          path="/admin/salesProductVariant"
          component={SalesProductVariant}
        />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
