import React, { useEffect, useState } from "react";
import moment from "moment";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StyledTableCell from "../../component/styleCell";
import StyleTableRow from "../../component/styleRow";
import clsx from "clsx";
import PropTypes from "prop-types";
import lighten from "@material-ui/core/styles/makeStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  IconButton,
  Tooltip,
  Typography,
  Paper,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  MenuItem,
  Checkbox,
  Avatar,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { toast } from "react-toastify";

function createData(id, name, code, start_date, end_date) {
  return { id, name, code, start_date, end_date };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "id",
    numeric: true,
    center: false,
    disablePadding: false,
    label: "Id",
  },
  {
    id: "name",
    numeric: true,
    center: false,
    disablePadding: false,
    label: "Name",
  },
  { id: "code", numeric: true, disablePadding: false, label: "Code" },
  {
    id: "start_date",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Start Date",
  },
  {
    id: "end_date",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "End Date",
  },
  {
    id: "Events",
    center: true,
    numeric: true,
    disablePadding: false,
    label: "Events ",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    length,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <StyledTableCell
            key={row.id}
            align={`${row.center === true ? "center" : "left"}`}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={length > 0 ? orderBy === row.id : ""}
              direction={length > 0 ? order : ""}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Promotion
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <></>
        ) : (
          // <Tooltip title="Delete">
          //   <IconButton aria-label="Delete">
          //     <DeleteIcon />
          //   </IconButton>
          // </Tooltip>
          <Tooltip title="">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: "1px",
    height: "31px",
    fontSize: "11px",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    paddingLeft: "15px",
    paddingRight: "15px",
    overflowX: "auto",
  },
}));

function Promotion() {
  const [isMyInputFocused, setIsMyInputFocused] = useState(false);

  const classes = useStyles();
  const searchInput = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  const [rows, setRows] = useState([]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectId, setSelectId] = useState(null);
  const [totalLength, setTotalLength] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [sendingStore, setSendingStore] = useState([]);
  const [sendingProductCategory, setSendingProductCategory] = useState([]);

  const [sendingProductTags, setSendingProductTags] = useState([]);
  const [sendingSpecificProduct, setSendingSpecificProduct] = useState([]);

  const [formData, setformData] = useState({
    name: "",
    code: "",
    enable: 0,
    start_date: "",
    end_date: "",
    all_days: "",
    sunday: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    time_from: "",
    time_to: "",
    discount_type: "",
    percentage: "",
    amount: "",
    apply_to_type: "",
    order_min_amount: "",
    min_quantity: "",
    max_quantity: "",
    price: "",
    tax_id: "",
    inclusive_price: "",
    buy_quantity: "",
    free_quantity: "",
    store_ids: [],
    category_ids: [],
    product_tag_ids: [],
    product_ids: [],
    combo_products: [],
  });
  const [category, setAllCategory] = useState([]);
  const [productTag, setProductTags] = useState([]);
  const [allStore, setAllStore] = useState([]);

  const [days, setDays] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [products, setAllProduct] = useState([]);

  const [allTax, setAllTax] = useState([]);
  const [multipleProduct, setMultipleProduct] = useState([]);

  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  let url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    setformData({
      ...formData,
      inclusive_price:
        parseInt(formData.price) +
        (parseInt(formData.price) * parseInt(percentage ? percentage : 0)) /
          100,
    });
  }, [percentage]);
  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);
  useEffect(() => {
    axios.get(`${url}/admin/get-stores-list?limit=1000&page=1`).then((res) => {
      if (res.data.stores.rows.length > 0) {
        let arr = [];
        res.data.stores.rows.map((v, k) => {
          arr.push({ id: v.id, name: v.name });
        });
        setAllStore([...arr]);
      }
    });
  }, []);
  useEffect(() => {
    axios.get(`${url}/admin/get-product-list?limit=1000&page=1`).then((res) => {
      if (res.data.products.rows.length > 0) {
        let arr = [];
        res.data.products.rows.map((v, k) => {
          arr.push({ id: v.id, name: v.name });
        });
        setAllProduct([...arr]);
      }
    });
  }, []);

  useEffect(() => {
    axios
      .get(`${url}/admin/get-category-list?limit=1000&page=1`)
      .then((res) => {
        if (res.data.categories.rows.length > 0) {
          let arr = [];
          res.data.categories.rows.map((v, k) => {
            arr.push({ id: v.id, name: v.name });
          });
          setAllCategory([...arr]);
        }
      });
  }, []);
  useEffect(() => {
    axios.get(`${url}/admin/get-tax-list?limit=1000&page=1`).then((res) => {
      if (res.data.taxes.rows.length > 0) {
        let arr = [];
        res.data.taxes.rows.map((v, k) => {
          arr.push({
            id: v.id,
            tax_category: v.tax_category,
            percentage: v.percentage,
          });
        });
        setAllTax([...arr]);
      }
    });
  }, []);
  useEffect(() => {
    axios.get(`${url}/admin/prod-tag-list?limit=1000&page=1`).then((res) => {
      if (res.data.prod_tags.rows.length > 0) {
        let arr = [];
        res.data.prod_tags.rows.map((v, k) => {
          arr.push({ id: v.id, name: v.name });
        });
        setProductTags([...arr]);
      }
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setformData({
      name: "",
      code: "",
      enable: 0,
      start_date: "",
      end_date: "",
      all_days: "",
      sunday: "",
      monday: "",
      tuesday: "",
      wednesday: "",
      thursday: "",
      friday: "",
      saturday: "",
      time_from: "",
      time_to: "",
      discount_type: "",
      percentage: 0,
      amount: 0,
      apply_to_type: "",
      order_min_amount: 0,
      min_quantity: 0,
      max_quantity: 0,
      price: 0,
      tax_id: 0,
      inclusive_price: 0,
      buy_quantity: 0,
      free_quantity: 0,
      store_ids: [],
      category_ids: [],
      product_tag_ids: [],
      product_ids: [],
      combo_products: [],
    });
    setSelectedDays([]);
    setSendingSpecificProduct([]);
    setSendingStore([]);
    setSendingProductCategory([]);
    setSendingProductTags([]);
    setSelectId(null);
  };

  const getData = () => {
    axios
      .get(
        `${url}/admin/get-promotion-list?limit=${rowsPerPage}&page=${parseInt(
          page + 1
        )}`
      )
      .then((res) => {
        if (res?.data?.promotion?.rows.length > 0) {
          let state = [];
          res.data.promotion.rows.map((v, k) => {
            state.push(
              createData(v.id, v.name, v.code, v.start_date, v.end_date)
            );
          });
          setRows(state);
          setTotalLength(res.data.promotion.count);
        }
      });
  };
  const addProduct = () => {
    setMultipleProduct([...multipleProduct, { product_id: "", quantity: "" }]);
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    if (event.target.value === 10) {
      if (page + 1 <= 1) {
        setPage(0);
      } else {
        setPage(page - 1);
      }
    } else if (event.target.value === 25) {
      if (page + 1 <= 5) {
        setPage(0);
      } else {
        setPage(page - 5);
      }
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleRequest = async () => {
    if (selectId !== null) {
      console.log(formData.apply_to_type, formData.inclusive_price);
      if (formData.name === "") {
        toast.error("Please Enter a Name!");
      } else if (formData.code === "") {
        toast.error("Please Enter a Code!");
      } else if (sendingStore.length < 1) {
        toast.error("Please Select At Least One Store!");
      } else if (formData.start_date === "") {
        toast.error("Please Select a Start Date!");
      } else if (formData.end_date === "") {
        toast.error("Please Select a End Date!");
      } else if (new Date(formData.start_date) > new Date(formData.end_date)) {
        toast.error("End Date Should Be Greater Than Start Date!");
      } else if (selectedDays.length === 0) {
        toast.error("Please Select a Effect On Every! ");
      } else if (formData.time_from === "") {
        toast.error("Please Select a Time From! ");
      } else if (formData.time_to === "") {
        toast.error("Please Select a Time To! ");
      } else if (formData.discount_type === "") {
        toast.error("Please Select Disscount Value!");
      } else if (
        (formData.discount_type === "override-exist-price" &&
          formData.inclusive_price === "") ||
        (formData.discount_type === "override-exist-price" &&
          isNaN(formData.inclusive_price))
      ) {
        toast.error("Please Enter Inclusive Price");
      } else if (
        formData.discount_type === "override-exist-price" &&
        formData.tax_id === ""
      ) {
        toast.error("Please Select Tax!");
      } else if (
        (formData.discount_type === "combo-set" &&
          formData.inclusive_price === "") ||
        (formData.discount_type === "combo-set" &&
          isNaN(formData.inclusive_price))
      ) {
        toast.error("Please Enter Inclusive Price");
      } else if (
        formData.discount_type === "combo-set" &&
        formData.tax_id === ""
      ) {
        toast.error("Please Select Tax!");
      } else if (
        formData.discount_type === "override-exist-price" &&
        formData.apply_to_type === ""
      ) {
        toast.error("Please Select Option from Apply To!");
      } else if (
        (formData.discount_type === "amount-off" && formData.amount === "") ||
        (formData.discount_type === "amount-off" && formData.amount === null)
      ) {
        toast.error("Please Enter Amount!");
      } else if (
        formData.discount_type === "percentage-off" &&
        formData.percentage === ""
      ) {
        toast.error("Please Enter Percentage!");
      } else if (
        formData.discount_type === "percentage-off" &&
        formData.apply_to_type === ""
      ) {
        toast.error("Please Select Option from Apply To!");
      }

      //
      else if (
        formData.apply_to_type === "products-of-category" &&
        sendingProductCategory.length < 1
      ) {
        toast.error("Please Select Product with Category!");
      }
      //
      else if (
        formData.apply_to_type === "products-of-category" &&
        formData.min_quantity === ""
      ) {
        toast.error("Please Enter Min Quantity!");
      } else if (
        formData.apply_to_type === "products-of-category" &&
        formData.max_quantity === ""
      ) {
        toast.error("Please Enter Max Quantity!");
      } else if (
        formData.apply_to_type === "products-of-category" &&
        parseInt(formData.min_quantity) > parseInt(formData.max_quantity)
      ) {
        toast.error("Max Quantity Should be greater than Min!");
      } else if (
        formData.apply_to_type === "products-of-tags" &&
        sendingProductTags.length < 1
      ) {
        toast.error("Please Select Tags!");
      } else if (
        (formData.apply_to_type === "products-of-tags" &&
          formData.min_quantity === null) ||
        (formData.apply_to_type === "products-of-tags" &&
          formData.min_quantity === "")
      ) {
        toast.error("Please Enter Min Quantity!");
      } else if (
        (formData.apply_to_type === "products-of-tags" &&
          formData.max_quantity === null) ||
        (formData.apply_to_type === "products-of-tags" &&
          formData.max_quantity === "")
      ) {
        toast.error("Please Enter Max Quantity!");
      } else if (
        formData.apply_to_type === "specific-products" &&
        sendingSpecificProduct.length < 1
      ) {
        toast.error("Please Select Specific Products!");
      } else if (
        formData.apply_to_type === "products-of-tags" &&
        parseInt(formData.min_quantity) > parseInt(formData.max_quantity)
      ) {
        toast.error("Max Quantity Should be greater than Min!");
      } else if (
        (formData.apply_to_type === "specific-products" &&
          formData.min_quantity === null) ||
        (formData.apply_to_type === "specifix-products" &&
          formData.min_quantity === "")
      ) {
        toast.error("Please Enter Min Quantity!");
      } else if (
        formData.apply_to_type === "specific-products" &&
        formData.max_quantity === null &&
        formData.apply_to_type === "specific-products" &&
        formData.max_quantity === ""
      ) {
        toast.error("Please Enter Max Quantity!");
      } else if (
        formData.apply_to_type === "specific-products" &&
        parseInt(formData.min_quantity) > parseInt(formData.max_quantity)
      ) {
        toast.error("Max Quantity Should be greater than Min!");
      } else if (
        (formData.apply_to_type === "orders-over-or-equal-to" &&
          formData.order_min_amount === "") ||
        (formData.apply_to_type === "orders-over-or-equal-to" &&
          formData.order_min_amount === null)
      ) {
        toast.error("Please Enter Min Quantity Order!");
      } else if (
        (formData.discount_type === "override-exist-price" &&
          formData.min_quantity === "") ||
        (formData.discount_type === "override-exist-price" &&
          formData.max_quantity === "")
      ) {
        toast.error("Please Enter Both Minimum And Maximum Quantiy");
      } else {
        try {
          let s = [...selectedDays];
          let obj = {
            name: formData.name,
            code: formData.code,
            enable: formData.enable,
            start_date: formData.start_date,
            end_date: formData.end_date,
            all_days: 0,
            sunday: s.length === 0 || s.includes("Sunday") ? 1 : 0,
            monday: s.length === 0 || s.includes("Monday") ? 1 : 0,
            tuesday: s.length === 0 || s.includes("Tuesday") ? 1 : 0,
            wednesday: s.length === 0 || s.includes("Wednesday") ? 1 : 0,
            thursday: s.length === 0 || s.includes("Thursday") ? 1 : 0,
            friday: s.length === 0 || s.includes("Friday") ? 1 : 0,
            saturday: s.length === 0 || s.includes("Saturday") ? 1 : 0,
            time_from: formData.time_from,
            time_to: formData.time_to,
            discount_type: formData.discount_type,
            percentage: parseInt(formData.percentage),
            amount: parseInt(formData.amount),
            apply_to_type: formData.apply_to_type,
            order_min_amount: parseInt(formData.order_min_amount),
            min_quantity: parseInt(formData.min_quantity),
            max_quantity: parseInt(formData.max_quantity),
            price: parseInt(formData.price),
            tax_id: parseInt(formData.tax_id),
            inclusive_price: formData.inclusive_price,
            buy_quantity: parseInt(formData.buy_quantity),
            free_quantity: parseInt(formData.free_quantity),
            store_ids: [],
            category_ids: [],
            product_tag_ids: [],
            product_ids: [],
            combo_products: [...multipleProduct],
          };
          let category = sendingProductCategory;
          category.map((v, k) => {
            obj.category_ids.push(v.id);
          });
          let store = sendingStore;
          store.map((v, k) => {
            obj.store_ids.push(v.id);
          });
          let ptags = sendingProductTags;
          ptags.map((v, k) => {
            obj.product_tag_ids.push(v.id);
          });
          let pro = sendingSpecificProduct;
          pro.map((v, k) => {
            obj.product_ids.push(v.id);
          });
          axios
            .post(`${url}/admin/update-promotion`, { ...obj, id: selectId })
            .then(async (res) => {
              toast.success("Promotion Updates successfully");
              setOpen(false);
              await setformData({
                name: "",
                code: "",
                enable: 0,
                start_date: "",
                end_date: "",
                all_days: "",
                sunday: "",
                monday: "",
                tuesday: "",
                wednesday: "",
                thursday: "",
                friday: "",
                saturday: "",
                time_from: "",
                time_to: "",
                discount_type: "",
                percentage: 0,
                amount: 0,
                apply_to_type: "",
                order_min_amount: 0,
                min_quantity: 0,
                max_quantity: 0,
                price: 0,
                tax_id: 0,
                inclusive_price: 0,
                buy_quantity: 0,
                free_quantity: 0,
                store_ids: [],
                category_ids: [],
                product_tag_ids: [],
                product_ids: [],
                combo_products: [],
              });
              setSelectedDays([]);
              setSendingSpecificProduct([]);
              setSendingStore([]);
              setSendingProductCategory([]);
              setSendingProductTags([]);
              setTimeout(() => getData(), 200);
              setSelectId(null);
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    } else {
      if (formData.name === "") {
        toast.error("Please Enter a Name!");
      } else if (formData.code === "") {
        toast.error("Please Enter a Code!");
      } else if (formData.start_date === "") {
        toast.error("Please Select a Start Date!");
      } else if (formData.end_date === "") {
        toast.error("Please Select a End Date!");
      } else if (new Date(formData.start_date) > new Date(formData.end_date)) {
        toast.error("End Date Should Be Greater Than Start Date!");
      } else if (selectedDays.length === 0) {
        toast.error("Please Select a Effect On Every! ");
      } else if (formData.time_from === "") {
        toast.error("Please Select a Time From ");
      } else if (formData.time_to === "") {
        toast.error("Please Select a Time To ");
      } else if (formData.discount_type === "") {
        toast.error("Please Select Disscount Type!");
      } else if (
        (formData.discount_type === "amount-off" && formData.amount === "") ||
        (formData.discount_type === "amount-off" && formData.amount === null)
      ) {
        toast.error("Please Enter Amount!");
      } else if (
        formData.discount_type === "amount-off" &&
        formData.apply_to_type === ""
      ) {
        toast.error("Please Select Option from Apply To!");
      } else if (
        formData.discount_type === "percentage-off" &&
        formData.percentage === ""
      ) {
        toast.error("Please Enter Percentage!");
      } else if (
        (formData.discount_type === "override-exist-price" &&
          formData.inclusive_price === "") ||
        (formData.discount_type === "override-exist-price" &&
          isNaN(formData.inclusive_price))
      ) {
        toast.error("Please Enter Inclusive Price");
      } else if (
        formData.discount_type === "override-exist-price" &&
        formData.tax_id === ""
      ) {
        toast.error("Please Select Tax!");
      } else if (
        formData.discount_type === "override-exist-price" &&
        formData.apply_to_type === ""
      ) {
        toast.error("Please Select Option from Apply To!");
      } else if (
        (formData.discount_type === "combo-set" &&
          formData.inclusive_price === "") ||
        (formData.discount_type === "combo-set" &&
          isNaN(formData.inclusive_price))
      ) {
        toast.error("Please Enter Inclusive Price");
      } else if (
        formData.discount_type === "combo-set" &&
        formData.tax_id === ""
      ) {
        toast.error("Please Select Tax!");
      } else if (
        formData.apply_to_type === "products-of-category" &&
        formData.min_quantity === ""
      ) {
        toast.error("Please Enter Min Quantity!");
      } else if (
        formData.apply_to_type === "products-of-category" &&
        formData.max_quantity === ""
      ) {
        toast.error("Please Enter Max Quantity!");
      } else if (
        formData.apply_to_type === "products-of-category" &&
        parseInt(formData.min_quantity) > parseInt(formData.max_quantity)
      ) {
        toast.error("Max Quantity Should be greater than Min!");
      } else if (
        formData.apply_to_type === "products-of-tags" &&
        sendingProductTags.length < 1
      ) {
        toast.error("Please Select Tags!");
      } else if (
        (formData.apply_to_type === "products-of-tags" &&
          formData.min_quantity === null) ||
        (formData.apply_to_type === "products-of-tags" &&
          formData.min_quantity === "")
      ) {
        toast.error("Please Enter Min Quantity!");
      } else if (
        (formData.apply_to_type === "products-of-tags" &&
          formData.max_quantity === null) ||
        (formData.apply_to_type === "products-of-tags" &&
          formData.max_quantity === "")
      ) {
        toast.error("Please Enter Max Quantity!");
      } else if (
        formData.apply_to_type === "products-of-tags" &&
        parseInt(formData.min_quantity) > parseInt(formData.max_quantity)
      ) {
        toast.error("Max Quantity Should be greater than Min!");
      } else if (
        formData.apply_to_type === "specific-products" &&
        sendingSpecificProduct.length < 1
      ) {
        toast.error("Please Select Specific Products!");
      } else if (
        formData.apply_to_type === "specific-products" &&
        formData.min_quantity === null
      ) {
        toast.error("Please Enter Min Quantity!");
      } else if (
        formData.apply_to_type === "specific-products" &&
        formData.max_quantity === null &&
        formData.apply_to_type === "specific-products" &&
        formData.max_quantity === ""
      ) {
        toast.error("Please Enter Max Quantity!");
      } else if (
        formData.apply_to_type === "specific-products" &&
        parseInt(formData.min_quantity) > parseInt(formData.max_quantity)
      ) {
        toast.error("Max Quantity Should be greater than Min!");
      } else if (
        (formData.apply_to_type === "orders-over-or-equal-to" &&
          formData.order_min_amount === "") ||
        (formData.apply_to_type === "orders-over-or-equal-to" &&
          formData.order_min_amount === null)
      ) {
        toast.error("Please Enter Min Quantity Order!");
      } else if (
        (formData.discount_type === "override-exist-price" &&
          formData.min_quantity === "") ||
        (formData.discount_type === "override-exist-price" &&
          formData.max_quantity === "")
      ) {
        toast.error("Please Enter Both Minimum And Maximum Quantiy");
      } else {
        try {
          let obj = {
            name: formData.name,
            code: formData.code,
            enable: formData.enable,
            start_date: formData.start_date,
            end_date: formData.end_date,
            all_days: 0,
            sunday: selectedDays.includes("Suunday") ? 1 : 0,
            monday: selectedDays.includes("Monday") ? 1 : 0,
            tuesday: selectedDays.includes("Tuesday") ? 1 : 0,
            wednesday: selectedDays.includes("Wednesday") ? 1 : 0,
            thursday: selectedDays.includes("Thursday") ? 1 : 0,
            friday: selectedDays.includes("Friday") ? 1 : 0,
            saturday: selectedDays.includes("Saturday") ? 1 : 0,
            time_from: formData.time_from,

            time_to: formData.time_to,
            discount_type: formData.discount_type,
            percentage: parseInt(formData.percentage),
            amount: parseInt(formData.amount),

            apply_to_type: formData.apply_to_type,
            order_min_amount: parseInt(formData.order_min_amount),
            min_quantity: parseInt(formData.min_quantity),
            max_quantity: parseInt(formData.max_quantity),
            price: parseInt(formData.price),
            tax_id: parseInt(formData.tax_id),
            inclusive_price: formData.inclusive_price,
            buy_quantity: parseInt(formData.buy_quantity),
            free_quantity: parseInt(formData.free_quantity),
            store_ids: [],
            category_ids: [],
            product_tag_ids: [],
            product_ids: [],
            combo_products: [...multipleProduct],
          };
          let category = sendingProductCategory;
          category.map((v, k) => {
            obj.category_ids.push(v.id);
          });
          let store = sendingStore;
          store.map((v, k) => {
            obj.store_ids.push(v.id);
          });
          let ptags = sendingProductTags;
          ptags.map((v, k) => {
            obj.product_tag_ids.push(v.id);
          });
          let pro = sendingSpecificProduct;
          pro.map((v, k) => {
            obj.product_ids.push(v.id);
          });
          axios
            .post(`${url}/admin/create-promotion`, { ...obj })
            .then((res) => {
              toast.success("Promotion is created successfully");
              setTimeout(() => getData(), 200);
              setOpen(false);
              setformData({
                name: "",
                code: "",
                enable: 0,
                start_date: "",
                end_date: "",
                all_days: "",
                sunday: "",
                monday: "",
                tuesday: "",
                wednesday: "",
                thursday: "",
                friday: "",
                saturday: "",
                time_from: "",
                time_to: "",
                discount_type: "",
                percentage: 0,
                amount: 0,
                apply_to_type: "",
                order_min_amount: 0,
                min_quantity: 0,
                max_quantity: 0,
                price: 0,
                tax_id: 0,
                inclusive_price: 0,
                buy_quantity: 0,
                free_quantity: 0,
                store_ids: [],
                category_ids: [],
                product_tag_ids: [],
                product_ids: [],
                combo_products: [],
              });
              setSelectedDays([]);
              setSendingSpecificProduct([]);
              setSendingStore([]);
              setSendingProductCategory([]);
              setSendingProductTags([]);
            })
            .catch((error) => {
              toast.error(error.response.data.message);
            });
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    }
  };
  const updateHandler = async (row) => {
    let a = [];
    let store = [];
    let prodcat = [];
    let tagcat = [];
    let prod = [];
    let combo = [];
    setSelectId(row.id);
    axios.get(`${url}/admin/get-by-id-promotion/${row.id}`).then((res) => {
      setformData({
        name: res.data.promotion.name,
        code: res.data.promotion.code,
        enable: res.data.promotion.enable,
        start_date: moment(res?.data?.promotion?.start_date).format(
          "YYYY-MM-DD"
        ),
        end_date: moment(res?.data?.promotion?.end_date).format("YYYY-MM-DD"),
        all_days: res.data.promotion.all_days,
        sunday: res.data.promotion.sunday === true ? a.push("Sunday") : "",
        monday: res.data.promotion.monday === true ? a.push("Monday") : "",
        tuesday: res.data.promotion.tuesday === true ? a.push("Tuesday") : "",
        wednesday:
          res.data.promotion.wednesday === true ? a.push("Wednesday") : "",
        thursday:
          res.data.promotion.thursday === true ? a.push("Thursday") : "",
        friday: res.data.promotion.friday === true ? a.push("Friday") : "",
        saturday:
          res.data.promotion.saturday === true ? a.push("Saturday") : "",
        time_from: res.data.promotion.time_from,
        time_to: res.data.promotion.time_to,
        discount_type: res.data.promotion.discount_type,
        percentage: res.data.promotion.percentage,
        amount: res.data.promotion.amount,
        apply_to_type: res.data.promotion.apply_to_type,
        order_min_amount: res.data.promotion.order_min_amount,
        min_quantity: res.data.promotion.min_quantity,
        max_quantity: res.data.promotion.max_quantity,
        price: res.data.promotion.price,
        tax_id: res.data.promotion.tax_id,
        inclusive_price: res.data.promotion.inclusive_price,
        buy_quantity: res.data.promotion.buy_quantity,
        free_quantity: res.data.promotion.free_quantity,
        store_ids: [],
        category_ids: [],
        product_tag_ids: [],
        product_ids: [],
        combo_products: [],
      });
      let test = allTax.findIndex((v) => v.id === res.data.promotion.tax_id);
      if (test > -1) {
        setPercentage(allTax[test].percentage);
      } else {
        setPercentage(0);
      }
      res.data.promotion.promotion_stores.map((v) => {
        store.push(v?.promo_store?.id);
      });
      if (res.data.promotion.products_category.length > 0) {
        res.data.promotion.products_category.map((v) => {
          prodcat.push({
            id:
              v?.promo_categories?.id === undefined
                ? ""
                : v?.promo_categories?.id,
            name:
              v?.promo_categories?.name === undefined
                ? ""
                : v?.promo_categories?.name,
          });
        });
      }
      if (res.data.promotion.product_tags.length > 0) {
        res.data.promotion.product_tags.map((v) => {
          tagcat.push({
            id:
              v?.promo_product_tags?.id === undefined
                ? ""
                : v?.promo_product_tags?.id,
            name:
              v?.promo_product_tags?.name === undefined
                ? ""
                : v?.promo_product_tags?.name,
          });
        });
      }
      if (res.data.promotion.promotion_products.length > 0) {
        res.data.promotion.promotion_products.map((v) => {
          prod.push({
            id: v?.promo_product?.id === undefined ? "" : v?.promo_product?.id,
            name:
              v?.promo_product?.name === undefined
                ? ""
                : v?.promo_product?.name,
          });
        });
      }
      res.data.promotion.combo_products.map((v) => {
        combo.push({
          product_id:
            v?.promo_combo_product?.id === undefined
              ? ""
              : v?.promo_combo_product?.id,
          quantity: v?.quantity === undefined ? "" : v?.quantity,
        });
      });

      setMultipleProduct([...combo]);

      setSendingProductTags([...tagcat]);
      setSendingSpecificProduct([...prod]);
      setSendingProductCategory([...prodcat]);
      if (store.length > 0) {
        let select = allStore.filter((p) => store.includes(p.id));
        setSendingStore(select);
      }
      setSelectedDays([...a]);
      setOpen(true);
    });
  };
  const deleteComponentHandler = (i) => {
    let obj = multipleProduct;
    obj.splice(i, 1);
    setMultipleProduct([...obj]);
  };

  return (
    <>
      <Dialog
        fullScreen
        fullWidth
        open={open}
        // onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            display: "flex ",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <ArrowBackIcon
            style={{ cursor: "pointer", marginTop: "-1px", marginRight: "5px" }}
            onClick={handleClose}
          />{" "}
          <span> {selectId !== null ? "Update" : "Add"} Promotion</span>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Name
              </div>
              <TextField
                name="name"
                autoFocus
                onChange={(e) => onChange(e)}
                margin="dense"
                value={formData.name}
                id="currenct"
                placeholder="Name"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Promo Code
              </div>
              <TextField
                name="code"
                onChange={(e) => onChange(e)}
                margin="dense"
                value={formData.code}
                id="currenct"
                placeholder="Promo Code"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Apply To Store
              </div>
              <Autocomplete
                value={[...sendingStore]}
                onChange={(event, newValue) => {
                  setSendingStore(newValue);
                }}
                fullWidth
                size="small"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
                id="combo-box-demo"
                multiple
                options={allStore}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Store"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className="mt-3 mb-3">
              <Typography className="mt-5" variant="h6">
                Promotion Time
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Start Date
              </div>
              <TextField
                name="start_date"
                onChange={(e) => onChange(e)}
                margin="dense"
                value={formData.start_date}
                id="currenct"
                placeholder="Name"
                type="date"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                End Date
              </div>
              <TextField
                name="end_date"
                onChange={(e) => onChange(e)}
                margin="dense"
                value={formData.end_date}
                id="currenct"
                placeholder="End Date"
                type="date"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Effective On Every
              </div>
              {selectedDays.length === 0 && isMyInputFocused === false ? (
                <div
                  className="days"
                  style={{
                    fontSize: "11px",
                    marginLeft: "5px",
                    marginTop: "10px",
                  }}
                >
                  Days{" "}
                </div>
              ) : (
                ""
              )}

              <Autocomplete
                onBlur={() => setIsMyInputFocused(false)}
                onFocus={() => setIsMyInputFocused(true)}
                defaultValue="All Days"
                multiple
                value={selectedDays}
                onChange={(event, newValue) => {
                  setSelectedDays(newValue);
                }}
                fullWidth
                size="small"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
                id="combo-box-demo"
                options={days}
                getOptionLabel={(option) => option || ""}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" ref={searchInput} />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                From
              </div>
              <TextField
                name="time_from"
                onChange={(e) => onChange(e)}
                margin="dense"
                value={formData.time_from}
                id="currenct"
                placeholder="Time From "
                type="time"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                To
              </div>
              <TextField
                name="time_to"
                onChange={(e) => onChange(e)}
                margin="dense"
                value={formData.time_to}
                id="currenct"
                placeholder="Time From "
                type="time"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} className="mt-3 mb-3">
              <Typography className="mt-5" variant="h6">
                Discount
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Discount Type
              </div>
              <Select
                name="discount_type"
                value={formData.discount_type}
                onChange={(e) => {
                  setformData({
                    ...formData,
                    discount_type: e.target.value,
                    //here
                    order_min_amount: 0,

                    apply_to_type: "",
                    price: 0,
                    tax_id: 0,
                    percentage: 0,
                    inclusive_price: 0,
                    combo_products: [],
                    free_quantity: 0,
                    buy_quantity: 0,
                    amount: 0,
                  });
                  setPercentage(0);
                  setMultipleProduct([]);
                }}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder="Discount Type"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                <MenuItem value="percentage-off">Percentage Off</MenuItem>
                <MenuItem value="amount-off"> Amount Off </MenuItem>
                <MenuItem value="override-exist-price">
                  Override Exist Price
                </MenuItem>
                <MenuItem value="combo-set">Combo Set</MenuItem>
                <MenuItem value="buy-x-free-y">Buy-X-Free-Y</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}></Grid>
            {formData.discount_type === "buy-x-free-y" ? (
              <>
                <Grid item xs={3}>
                  <div
                    style={{ marginBottom: "-5px", fontSize: "11px" }}
                    className="-mb-4"
                  >
                    Buy
                  </div>
                  <TextField
                    name="buy_quantity"
                    onChange={(e) => onChange(e)}
                    margin="dense"
                    value={formData.buy_quantity}
                    id="currenct"
                    placeholder="Buy Qunatity"
                    type="number"
                    fullWidth
                    variant="outlined"
                    className=""
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <div
                    style={{ marginBottom: "-5px", fontSize: "11px" }}
                    className="mb-1"
                  >
                    Apply To
                  </div>
                  <Select
                    name="apply_to_type"
                    value={formData.apply_to_type}
                    onChange={(e) => {
                      setformData({
                        ...formData,
                        order_min_amount: 0,
                        apply_to_type: e.target.value,
                        amount: 0,
                        max_quantity: 0,
                        min_quantity: 0,
                        category_ids: [],
                        product_tag_ids: [],
                        product_ids: [],
                      });

                      setSendingProductCategory([]);
                      setSendingProductTags([]);
                      setSendingSpecificProduct([]);
                    }}
                    fullWidth
                    displayEmpty
                    margin="dense"
                    id="currenct"
                    placeholder="Discount Type"
                    variant="outlined"
                    style={{
                      borderRadius: "0px",
                      height: "31px",
                      fontSize: "11px",
                    }}
                  >
                    {formData.discount_type !== "buy-x-free-y" ? (
                      <MenuItem value="all-orders"> All Orders </MenuItem>
                    ) : (
                      ""
                    )}
                    {formData.discount_type !== "buy-x-free-y" ? (
                      <MenuItem value="orders-over-or-equal-to">
                        Orders Over Or Equal To
                      </MenuItem>
                    ) : (
                      ""
                    )}

                    <MenuItem value="products-of-category">
                      Products Of Category
                    </MenuItem>
                    <MenuItem value="products-of-tags">
                      Products Of Tags
                    </MenuItem>
                    <MenuItem value="specific-products">
                      Specific Products
                    </MenuItem>
                  </Select>
                </Grid>

                {formData.apply_to_type === "products-of-category" ? (
                  <Grid item xs={3}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="mb-1"
                    >
                      Product with Category
                    </div>

                    <Autocomplete
                      value={sendingProductCategory}
                      onChange={(event, newValue) => {
                        setSendingProductCategory(newValue);
                      }}
                      style={{
                        borderRadius: "0px",
                        height: "31px",
                        fontSize: "11px",
                      }}
                      multiple
                      id="tags-outlined"
                      options={category}
                      getOptionLabel={(option) => option.name || ""}
                      filterSelectedOptions
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          InputProps={{
                            classes: {
                              root: classes.input,
                            },
                          }}
                          {...params}
                          variant="outlined"
                          placeholder="Product Tags"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {formData.apply_to_type === "products-of-tags" ? (
                  <Grid item xs={3}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="mb-1"
                    >
                      Products of Tags
                    </div>

                    <Autocomplete
                      value={sendingProductTags}
                      onChange={(event, newValue) => {
                        setSendingProductTags(newValue);
                      }}
                      style={{
                        borderRadius: "0px",
                        height: "31px",
                        fontSize: "11px",
                      }}
                      multiple
                      id="tags-outlined"
                      options={productTag}
                      getOptionLabel={(option) => option.name || ""}
                      filterSelectedOptions
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          InputProps={{
                            classes: {
                              root: classes.input,
                            },
                          }}
                          {...params}
                          variant="outlined"
                          placeholder="Product Tags"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {formData.apply_to_type === "specific-products" ? (
                  <Grid item xs={6}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="mb-1"
                    >
                      Specific Products
                    </div>

                    <Autocomplete
                      value={sendingSpecificProduct}
                      onChange={(event, newValue) => {
                        setSendingSpecificProduct(newValue);
                      }}
                      style={{
                        borderRadius: "0px",
                        height: "31px",
                        fontSize: "11px",
                      }}
                      multiple
                      id="tags-outlined"
                      options={products}
                      getOptionLabel={(option) => option.name || ""}
                      filterSelectedOptions
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          InputProps={{
                            classes: {
                              root: classes.input,
                            },
                          }}
                          {...params}
                          variant="outlined"
                          placeholder="Product Tags"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={3}>
                  <div
                    style={{ marginBottom: "-5px", fontSize: "11px" }}
                    className="-mb-4"
                  >
                    Get Y Item Free
                  </div>
                  <TextField
                    name="free_quantity"
                    onChange={(e) => onChange(e)}
                    margin="dense"
                    value={formData.free_quantity}
                    id="currenct"
                    placeholder="Buy Qunatity"
                    type="number"
                    fullWidth
                    variant="outlined"
                    className=""
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </>
            ) : (
              ""
            )}
            {formData.discount_type === "percentage-off" ? (
              <>
                <Grid item xs={6}>
                  <div
                    style={{ marginBottom: "-5px", fontSize: "11px" }}
                    className="-mb-4"
                  >
                    Percentage off value
                  </div>
                  <TextField
                    name="percentage"
                    onChange={(e) => onChange(e)}
                    value={formData.percentage}
                    margin="dense"
                    id="currenct"
                    placeholder="Percentage off value"
                    type="number"
                    fullWidth
                    variant="outlined"
                    className=""
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} />
              </>
            ) : formData.discount_type === "amount-off" ? (
              <>
                <Grid item xs={6}>
                  <div
                    style={{ marginBottom: "-5px", fontSize: "11px" }}
                    className="-mb-4"
                  >
                    Amount
                  </div>
                  <TextField
                    name="amount"
                    value={formData.amount}
                    onChange={(e) => onChange(e)}
                    margin="dense"
                    id="currenct"
                    placeholder="Amount"
                    type="number"
                    fullWidth
                    variant="outlined"
                    className=""
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
              </>
            ) : (
              ""
            )}

            {formData.discount_type === "override-exist-price" ||
            formData.discount_type === "combo-set" ? (
              <div
                style={{
                  width: "100%",
                  margin: "5px",
                  border: "1px solid #dbdbdb",
                  padding: "20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="-mb-4"
                    >
                      Tax Exclusive Price
                    </div>
                    <TextField
                      name="price"
                      margin="dense"
                      onChange={(e) => {
                        setformData({
                          ...formData,
                          price: e.target.value,
                          inclusive_price:
                            parseInt(e.target.value) +
                            (parseInt(e.target.value) *
                              parseInt(percentage ? percentage : 0)) /
                              100,
                        });
                      }}
                      value={formData.price}
                      id="currenct"
                      type="text"
                      fullWidth
                      variant="outlined"
                      className=""
                      InputProps={{
                        classes: {
                          root: classes.input,
                        },
                      }}
                    />
                  </Grid>

                  <Grid
                    xs={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "18px",
                      marginTop: "20px",
                    }}
                  >
                    +
                  </Grid>

                  <Grid item xs={3}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="mb-1"
                    >
                      Tax
                    </div>
                    <Select
                      name="tax_id"
                      value={formData.tax_id}
                      onChange={(e) => {
                        setformData({ ...formData, tax_id: e.target.value });
                        let test = allTax.findIndex(
                          (v) => v.id === e.target.value
                        );
                        if (test > -1) {
                          setPercentage(allTax[test].percentage);
                        } else {
                          setPercentage(0);
                        }
                      }}
                      fullWidth
                      displayEmpty
                      margin="dense"
                      id="currenct"
                      placeholder="Role"
                      variant="outlined"
                      style={{
                        borderRadius: "0px",
                        height: "31px",
                        fontSize: "11px",
                      }}
                    >
                      {allTax?.map((v, k) => (
                        <MenuItem key={k} value={v.id}>
                          {v.percentage}% : {v.tax_category}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid
                    disabled={true}
                    xs={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "18px",
                      marginTop: "20px",
                    }}
                  >
                    =
                  </Grid>

                  <Grid item xs={3}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="-mb-4"
                    >
                      Tax Inclusive Price
                    </div>
                    <TextField
                      disabled={true}
                      name="inclusive_price"
                      margin="dense"
                      value={formData.inclusive_price}
                      id="currenct"
                      placeholder="Cost"
                      type="number"
                      fullWidth
                      variant="outlined"
                      className=""
                      InputProps={{
                        classes: {
                          root: classes.input,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            ) : (
              ""
            )}
            {formData.discount_type === "combo-set" ? (
              <>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontSize: "14px" }}>
                        Product
                      </TableCell>
                      <TableCell align="left" style={{ fontSize: "14px" }}>
                        Qunatity
                      </TableCell>
                      <TableCell align="center" style={{ fontSize: "14px" }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {multipleProduct.map((v, k) => (
                      <TableRow>
                        <TableCell>
                          {" "}
                          <Autocomplete
                            value={products.find(
                              (e) => multipleProduct[k].product_id === e.id
                            )}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                let obj = multipleProduct;
                                obj[k].product_id = newValue.id;
                                setMultipleProduct([...obj]);
                              }
                            }}
                            fullWidth
                            size="small"
                            style={{
                              borderRadius: "0px",
                              height: "31px",
                              fontSize: "11px",
                              marginTop: "4px",
                            }}
                            id="combo-box-demo"
                            options={products}
                            getOptionLabel={(option) => option.name || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Category"
                                variant="outlined"
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            onChange={(event) => {
                              let obj = multipleProduct;
                              obj[k].quantity = event.target.value;
                              setMultipleProduct([...obj]);
                            }}
                            value={v.quantity}
                            margin="dense"
                            id="currenct"
                            placeholder="quantity"
                            type="number"
                            fullWidth
                            variant="outlined"
                            className=""
                            InputProps={{
                              classes: {
                                root: classes.input,
                              },
                            }}
                          />
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div
                            onClick={() => deleteComponentHandler(k)}
                            style={{
                              width: "30%",
                              fontSize: "15px",
                              color: "#E23F33",
                              cursor: "pointer",
                            }}
                          >
                            <DeleteForeverOutlinedIcon /> Delete
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Grid item xs={2}>
                  <Typography
                    onClick={() => addProduct()}
                    style={{ cursor: "pointer" }}
                    className=" mt-5 mb-5"
                    color="primary"
                  >
                    <AddCircleIcon /> Add Products
                  </Typography>
                </Grid>
                <Grid item xs={9}></Grid>
              </>
            ) : (
              ""
            )}
            {formData.discount_type === "percentage-off" ||
            formData.discount_type === "amount-off" ||
            formData.discount_type === "override-exist-price" ? (
              <>
                <Grid item xs={6}>
                  <div
                    style={{ marginBottom: "-5px", fontSize: "11px" }}
                    className="mb-1"
                  >
                    Apply To
                  </div>
                  <Select
                    name="apply_to_type"
                    value={formData.apply_to_type}
                    onChange={(e) => {
                      setformData({
                        ...formData,
                        apply_to_type: e.target.value,
                        max_quantity: 0,
                        min_quantity: 0,
                        category_ids: [],
                        product_tag_ids: [],
                        product_ids: [],
                        order_min_amount: 0,
                      });
                      setSendingProductCategory([]);
                      setSendingProductTags([]);
                      setSendingSpecificProduct([]);
                    }}
                    fullWidth
                    displayEmpty
                    margin="dense"
                    id="currenct"
                    placeholder="Discount Type"
                    variant="outlined"
                    style={{
                      borderRadius: "0px",
                      height: "31px",
                      fontSize: "11px",
                    }}
                  >
                    {formData.discount_type !== "override-exist-price" ? (
                      <MenuItem value="all-orders"> all-orders </MenuItem>
                    ) : (
                      ""
                    )}
                    {formData.discount_type !== "override-exist-price" ? (
                      <MenuItem value="orders-over-or-equal-to">
                        orders-over-or-equal-to
                      </MenuItem>
                    ) : (
                      ""
                    )}

                    <MenuItem value="products-of-category">
                      products-of-category
                    </MenuItem>
                    <MenuItem value="products-of-tags">
                      products-of-tags
                    </MenuItem>
                    <MenuItem value="specific-products">
                      Specific Products
                    </MenuItem>
                  </Select>
                </Grid>
                {formData.apply_to_type === "all-orders" ? "" : ""}
                {formData.apply_to_type === "orders-over-or-equal-to" ? (
                  <Grid item xs={6}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="-mb-4"
                    >
                      Min Quantity Order
                    </div>
                    <TextField
                      name="Min Amount To Order"
                      value={formData.order_min_amount}
                      onChange={(e) =>
                        setformData({
                          ...formData,
                          order_min_amount: e.target.value,
                        })
                      }
                      margin="dense"
                      id="currenct"
                      placeholder="Amount"
                      type="number"
                      fullWidth
                      variant="outlined"
                      className=""
                      InputProps={{
                        classes: {
                          root: classes.input,
                        },
                      }}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {formData.apply_to_type === "products-of-category" ? (
                  <Grid item xs={6}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="mb-1"
                    >
                      Product with Category
                    </div>

                    <Autocomplete
                      value={sendingProductCategory}
                      onChange={(event, newValue) => {
                        setSendingProductCategory(newValue);
                      }}
                      style={{
                        borderRadius: "0px",
                        height: "31px",
                        fontSize: "11px",
                      }}
                      multiple
                      id="tags-outlined"
                      options={category}
                      getOptionLabel={(option) => option.name || ""}
                      filterSelectedOptions
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          InputProps={{
                            classes: {
                              root: classes.input,
                            },
                          }}
                          {...params}
                          variant="outlined"
                          placeholder="Product Tags"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {formData.apply_to_type === "products-of-tags" ? (
                  <Grid item xs={6}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="mb-1"
                    >
                      products-of-tags
                    </div>

                    <Autocomplete
                      value={sendingProductTags}
                      onChange={(event, newValue) => {
                        setSendingProductTags(newValue);
                      }}
                      style={{
                        borderRadius: "0px",
                        height: "31px",
                        fontSize: "11px",
                      }}
                      multiple
                      id="tags-outlined"
                      options={productTag}
                      getOptionLabel={(option) => option.name || ""}
                      filterSelectedOptions
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          InputProps={{
                            classes: {
                              root: classes.input,
                            },
                          }}
                          {...params}
                          variant="outlined"
                          placeholder="Product Tags"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {formData.apply_to_type === "specific-products" ? (
                  <Grid item xs={6}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="mb-1"
                    >
                      Specific Products
                    </div>

                    <Autocomplete
                      value={sendingSpecificProduct}
                      onChange={(event, newValue) => {
                        setSendingSpecificProduct(newValue);
                      }}
                      style={{
                        borderRadius: "0px",
                        height: "31px",
                        fontSize: "11px",
                      }}
                      multiple
                      id="tags-outlined"
                      options={products}
                      getOptionLabel={(option) => option.name || ""}
                      filterSelectedOptions
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          InputProps={{
                            classes: {
                              root: classes.input,
                            },
                          }}
                          {...params}
                          variant="outlined"
                          placeholder="Product Tags"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {formData.apply_to_type === "specific-products" ||
                formData.apply_to_type === "products-of-tags" ||
                formData.apply_to_type === "products-of-category" ? (
                  <>
                    <Grid item xs={6}>
                      <div
                        style={{ marginBottom: "-5px", fontSize: "11px" }}
                        className="-mb-4"
                      >
                        Min Quantity
                      </div>
                      <TextField
                        name="min_quantity"
                        onChange={(e) => {
                          const re = /^[0-9 ]+$/;
                          if (e.target.value.length < 15)
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              onChange(e);
                            }
                        }}
                        margin="dense"
                        value={formData.min_quantity}
                        id="currenct"
                        placeholder="Min Quantity"
                        type="number"
                        fullWidth
                        variant="outlined"
                        className=""
                        InputProps={{
                          classes: {
                            root: classes.input,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{ marginBottom: "-5px", fontSize: "11px" }}
                        className="-mb-4"
                      >
                        Max Quantity
                      </div>
                      <TextField
                        name="max_quantity"
                        onChange={(e) => {
                          onChange(e);
                        }}
                        margin="dense"
                        value={formData.max_quantity}
                        id="currenct"
                        placeholder="Max Quantity"
                        type="number"
                        fullWidth
                        variant="outlined"
                        className=""
                        InputProps={{
                          classes: {
                            root: classes.input,
                          },
                        }}
                      />
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {/* <Grid item xs={12}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Product Tags
              </div>
              <Autocomplete
                value={SendingTags}
                onChange={(event, newValue) => {
                  setSendingTags(newValue);
                }}
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
                multiple
                id="tags-outlined"
                options={productTag}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                size="small"
                renderInput={(params) => (
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    {...params}
                    variant="outlined"
                    placeholder="Product Tags"
                  />
                )}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: "25px" }}>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleRequest}
            color="primary"
            variant="contained"
            style={{ color: "white" }}
          >
            {selectId !== null ? "Update" : "ADD"}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <div className="p-5 d-flex justify-content-lg-between align-items-center ">
            <Typography variant="h5">Promotion</Typography>
            <Button
              style={{ color: "white" }}
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Add New Promotion
            </Button>
          </div>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                length={rows.length}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={totalLength}
              />
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyleTableRow
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {row.id}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.code}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {moment(row.start_date).format("DD-MM-YYYY")}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {moment(row.end_date).format("DD-MM-YYYY")}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <button
                            onClick={() => updateHandler(row)}
                            className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                            title="Edit details"
                            ng-click="UpdateData(role)"
                          >
                            <span className="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                  ></path>
                                  <path
                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button>
                          {/* <button
                            className="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                            title="Delete"
                            ng-click="Delete(role.srNo)"
                          >
                            <span className="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000"
                                    fillRule="nonzero"
                                  ></path>
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button> */}
                        </StyledTableCell>
                      </StyleTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalLength}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}

export default Promotion;
