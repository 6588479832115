import React, { useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  Select,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "@material-ui/core";

let url = process.env.REACT_APP_API_URL;
const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: "0px",
    height: "31px",
    fontSize: "11px",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    paddingLeft: "15px",
    paddingRight: "15px",
    overflowX: "auto",
  },
}));

const steps = ["Create", "Editing", "Complete"];
function AddReturn() {
  const history = useHistory();
  const param = useParams();
  const classes = useStyles();
  const [products, setAllProduct] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState(null);
  const [subTotal, setSubTotal] = React.useState();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [progress, setProgress] = React.useState(25);
  const [supplier, setSupplier] = React.useState([]);
  const [allStore, setAllStore] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [final, setfinal] = React.useState();

  const [supplierName, setSupplierName] = React.useState("");
  const [storeName, setStoreName] = React.useState("");
  const [selectedProduct, setSelectedProduct] = React.useState([]);
  const [symbol, setSymbol] = React.useState("");

  const [formData, setformData] = React.useState({
    suppliername: "",
    storeName: "",
    es_date: "",
    notes: "",
  });
  useEffect(() => {
    axios.get(`${url}/admin/get-by-id-account/1`).then((res) => {
      setSymbol(res.data.account_setting.currency.symbol);
    });
    axios
      .get(`${url}/admin/stock-return-invoice/${param.id}`)
      .then(async (res) => {
        setSelectedId(res.data.order.id);
        if (res.data.message === "success.") {
          if (res.data.order.status === "shipped") {
            setfinal(res.data.order);
            setActiveStep(3);
            setProgress(100);
          } else {
            setActiveStep(1);
            setProgress(50);
          }

          let prod = [];
          setformData({
            storeName: res.data.order.storeId,
            suppliername: res.data.order.supplierId,
            notes: res.data.order.notes,
            es_date: moment(res.data.order.estimatedArrivalDate).format(
              "YYYY-MM-DD"
            ),
          });
          await setSubTotal(res.data.order.excAmount);
          await setTotalTax(res.data.order.taxAmount);
          await setTotalDiscountscount(res.data.order.discount);

          res.data.order.details.map((v, k) => {
            prod.push({
              id: v.id,
              productId: v.productId,
              qtyToReturn: v.qtyToReturn,
              price: v.price,
              qtyOnHand: v.qtyOnHand,
            });
          });
          setSelectedProduct([...prod]);
        }
      })
      .catch((err) => history.push("/admin/return"));
  }, []);
  const [totalTax, setTotalTax] = React.useState(0);
  const [totalDiscountscount, setTotalDiscountscount] = React.useState(0);
  useEffect(() => {
    let d = parseInt(totalDiscountscount === "" ? 0 : totalDiscountscount);
    let a =
      parseInt(subTotal === "" ? 0 : subTotal) +
      parseInt(totalTax === "" ? 0 : totalTax);
    let b = a - d;
    setTotal(b);
  }, [totalTax, totalDiscountscount, subTotal]);
  useEffect(() => {
    if (activeStep === 2) {
      let a = [];
      selectedProduct.map((v) => {
        a.push({
          // id: v.id,
          // productId: v.productId,
          // qtyToReturn: v.qtyToReturn,
          // price: v.price,
          // totalAmount: parseInt(v?.qtyToReturn) * parseInt(v?.price),
          // qtyReceived: "1",
        });
      });
      setSelectedProduct([...a]);
    }
  }, [activeStep]);
  useEffect(() => {
    axios.get(`${url}/admin/get-product-list?limit=1000&page=1`).then((res) => {
      if (res.data.products.rows.length > 0) {
        let arr = [];
        res.data.products.rows.map((v, k) => {
          arr.push({ id: v.id, name: v.name, cost: v.cost });
        });
        setAllProduct([...arr]);
      }
    });
  }, []);
  useEffect(() => {
    axios
      .get(`${url}/admin/get-supplier-list?limit=1000&page=1`)
      .then((res) => {
        let sup = [];
        res.data.suppliers.rows.map((v, k) => {
          sup.push({ id: v.id, name: v.name });
        });

        setSupplier(sup);
      });
  }, []);
  useEffect(() => {
    axios.get(`${url}/admin/get-stores-list?limit=1000&page=1`).then((res) => {
      let st = [];
      res.data.stores.rows.map((v, k) => {
        st.push({ id: v.id, name: v.name });
      });

      setAllStore(st);
    });
  }, []);
  useEffect(() => {
    allStore.findIndex((e) =>
      e.id === formData.storeName ? setStoreName(e.name) : ""
    );
    supplier.findIndex((e) =>
      e.id === formData.suppliername ? setSupplierName(e.name) : ""
    );
  });

  useEffect(() => {
    let sum = 0;
    if (activeStep === 1) {
      selectedProduct.map((v) => {
        sum =
          sum +
          parseInt(v.qtyToReturn === "" ? 0 : v.qtyToReturn) *
            parseInt(v.price === "" ? 0 : v.price);
      });
    }
    // else if (activeStep === 2) {
    //   selectedProduct.map((v) => {
    //     sum =
    //       sum +
    //       parseInt(v.qtyToReturn === "" ? 0 : v.qtyToReturn) *
    //         parseInt(v.price === "" ? 0 : v.price) *
    //         parseInt(v.qtyReceived === "" ? 0 : v.qtyReceived);
    //   });
    // }
    if (isNaN(sum)) {
      setSubTotal(0);
    } else {
      setSubTotal(sum);
    }
  });
  const SaveAndSend = (r) => {
    if (selectedProduct.length < 1) {
      toast.error("Please Add Product !");
    } else if (
      selectedProduct.some((e) => e.productId === "" || e.productId === null)
    ) {
      toast.error("Please Select Product First");
    } else if (selectedProduct.some((e) => e.qtyToReturn === "")) {
      toast.error("Please Enter The Quantity Of Order!");
    } else if (selectedProduct.some((e) => e.qtyToReturn < 1)) {
      toast.error("Quantity Of Order Should Be Greater Than Zero! ");
    } else if (
      selectedProduct.some((e) => e.price === "" || e.price === null)
    ) {
      toast.error("Please Enter The Price!");
    } else if (selectedProduct.some((e) => e.price < 1)) {
      toast.error("Price Should Be Greater The Zero!");
    } else if (
      selectedProduct.some((e) => e.qtyOnHand === "" || e.price === null)
    ) {
      toast.error("Please Enter The Quantity in Hand!");
    } else if (selectedProduct.some((e) => e.qtyOnHand < 1)) {
      toast.error("Quantity in Hand should not less than zero!");
    } else {
      let selectedProducts = [];

      selectedProduct.map((v, k) => {
        selectedProducts.push({
          id: v.id,
          productId: v.productId,
          qtyToReturn: v.qtyToReturn,
          qtyOnHand: v.qtyOnHand,
          price: v.price,
          totalAmount: parseInt(v?.qtyToReturn) * parseInt(v?.price),
        });
      });
      let obj = {
        storeId: formData.storeName,
        supplierId: formData.suppliername,
        status: "processing",
        excAmount: parseInt(subTotal),

        taxAmount: parseInt(totalTax),
        incAmount: parseInt(subTotal) + parseInt(totalTax),
        discount: parseInt(totalDiscountscount),
        totalAmount:
          parseInt(subTotal) +
          parseInt(totalTax) -
          parseInt(totalDiscountscount),
        notes: formData.notes,
        details: [...selectedProducts],
      };

      console.log(obj);
      axios
        .post(`${url}/admin/create-stock-return`, obj)
        .then(async (res) => {
          history.push(`/admin/return/${res.data.order.id}`);
          setSelectedId(res.data.order.id);
        })
        .then((res) => {
          toast.success("Return is created successfully!");
        })
        .catch((err) => toast.error(err.response.data.message));
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (formData.suppliername === "") {
      toast.error("Please Select Supplier First!");
    } else if (formData.storeName === "") {
      toast.error("Please Select Target Store First!");
    } else {
      setProgress((old) => old + 25);
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setProgress((old) => old - 25);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };
  const addComponentHandler = () => {
    setSelectedProduct([
      ...selectedProduct,
      { id: 0, productId: "", qtyToReturn: "1", price: "0", qtyOnHand: "1" },
    ]);
  };
  const deleteGroup = (f) => {
    let obj = selectedProduct;
    obj.splice(f, 1);
    setSelectedProduct([...obj]);
  };
  const SaveAndCountinue = () => {
    if (selectedProduct.length < 1) {
      toast.error("Please Add Product !");
    } else if (
      selectedProduct.some((e) => e.productId === "" || e.productId === null)
    ) {
      toast.error("Please Select Product First");
    } else if (selectedProduct.some((e) => e.qtyToReturn === "")) {
      toast.error("Please Enter The Quantity Of Order!");
    } else if (selectedProduct.some((e) => e.qtyToReturn < 1)) {
      toast.error("Quantity Of Order Should Be Greater Than Zero! ");
    } else if (
      selectedProduct.some((e) => e.price === "" || e.price === null)
    ) {
      toast.error("Please Enter The Price!");
    } else if (selectedProduct.some((e) => e.price < 1)) {
      toast.error("Price Should Be Greater The Zero!");
    } else if (selectedProduct.some((e) => e.qtyOnHand < 0)) {
      toast.error("Quantity Recived Should Be Greater The Zero!");
    } else if (
      selectedProduct.some((e) => e.qtyOnHand === "" || e.qtyOnHand === null)
    ) {
      toast.error("Quantity in Hand Should Not Be Null!");
    } else {
      let selectedProducts = [];

      selectedProduct.map((v, k) => {
        selectedProducts.push({
          id: v.id,
          productId: v.productId,
          qtyToReturn: parseInt(v.qtyToReturn),
          price: v.price,
          qtyOnHand: parseInt(v.qtyOnHand),
          totalAmount: parseInt(v?.qtyToReturn) * parseInt(v?.price),
        });
      });
      let obj = {
        storeId: formData.storeName,
        supplierId: formData.suppliername,
        status: "shipped",
        estimatedArrivalDate: formData.es_date,
        excAmount: parseInt(subTotal),

        taxAmount: parseInt(totalTax),
        incAmount: parseInt(subTotal) + parseInt(totalTax),
        discount: parseInt(totalDiscountscount),
        totalAmount:
          parseInt(subTotal) +
          parseInt(totalTax) -
          parseInt(totalDiscountscount),
        notes: formData.notes,
        details: [...selectedProducts],
      };
      axios
        .post(`${url}/admin/update-stock-return`, { ...obj, id: selectedId })
        .then((res) => {
          toast.success("Stock Shipped Succesfully!");
          setfinal(res.data.order);
          setActiveStep(3);
          setProgress(100);
        })
        .catch((err) => toast.error(err.response.data.message));
    }
  };
  const HandleUpdate = () => {
    if (selectedProduct.length < 1) {
      toast.error("Please Add Product !");
    } else if (
      selectedProduct.some((e) => e.productId === "" || e.productId === null)
    ) {
      toast.error("Please Select Product First");
    } else if (selectedProduct.some((e) => e.qtyToReturn === "")) {
      toast.error("Please Enter The Quantity Of Order!");
    } else if (selectedProduct.some((e) => e.qtyToReturn < 1)) {
      toast.error("Quantity Of Order Should Be Greater Than Zero! ");
    } else if (
      selectedProduct.some((e) => e.price === "" || e.price === null)
    ) {
      toast.error("Please Enter The Price!");
    } else if (selectedProduct.some((e) => e.price < 1)) {
      toast.error("Price Should Be Greater The Zero!");
    } else {
      let selectedProducts = [];

      selectedProduct.map((v, k) => {
        selectedProducts.push({
          id: v.id,
          productId: v.productId,
          qtyToReturn: v.qtyToReturn,
          qtyOnHand: parseInt(v.qtyOnHand),
          price: v.price,
          totalAmount: parseInt(v?.qtyToReturn) * parseInt(v?.price),
        });
      });
      let obj = {
        storeId: formData.storeName,
        supplierId: formData.suppliername,
        status: "processing",
        estimatedArrivalDate: formData.es_date,
        excAmount: parseInt(subTotal),

        taxAmount: parseInt(totalTax),
        incAmount: parseInt(subTotal) + parseInt(totalTax),
        discount: parseInt(totalDiscountscount),
        totalAmount:
          parseInt(subTotal) +
          parseInt(totalTax) -
          parseInt(totalDiscountscount),
        notes: formData.notes,
        details: [...selectedProducts],
      };

      axios
        .post(`${url}/admin/update-stock-return`, { ...obj, id: selectedId })
        .then((res) => {
          toast.success("Stock updated successfully");
          let a = [];
          res.data.order.details.map((v, k) => {
            a.push({
              id: v.id,
              productId: v.productId,
              qtyToReturn: v.qtyToReturn,
              price: v.price,
              qtyOnHand: v.qtyOnHand,
            });
          });
          setSelectedProduct([...a]);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          toast.error(err.response.data.prodcut);
        });
    }
  };

  return (
    <Box sx={{ width: "100%", background: "white" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <span
        style={{ width: "100%", justifyContent: "center", display: "flex" }}
      >
        <LinearProgress
          style={{ width: "93%", height: "1vh" }}
          variant="determinate"
          value={progress}
        />
      </span>

      {activeStep === 0 ? (
        <div style={{ height: "60vh" }}>
          <Grid container style={{ padding: "50px 30px 0px 50px" }} spacing={4}>
            <Grid item xs={4} style={{ fontSize: "13.5px", fontWeight: "500" }}>
              Supplier*
            </Grid>
            <Grid item xs={5}>
              <Select
                name="suppliername"
                value={formData.suppliername}
                onChange={(event) => onChange(event)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                {supplier.map((v, k) => (
                  <MenuItem value={v.id} key={k}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={4} style={{ fontSize: "13.5px", fontWeight: "500" }}>
              Target Store*
            </Grid>
            <Grid item xs={5}>
              <Select
                name="storeName"
                value={formData.storeName}
                onChange={(event) => onChange(event)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder=" Cutomer Type"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                {allStore.map((v, k) => (
                  <MenuItem value={v.id} name={v.name} key={k}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={4} style={{ fontSize: "13.5px", fontWeight: "500" }}>
              Auto Fill from Supply Needs
            </Grid>
            <Grid item xs={5} style={{ fontSize: "11px" }}>
              <Select
                name="customer_type"
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder=" Cutomer Type"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="Not">Not</MenuItem>
              </Select>
              <p>
                Auto fill your order with products below or equal to their
                warning stock levels.
              </p>
            </Grid>
          </Grid>
        </div>
      ) : (
        ""
      )}
      {activeStep === 1 || activeStep === 2 ? (
        <div style={{ minHeight: "60vh" }}>
          <Grid container style={{ padding: "50px 30px 0px 50px" }} spacing={4}>
            <Grid item xs={3} style={{ fontSize: "12px", fontWeight: "500" }}>
              Supplier :
            </Grid>
            <Grid item xs={3} style={{ fontSize: "12px" }}>
              {supplierName}
            </Grid>
            <Grid item xs={3} style={{ fontSize: "12px", fontWeight: "500" }}>
              Target Store
            </Grid>
            <Grid item xs={3} style={{ fontSize: "12px" }}>
              {storeName}
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "-5px" }}>
              <Typography variant="h6">Items to Order</Typography>
            </Grid>
            <Grid item xs={12}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: "14px", width: "25%" }}>
                      Product
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: "14px", width: "15%" }}
                    >
                      Qty to Return
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ fontSize: "14px", width: "15%" }}
                    >
                      Supplier Price
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{ fontSize: "14px", width: "15%" }}
                      hidden
                    >
                      Qty on Hand
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{ fontSize: "14px", width: "20%" }}
                    >
                      Total {symbol}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "14px" }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProduct?.map((v, k) => {
                    let f = products.find((e) =>
                      v.productId === e.id ? e : ""
                    );
                    let find = { ...f };

                    return (
                      <TableRow key={k}>
                        <TableCell>
                          <Autocomplete
                            value={find}
                            onChange={(event, newValue) => {
                              let cost = products.find((e) =>
                                e.id === newValue?.id ? e : ""
                              );
                              let obj = selectedProduct;
                              obj[k].productId = newValue?.id;
                              obj[k].price = cost?.cost;
                              setSelectedProduct([...obj]);
                            }}
                            fullWidth
                            size="small"
                            style={{
                              borderRadius: "0px",
                              height: "31px",
                              fontSize: "11px",
                            }}
                            id="combo-box-demo"
                            options={products}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Product"
                                variant="outlined"
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={v.qtyToReturn}
                            onChange={(event) => {
                              let obj = selectedProduct;
                              obj[k].qtyToReturn = event.target.value;
                              setSelectedProduct([...obj]);
                            }}
                            style={{ marginTop: "-0.5px" }}
                            name=""
                            size="small"
                            margin="dense"
                            id="currenct"
                            placeholder="Quantity Order"
                            type="number"
                            fullWidth
                            variant="outlined"
                            className=""
                            InputProps={{
                              classes: {
                                root: classes.input,
                              },
                            }}
                          />
                        </TableCell>

                        <TableCell>
                          <TextField
                            value={v.price}
                            onChange={(event) => {
                              let obj = selectedProduct;
                              obj[k].price = event.target.value;
                              setSelectedProduct([...obj]);
                            }}
                            style={{ marginTop: "-0.5px" }}
                            name=""
                            size="small"
                            margin="dense"
                            id="currenct"
                            placeholder="Price"
                            type="number"
                            fullWidth
                            variant="outlined"
                            className=""
                            InputProps={{
                              classes: {
                                root: classes.input,
                              },
                            }}
                          />
                        </TableCell>

                        <TableCell hidden>
                          <TextField
                            value={v.qtyOnHand}
                            onChange={(event) => {
                              let obj = selectedProduct;
                              obj[k].qtyOnHand = event.target.value;
                              setSelectedProduct([...obj]);
                            }}
                            style={{ marginTop: "-0.5px" }}
                            name=""
                            size="small"
                            margin="dense"
                            id="currenct"
                            placeholder=""
                            type="number"
                            fullWidth
                            variant="outlined"
                            className=""
                            InputProps={{
                              classes: {
                                root: classes.input,
                              },
                            }}
                          />
                        </TableCell>

                        <TableCell>
                          <>
                            {parseInt(v?.price === "" ? 0 : v?.price) *
                              parseInt(
                                v?.qtyToReturn === "" ? 0 : v.qtyToReturn
                              )}
                          </>
                        </TableCell>
                        <TableCell align="center">
                          <div
                            onClick={() => deleteGroup(k)}
                            style={{
                              fontSize: "15px",
                              color: "#E23F33",
                              cursor: "pointer",
                            }}
                          >
                            <DeleteForeverOutlinedIcon /> Delete
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">Sub Total</TableCell>
                    <TableCell>{subTotal}</TableCell>
                    <TableCell align="center">
                      <Typography
                        color="primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => addComponentHandler()}
                      >
                        Add Item
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">Tax</TableCell>
                    <TableCell>
                      <TextField
                        style={{ marginTop: "-0.5px" }}
                        name="totalTax"
                        onChange={(e) => setTotalTax(e.target.value)}
                        value={totalTax}
                        size="small"
                        margin="dense"
                        id="currenct"
                        placeholder="Tax"
                        type="number"
                        fullWidth
                        variant="outlined"
                        className=""
                        InputProps={{
                          classes: {
                            root: classes.input,
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">Discount</TableCell>
                    <TableCell>
                      <TextField
                        style={{ marginTop: "-0.5px" }}
                        name="totalDiscountscount"
                        onChange={(e) => setTotalDiscountscount(e.target.value)}
                        value={totalDiscountscount}
                        size="small"
                        margin="dense"
                        id="currenct"
                        placeholder="discount"
                        type="number"
                        fullWidth
                        variant="outlined"
                        className=""
                        InputProps={{
                          classes: {
                            root: classes.input,
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell>{isNaN(total) ? 0 : total}</TableCell>
                    <TableCell align="center"> </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "-20px" }}>
              <Typography variant="h6">Notes</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="notes"
                value={formData.notes}
                onChange={(e) => onChange(e)}
                multiline
                rows={3}
                size="small"
                margin="dense"
                id="currenct"
                placeholder="Notes"
                type="text"
                fullWidth
                variant="outlined"
                className=""
              />
            </Grid>
          </Grid>
        </div>
      ) : (
        ""
      )}
      {activeStep === 3 ? (
        <div style={{ minHeight: "60vh" }}>
          <Grid container style={{ padding: "50px 30px 0px 50px" }} spacing={4}>
            <Grid item xs={3} style={{ fontSize: "12px", fontWeight: "500" }}>
              Supplier :
            </Grid>
            <Grid item xs={3} style={{ fontSize: "12px" }}>
              {supplierName}
            </Grid>
            <Grid item xs={3} style={{ fontSize: "12px", fontWeight: "500" }}>
              Target Store
            </Grid>
            <Grid item xs={3} style={{ fontSize: "12px" }}>
              {storeName}
            </Grid>

            <Grid item xs={12} style={{ marginBottom: "-5px" }}>
              <Typography variant="h6">Items to Order</Typography>
            </Grid>
            <Grid item xs={12}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: "14px" }}>Product</TableCell>
                    <TableCell align="left" style={{ fontSize: "14px" }}>
                      Qty to Return
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: "14px" }}>
                      Supplier Price {symbol}
                    </TableCell>

                    {/* <TableCell align="left" style={{ fontSize: "14px" }}>
                      Qty on Hand
                    </TableCell> */}

                    <TableCell align="left" style={{ fontSize: "14px" }}>
                      Total {symbol}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {final.details?.map((v, k) => {
                    return (
                      <TableRow key={k}>
                        <TableCell>{v?.product?.name}</TableCell>
                        <TableCell>{v?.qtyToReturn}</TableCell>

                        <TableCell>{v?.price}</TableCell>

                        {/* <TableCell>{v?.qtyOnHand}</TableCell> */}

                        <TableCell>{v?.totalAmount}</TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">Sub Total</TableCell>
                    <TableCell>{final.excAmount}</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">Tax</TableCell>
                    <TableCell>{parseInt(final.taxAmount)}</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">Discount</TableCell>
                    <TableCell>{parseInt(final.discount)}</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell>{parseInt(final.totalAmount)}</TableCell>
                    <TableCell align="center"> </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: "-20px" }}>
              <Typography variant="h6">Notes</Typography>
            </Grid>
            <Grid item xs={12}>
              {formData.notes}
            </Grid>
          </Grid>
        </div>
      ) : (
        ""
      )}

      <React.Fragment>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            onClick={() => history.push("/admin/allreturn")}
            style={{ margin: "15px" }}
            variant="outlined"
            color="primary"
          >
            Back
          </Button>
          {activeStep === 1 ? (
            <>
              {selectedId !== null ? (
                <>
                  <Button
                    onClick={() => HandleUpdate()}
                    style={{ margin: "15px", color: "white" }}
                    color="primary"
                    variant="contained"
                  >
                    Update
                  </Button>
                  <Button
                    onClick={SaveAndCountinue}
                    style={{ margin: "15px", color: "white" }}
                    color="primary"
                    variant="contained"
                  >
                    Save and Marked As Shipped
                  </Button>
                </>
              ) : (
                <Button
                  onClick={() => SaveAndSend("")}
                  color="primary"
                  variant="contained"
                  style={{ margin: "15px", color: "white" }}
                >
                  Create
                </Button>
              )}
            </>
          ) : (
            ""
          )}

          {activeStep === 0 ? (
            <Button
              onClick={handleNext}
              style={{ margin: "15px", color: "white" }}
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          ) : (
            ""
          )}
          {activeStep === steps.length - 1 ? (
            <Button style={{ margin: "15px", color: "white" }}>
              Completed
            </Button>
          ) : (
            ""
          )}
        </Box>
      </React.Fragment>
    </Box>
  );
}
export default AddReturn;
