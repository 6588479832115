import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Menu,
  Button,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  FormControl,
  Radio,
  MenuItem,
  Checkbox,
  TextField,
  MenuList,
} from "@material-ui/core";
import axios from "axios";

import { DateRange } from "react-date-range";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";

function FilterView({
  onEndingDate,
  onStartingDate,
  mainLabel,
  onChangeTime,
  onChangeGroupBy,
  time,
  onChangeStore,
  handleSearch,
  categoryCheck,
  onChangeCatgeory,
  productCheck,
  customerCheck,
  onChangeProduct,
  onChangeCustomer,
  limitCheck,
  onChangeLimit,
}) {
  let url = process.env.REACT_APP_API_URL;

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [allStore, setAllStore] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    onEndingDate(moment(state[0].startDate).format("YYYY-MM-DD"));
    onStartingDate(moment(state[0].endDate).format("YYYY-MM-DD"));
  }, [state]);
  useEffect(() => {
    axios.get(`${url}/admin/get-product-list?limit=1000&page=1`).then((res) => {
      if (res.data.products.rows.length > 0) {
        let arr = [];
        res.data.products.rows.map((v, k) => {
          arr.push({ id: v.id, name: v.name });
        });
        setAllProduct([...arr]);
      }
    });
  }, []);
  useEffect(() => {
    axios.get(`${url}/admin/get-stores-list?limit=1000&page=1`).then((res) => {
      if (res.data.stores.rows.length > 0) {
        let arr = [];
        res.data.stores.rows.map((v, k) => {
          arr.push({ id: v.id, name: v.name });
        });
        setAllStore([...arr]);
      }
    });
  }, []);
  useEffect(() => {
    axios
      .get(`${url}/admin/get-category-list?limit=1000&page=1`)
      .then((res) => {
        if (res.data.categories.rows.length > 0) {
          let arr = [];
          res.data.categories.rows.map((v, k) => {
            arr.push({ id: v.id, name: v.name });
          });
          setAllCategory([...arr]);
        }
      });
  }, []);
  return (
    <>
      <Grid container style={{ padding: "20px 0px" }}>
        <Grid item xs={12} style={{ paddingBottom: "20px" }}>
          <Typography variant="h5">{mainLabel}</Typography>
        </Grid>
        <Grid item xs={2}>
          <div style={{ display: "flex", alignItems: "center" }}>
            Group By
            <div className="pl-3">
              <Button
                style={{
                  backgroundColor: "white",
                  borderRadius: "0px",
                  textTransform: "capitalize",
                  border: "0.5px solid #E0E0E0",
                }}
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={handleClick1}
              >
                {time ? time : "None"}
                <i
                  className="fas fa-chevron-down pl-3"
                  style={{ fontSize: "12px" }}
                ></i>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem>
                  {" "}
                  <FormControlLabel
                    control={<Checkbox onChange={(e) => onChangeGroupBy(e)} />}
                    label="Group By"
                  />
                </MenuItem>
                <MenuItem style={{ padding: "0px 20px" }}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Time Variable</FormLabel>

                    <RadioGroup
                      value={time}
                      onChange={(e) => onChangeTime(e)}
                      style={{ paddingLeft: "20px" }}
                      aria-label="gender"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="weekly"
                        control={<Radio />}
                        label="Weekly"
                      />
                      <FormControlLabel
                        value="monthly"
                        control={<Radio />}
                        label="Monthly"
                      />

                      <FormControlLabel
                        value="day_of_week"
                        control={<Radio />}
                        label="Day Of Week"
                      />
                    </RadioGroup>
                  </FormControl>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="pl-3">
            <Button
              style={{
                backgroundColor: "white",
                borderRadius: "0px",
                textTransform: "capitalize",
                border: "0.5px solid #E0E0E0",
              }}
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open2 ? "true" : undefined}
              onClick={handleClick2}
            >
              Filter
              <i
                className="fas fa-chevron-down pl-3"
                style={{ fontSize: "12px" }}
              ></i>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl2}
              open={open2}
              onClose={handleClose2}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuList>
                <MenuItem
                  style={{ width: "250px" }}
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <FormLabel component="legend">Store</FormLabel>

                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChangeStore(newValue.id);
                      }}
                      fullWidth
                      size="small"
                      style={{
                        borderRadius: "0px",
                        height: "31px",
                        fontSize: "11px",
                      }}
                      id="combo-box-demo"
                      options={allStore}
                      getOptionLabel={(option) => option.name || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Store"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </MenuItem>

                {productCheck ? (
                  <MenuItem
                    onKeyDown={(e) => e.stopPropagation()}
                    style={{ width: "250px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <FormLabel component="legend">Product</FormLabel>

                      <TextField
                        placeholder="Product"
                        variant="outlined"
                        onChange={(e) => onChangeProduct(e.target.value)}
                        fullWidth
                        size="small"
                        style={{
                          borderRadius: "0px",
                          height: "31px",
                          fontSize: "11px",
                        }}
                        id="combo-box-demo"
                      />
                    </div>
                  </MenuItem>
                ) : (
                  ""
                )}
                {customerCheck ? (
                  <MenuItem
                    style={{ width: "250px" }}
                    onKeyDown={(e) => e.stopPropagation()}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <FormLabel component="legend">Customer</FormLabel>

                      <TextField
                        placeholder="Product"
                        variant="outlined"
                        onChange={(e) => onChangeCustomer(e.target.value)}
                        fullWidth
                        size="small"
                        style={{
                          borderRadius: "0px",
                          height: "31px",
                          fontSize: "11px",
                        }}
                        id="combo-box-demo"
                      />
                    </div>
                  </MenuItem>
                ) : (
                  ""
                )}

                {categoryCheck ? (
                  <MenuItem
                    style={{ width: "250px" }}
                    onKeyDown={(e) => e.stopPropagation()}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <FormLabel component="legend">Category</FormLabel>

                      <Autocomplete
                        onChange={(event, newValue) =>
                          onChangeCatgeory(newValue?.id)
                        }
                        // value={[...sendingStore]}
                        // onChange={(event, newValue) => {
                        //   setSendingStore(newValue);
                        // }}
                        fullWidth
                        size="small"
                        style={{
                          borderRadius: "0px",
                          height: "31px",
                          fontSize: "11px",
                        }}
                        id="combo-box-demo"
                        options={allCategory}
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Category"
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  </MenuItem>
                ) : (
                  ""
                )}
                {limitCheck ? (
                  <MenuItem
                    style={{ width: "250px" }}
                    onKeyDown={(e) => e.stopPropagation()}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <FormLabel component="legend">Limit</FormLabel>

                      <TextField
                        onChange={(e) => onChangeLimit(e.target.value)}
                        placeholder="Limit"
                        variant="outlined"
                        size="small"
                      />
                    </div>
                  </MenuItem>
                ) : (
                  ""
                )}

                <MenuItem style={{ width: "250px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => handleSearch()}
                      color="primary"
                      variant="contained"
                      style={{ color: "white" }}
                    >
                      Search
                    </Button>
                  </div>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </Grid>

        <Grid item xs={1}></Grid>

        <Grid item xs={3}>
          <div>
            <Button
              style={{
                backgroundColor: "white",
                borderRadius: "0px",
                border: "0.5px solid #E0E0E0",
              }}
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <span className="svg-icon svg-icon-primary svg-icon-2x">
                <i
                  className="fas fa-calendar-week"
                  style={{ fontSize: "12px" }}
                ></i>
              </span>
              <span className="pl-5 pr-5">
                {moment(state[0].startDate).format("DD-MM-YYYY")}
              </span>
              :
              <span className="pl-5 pr-5">
                {moment(state[0].endDate).format("DD-MM-YYYY")}
              </span>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              autoFocus={false}
              disableAutoFocusItem={true}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
                disabledItemsFocusable: true,
              }}
            >
              <DateRange
                editableDateInputs={true}
                onChange={(item) => {
                  setState([item.selection]);
                  console.log(item);
                }}
                moveRangeOnFirstSelection={false}
                ranges={state}
              />
            </Menu>
          </div>
        </Grid>
        <Grid item xs={2}>
          <Button
            style={{
              marginLeft: "10px",
              backgroundColor: "white",
              borderRadius: "0px",
              border: "0.5px solid #E0E0E0",
            }}
          >
            <i
              className="fas fa-download pr-3"
              style={{ fontSize: "12px" }}
            ></i>
            <span className="pr-3">Download</span>
          </Button>
        </Grid>

        <Grid></Grid>
      </Grid>
    </>
  );
}
export default FilterView;
