/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuListReport({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        <li
          className={`menu-item ${getMenuItemActive(
            "/admin/sales_category_product",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/sales_category_product">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
            </span>
            <span className="menu-text">Sales Category Report</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/admin/sales_product",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/sales_product">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
            </span>
            <span className="menu-text">Sales Product Report</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/admin/sales_employee",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/sales_employee">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
            </span>
            <span className="menu-text">Sales Employee Report</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/admin/salesProductStock",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/salesProductStock">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
            </span>
            <span className="menu-text">Product Stock Report</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/admin/product_permotion",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/product_permotion">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
            </span>
            <span className="menu-text">Product Permotion Report</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/admin/sales_cash",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/sales_cash">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
            </span>
            <span className="menu-text">Sales Cash Report</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/admin/sales_shift",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/sales_shift">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
            </span>
            <span className="menu-text">Sales Shift Report</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/admin/salesProductVariant",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/salesProductVariant">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
            </span>
            <span className="menu-text">Sales Product Variant Report</span>
          </NavLink>
        </li>
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/admin/sales_shift_transction",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin/sales_shift_transction">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
            </span>
            <span className="menu-text">Sales Shift Transaction Report</span>
          </NavLink>
        </li> */}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
