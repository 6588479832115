import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  head,
  body,
  opens,
  handleCloses,
  handleRequests,
}) {
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={opens}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloses}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{head}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleCloses}>
            No
          </Button>
          <Button
            style={{ color: "white" }}
            color="primary"
            variant="contained"
            onClick={handleRequests}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
