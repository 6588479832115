import React, { useEffect, useState, useRef } from "react";
import StyledTableCell from "../../component/styleCell";
import StyleTableRow from "../../component/styleRow";
import clsx from "clsx";
import PropTypes from "prop-types";
import lighten from "@material-ui/core/styles/makeStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { toast } from "react-toastify";
import {
  Avatar,
  IconButton,
  Tooltip,
  Typography,
  Paper,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import axios from "axios";
import Utils from "../../../Utils/Utils";
import AlertDialogSlide from "../../component/DeleteModal/index";

function createData(indexV, id, name, receipt_type, store) {
  return {
    indexV,
    id,
    name,
    receipt_type,
    store,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "id",
    numeric: true,
    center: false,
    disablePadding: false,
    label: "Serial No.",
  },
  {
    id: "name",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Name",
  },

  {
    id: "receipt_type",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Receipt Type",
  },

  {
    id: "store",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Store",
  },
  {
    id: "events",
    center: true,
    numeric: true,
    disablePadding: false,
    label: "Evnets",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    length,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <StyledTableCell
            key={row.id}
            align={`${row.center === true ? "center" : "left"}`}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={length > 0 ? orderBy === row.id : ""}
              direction={length > 0 ? order : ""}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Template
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <></>
        ) : (
          // <Tooltip title="Delete">
          //   <IconButton aria-label="Delete">
          //     <DeleteIcon />
          //   </IconButton>
          // </Tooltip>
          <Tooltip title="">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: "0px",
    height: "31px",
    fontSize: "11px",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    paddingLeft: "15px",
    paddingRight: "15px",
    overflowX: "auto",
  },
}));

function Template() {
  const classes = useStyles();
  const inputEl = useRef(null);

  const [open, setOpen] = React.useState(false);

  const [rows, setRows] = useState([]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectId, setSelectId] = useState(null);
  const [opens, setOpens] = useState(false);

  const [totalLength, setTotalLength] = useState(0);
  const [tag, setAllTags] = useState([]);
  const [store, setStore] = useState([]);
  const [imagePreview, setimagePreview] = useState();
  const [images, setimage] = useState();
  const [formData, setformData] = useState({
    file: "",
    name: "",
    set_tem_default: "0",
    receipt_type: "thermal",
    store_name: "0",
    bar_code: "0",
    customer_info: "0",
    item_order_list: "0",
    tax_name: "0",
    print_store_hub: "0",
    tax_id: "",
  });

  let url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);
  useEffect(() => {
    axios.get(`${url}/admin/get-stores-list?limit=1000&page=1`).then((res) => {
      let arr = [];
      res.data.stores.rows.map((v) => {
        arr.push(v.id);
      });
      setStore([...arr]);
    });
  }, []);
  useEffect(() => {
    axios.get(`${url}/admin/get-tax-list?limit=1000&page=1`).then((res) => {
      let arr = [];
      res.data.taxes.rows.map((v) => {
        arr.push({ id: v.id, name: v.tax_category });
      });
      setAllTags([...arr]);
    });
  }, []);
  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectId(null);

    setformData({
      file: "",
      name: "",
      set_tem_default: "0",
      receipt_type: "thermal",
      store_name: "0",
      bar_code: "0",
      customer_info: "0",
      item_order_list: "0",
      tax_name: "1",
      print_store_hub: "0",
      tax_id: "",
    });
    setimagePreview("");
    setimage("");
  };
  const getData = () => {
    axios
      .get(
        `${url}/admin/get-template-list?limit=${rowsPerPage}&page=${parseInt(
          page + 1
        )}`
      )
      .then((res) => {
        if (res.data.receipt_templates.rows.length > 0) {
          let state = [];
          res.data.receipt_templates.rows.map((v, k) => {
            state.push(
              createData(k + 1, v.id, v.name, v.receipt_type, v.store?.name)
            );
          });
          setRows(state);
          setTotalLength(res.data.receipt_templates.count);
        }
      });
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    if (event.target.value === 10) {
      if (page + 1 <= 1) {
        setPage(0);
      } else {
        setPage(page - 1);
      }
    } else if (event.target.value === 25) {
      if (page + 1 <= 5) {
        setPage(0);
      } else {
        setPage(page - 5);
      }
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalLength - page * rowsPerPage);

  const handleRequest = async () => {
    if (selectId !== null) {
      try {
        if (!formData.name) {
          toast.error("Please enter a name first!");
        } else if (parseInt(formData.tax_name) === 1 && !formData.tax_id) {
          toast.error("Please select a Tax Id!");
        } else {
          axios
            .post(`${url}/admin/update-template`, {
              ...formData,
              store_id: store[0],
              receipt_template_id: selectId,
            })
            .then((res) => {
              toast.success("Template updated successfully!");
              console.log(res.data);
              if (images) {
                var formData = new FormData();
                formData.append("file", images);
                formData.append(
                  "receipt_template_id",
                  res.data.receipt_template.id
                );
                console.log(formData, "formData");
                axios.post(`${url}/admin/update-logo`, formData);
              }

              setTimeout(() => {
                getData();
                setOpen(false);
              }, 100);
              setformData({
                file: "",
                name: "",
                set_tem_default: "",
                receipt_type: "thermal",
                store_name: "",
                bar_code: "",
                customer_info: "",
                item_order_list: "",
                tax_name: "",
                print_store_hub: "",
                tax_id: "",
              });
              setSelectId(null);
              setimagePreview("");
              setimage("");
            })
            .catch((err) => {
              var vals = [];
              if (err.response.data.errors) {
                vals = Object.keys(err.response.data.errors).map(function(key) {
                  let str = err.response.data.errors[key]
                    .replace("This", key)
                    .toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              } else if (err.response.data.message) {
                toast.error(err.response.data.message);
              } else {
                vals = Object.keys(err.response.data.message).map(function(
                  key
                ) {
                  let str = err.response.data.message.toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              }
              toast.error(vals);
            });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        if (!formData.name) {
          toast.error("Please enter a name first!");
        } else if (
          parseInt(formData.tax_name) === 1 &&
          formData.tax_id === ""
        ) {
          toast.error("Please select a Tax Id!");
        } else {
          axios
            .post(`${url}/admin/create-template`, {
              ...formData,
              store_id: store[0],
            })
            .then((res) => {
              console.log(res.data);
              toast.success("Template created successfully!");

              if (images) {
                var formData = new FormData();
                formData.append("file", images);
                formData.append(
                  "receipt_template_id",
                  res.data.receipt_template.id
                );
                axios.post(`${url}/admin/update-logo`, formData);
              }
              setTimeout(() => {
                setformData({
                  file: "",
                  name: "",
                  set_tem_default: "",
                  receipt_type: "thermal",
                  store_name: "",
                  bar_code: "",
                  customer_info: "",
                  item_order_list: "",
                  tax_name: "",
                  print_store_hub: "",
                  tax_id: "",
                });
                getData();
                setOpen(false);
              }, 100);
              setimagePreview("");
              setimage("");
            })
            .catch((err) => {
              var vals = [];
              if (err.response.data.errors) {
                vals = Object.keys(err.response.data.errors).map(function(key) {
                  let str = err.response.data.errors[key]
                    .replace("This", key)
                    .toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              } else if (err.response.data.message) {
                toast.error(err.response.data.message);
              } else {
                vals = Object.keys(err.response.data.message).map(function(
                  key
                ) {
                  let str = err.response.data.message.toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              }
              toast.error(vals);
            });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const updateHandler = async (row) => {
    setOpen(true);
    setSelectId(row.id);
    axios.get(`${url}/admin/get-by-id-template/${row.id}`).then((res) => {
      if (res.data.receipt_template) {
        console.log(res.data.receipt_template);
        setformData({
          ...formData,
          name: res.data.receipt_template?.name,
          set_tem_default:
            res.data.receipt_template?.set_tem_default === true ? 1 : 0,
          receipt_type: res.data.receipt_template?.receipt_type,
          store_name: res.data.receipt_template?.store_name === true ? 1 : 0,
          bar_code: res.data.receipt_template?.bar_code === true ? 1 : 0,
          customer_info:
            res.data.receipt_template?.customer_info === true ? 1 : 0,
          item_order_list:
            res.data.receipt_template?.item_order_list === true ? 1 : 0,
          tax_name: res.data.receipt_template?.tax_name === true ? 1 : 0,
          print_store_hub:
            res.data.receipt_template?.print_store_hub === true ? 1 : 0,
          tax_id: res.data.receipt_template?.tax_id + "",
        });
        if (res.data.receipt_template.template_images?.file_path)
          setimagePreview(
            Utils.SERVER_URL +
              res.data.receipt_template.template_images?.file_path
          );
      }
    });
  };
  const UploadFile = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setimagePreview(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    setimage(e.target.files[0]);
  };
  const handleCloses = () => {
    setOpens(false);
    setSelectId(null);
  };
  const handleRequests = () => {
    axios
      .get(`${url}/admin/delete-receipt-template/${selectId}`)
      .then((res) => {
        setOpens(false);
        toast.success("Template View Delete Succesfully!");
        setSelectId(null);
        setTimeout(() => getData(), 300);
      })
      .catch((err) => {
        toast.error("Unable to Delete Template !");
      });
  };

  return (
    <>
      <AlertDialogSlide
        handleRequests={handleRequests}
        opens={opens}
        handleCloses={handleCloses}
        head="Templates"
        body="Are You Sure You Want to Delete Templates ?"
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        // onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {selectId !== null ? "Update" : "Add"} Template
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt="res"
                  id="target"
                  style={{ width: "80px", height: "80px" }}
                  src={imagePreview}
                  onClick={() => inputEl.current.click()}
                />
                <p
                  onClick={() => inputEl.current.click()}
                  className="text-center mt-5"
                  style={{ cursor: "pointer" }}
                >
                  Select an image
                </p>

                <input
                  //   onKeyPress={this.CheckKey}
                  className="form-control form-control-sm d-none"
                  onChange={(e) => UploadFile(e)}
                  type="file"
                  ref={inputEl}
                ></input>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ marginBottom: "-4px", fontSize: "11px" }}>Name</div>
              <TextField
                name="name"
                value={formData.name}
                onChange={(e) => onChange(e)}
                autoFocus
                size="small"
                margin="dense"
                id="currenct"
                placeholder="First Name"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Set Items
              </div>
              <Select
                name="set_tem_default"
                value={formData.set_tem_default}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder=" Set Items "
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                <MenuItem value="1">True</MenuItem>
                <MenuItem value="0">False</MenuItem>
              </Select>
            </Grid>
            {/* set_tem_default */}
            <Grid item xs={6}>
              <div style={{ marginBottom: "-4px", fontSize: "11px" }}>
                Receipt Type
              </div>
              <TextField
                disabled={true}
                name="receipt_type"
                value={formData.receipt_type}
                onChange={(e) => onChange(e)}
                size="small"
                margin="dense"
                id="currenct"
                placeholder="receipt type"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Bar Code
              </div>
              <Select
                name="bar_code"
                value={formData.bar_code}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder="Bar Code"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                <MenuItem value="1">True</MenuItem>
                <MenuItem value="0">False</MenuItem>{" "}
              </Select>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Customer info
              </div>
              <Select
                name="customer_info"
                value={formData.customer_info}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder="customer Info"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                <MenuItem value="1">True</MenuItem>
                <MenuItem value="0">False</MenuItem>{" "}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Item Order List{" "}
              </div>
              <Select
                name="item_order_list"
                value={formData.item_order_list}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder="item order list "
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                <MenuItem value="1">True</MenuItem>
                <MenuItem value="0">False</MenuItem>{" "}
              </Select>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Print Company Name{" "}
              </div>
              <Select
                name="print_store_hub"
                value={formData.print_store_hub}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder="Print Company Name"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                <MenuItem value="1">True</MenuItem>
                <MenuItem value="0">False</MenuItem>{" "}
              </Select>
            </Grid>
            {/* <Grid item xs={6}>
              {" "}
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Store Name{" "}
              </div>
              <Select
                name="store_name"
                value={formData.store_name}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder="Store Name"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                <MenuItem value="1">True</MenuItem>
                <MenuItem value="0">False</MenuItem>{" "}
              </Select>
            </Grid> */}
            <Grid item xs={6}>
              {" "}
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Tax Name
              </div>
              <Select
                name="tax_name"
                value={formData.tax_name}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder="Tax Name"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                <MenuItem value="1">True</MenuItem>
                <MenuItem value="0">False</MenuItem>{" "}
              </Select>
            </Grid>

            <Grid item xs={12}>
              {" "}
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Tax Id
              </div>
              <Select
                name="tax_id"
                value={formData.tax_id}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder="Tax Id"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                {tag?.map((v, k) => (
                  <MenuItem value={v.id}>{v.name}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: "25px" }}>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleRequest}
            color="primary"
            variant="contained"
            style={{ color: "white" }}
          >
            {selectId !== null ? "Update" : "ADD"}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <div className="p-5 d-flex justify-content-lg-between align-items-center ">
            <Typography variant="h5">Templates</Typography>
            <Button
              style={{ color: "white" }}
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Add New Template
            </Button>
          </div>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                length={rows.length}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={totalLength}
              />
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyleTableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <span style={{ paddingLeft: "22px" }}> {row.id}</span>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.receipt_type}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.store}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <button
                            onClick={() => updateHandler(row)}
                            class="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                            title="Edit details"
                            ng-click="UpdateData(role)"
                          >
                            <span class="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                  ></path>
                                  <path
                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button>
                          <button
                            onClick={() => {
                              setOpens(true);
                              setSelectId(row.id);
                            }}
                            class="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                            title="Delete"
                          >
                            <span class="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                  ></path>
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button>
                        </StyledTableCell>
                      </StyleTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalLength}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}

export default Template;
