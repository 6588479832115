import React, { useMemo, useState, useEffect } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../layout";
import { Grid, Paper, Select, MenuItem } from "@material-ui/core";
import axios from "axios";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Demo1Dashboard } from "./Demo1Dashboard";
import { Demo2Dashboard } from "./Demo2Dashboard";
import { Demo3Dashboard } from "./Demo3Dashboard";
import { Demo4Dashboard } from "./Demo4Dashboard";
import { Demo5Dashboard } from "./Demo5Dashboard";
import { Demo6Dashboard } from "./Demo6Dashboard";
import { Demo7Dashboard } from "./Demo7Dashboard";

export function Dashboard() {
  const [user, setuser] = useState({});
  const [graphdate, setgraphdate] = useState("monthly");
  const [chartData, setChartData] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);

  let url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios
      .get(`${url}/users/authorize`)
      .then((res) => setuser({ ...res.data.user }));
  }, []);
  // useEffect(() => {
  //   axios
  //     .post(`${url}/admin/sale-over-time-report`, {
  //       store_id: "",
  //       view_by: graphdate,
  //       start_date: "2021-10-01",
  //     })
  //     .then((res) => {
  //       let totalTens = 0;
  //       let totalSal = 0;
  //       let totaldis = 0;
  //       if (graphdate === "monthly") {
  //         let first = [res?.data?.sale_over_time?.monthsList];
  //         let second = [res?.data?.sale_over_time?.item];

  //         let final = [];
  //         for (let i = 0; i < first.length; i++) {
  //           final.push({ name: first[i], uv: second[i] });
  //         }
  //         setChartData([...final]);
  //       } else {
  //         let first = [...res.data.sale_over_time.years];
  //         let second = [...res.data.sale_over_time.item];
  //         let final = [];
  //         for (let i = 0; i < first.length; i++) {
  //           final.push({
  //             name: first[first.length - 1 - i],
  //             uv: second[first.length - 1 - i],
  //           });
  //         }
  //         setChartData([...final]);
  //       }
  //       res.data.sale_over_time.details.map((v) => {
  //         console.log(
  //           parseInt(v.total_transactions),
  //           parseInt(v.total_sale),
  //           parseInt(v.total_discount)
  //         );
  //         totalTens =
  //           totalTens + parseInt(v.total_transactions) !== "Nan"
  //             ? parseInt(v.total_transactions)
  //             : 0;
  //         totalSal =
  //           totalSal + parseInt(v.total_sale) !== "Nan"
  //             ? parseInt(v.total_sale)
  //             : 0;
  //         totaldis =
  //           totaldis + parseInt(v.total_discount) !== "Nan"
  //             ? parseInt(v.total_discount)
  //             : 0;
  //       });
  //       console.log(totalTens, totalSal, totaldis);
  //       setTotalTransaction(totalTens);
  //       setTotalSales(totalSal);
  //       setTotalDiscount(totaldis);
  //     });
  // }, [graphdate]);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      demo: objectPath.get(uiService.config, "demo"),
    };
  }, [uiService]);
  return (
    <Paper elevation={2} style={{ padding: "25px", marginTop: "-20px" }}>
      <Grid container>
        <Grid item xs={12}>
          <p style={{ fontSize: "20px", paddingBottom: "25px" }}>
            Welcome back,{" "}
            <span style={{ textTransform: "capitlized" }}>
              {" "}
              {user?.firstName}!
            </span>
          </p>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>TODAY'S SALES</p>
            <p style={{ fontSize: "18px" }}>RM {totalSales}</p>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p># OF TRANSACTIONS</p>
            <p style={{ fontSize: "18px" }}> {totalTransaction}</p>
          </div>{" "}
        </Grid>

        <Grid item xs={4}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>Total Discount</p>
            <p style={{ fontSize: "18px" }}>{totalDiscount} </p>
          </div>
        </Grid>

        {/* <Grid item xs={3}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>TOTAL MEMBERS</p>
            <p style={{ fontSize: "18px" }}>2263</p>
          </div>
        </Grid> */}
        <Grid item xs={6}>
          {" "}
          <p
            style={{
              fontSize: "18px",
              marginTop: "25px",
              fontWeight: "400",
              marginBottom: "25px",
            }}
          >
            {" "}
            Total sales
          </p>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          {" "}
          <div
            style={{
              fontSize: "18px",
              marginTop: "25px",
              fontWeight: "400",
              marginBottom: "25px",
            }}
          >
            <Select
              fullWidth
              name="graphdate"
              value={graphdate}
              onChange={(event) => setgraphdate(event.target.value)}
              margin="dense"
              id="currenct"
              variant="outlined"
              style={{
                borderRadius: "0px",
                height: "31px",
                fontSize: "11px",
              }}
            >
              <MenuItem value="yearly">Yearly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ width: "100%", height: 320, marginLeft: "-20px" }}>
            <ResponsiveContainer>
              <AreaChart
                data={chartData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Area
                  name="Sale"
                  type="monotone"
                  dataKey="uv"
                  stroke="#37B7F3"
                  fill="#E4F0F2"
                  dot={true}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Grid>
      </Grid>
      {/* {layoutProps.demo === 'demo1' && <Demo1Dashboard />}
        {layoutProps.demo === 'demo2' && <Demo2Dashboard />}
        {layoutProps.demo === 'demo3' && <Demo3Dashboard />}
        {layoutProps.demo === 'demo4' && <Demo4Dashboard />}
        {layoutProps.demo === 'demo5' && <Demo5Dashboard />}
        {layoutProps.demo === 'demo6' && <Demo6Dashboard />}
        {layoutProps.demo === 'demo7' && <Demo7Dashboard />} */}
    </Paper>
  );
}
