import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import StyledTableCell from "../../component/styleCell";
import StyleTableRow from "../../component/styleRow";
import AlertDialogSlide from "../../component/DeleteModal/index";
import clsx from "clsx";
import PropTypes from "prop-types";
import lighten from "@material-ui/core/styles/makeStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  IconButton,
  Tooltip,
  Typography,
  Paper,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  MenuItem,
  Checkbox,
  Avatar,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Utils from "../../../Utils/Utils";

function createData(id, name, type, sku, cost) {
  return { id, name, type, sku, cost };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "id",
    numeric: true,
    center: false,
    disablePadding: false,
    label: "Serial No.",
  },
  {
    id: "name",
    numeric: true,
    center: false,
    disablePadding: false,
    label: "Name",
  },
  { id: "type", numeric: true, disablePadding: false, label: "Type" },
  {
    id: "sku",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "SKU",
  },
  {
    id: "cost",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Cost",
  },
  {
    id: "Events",
    center: true,
    numeric: true,
    disablePadding: false,
    label: "Events ",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    length,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <StyledTableCell
            key={row.id}
            align={`${row.center === true ? "center" : "left"}`}
            sortDirection={orderBy === row.id ? order : false}
          >
            {" "}
            <TableSortLabel
              active={length > 0 ? orderBy === row.id : ""}
              direction={length > 0 ? order : ""}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h7" id="tableTitle">
            Product
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <></>
        ) : (
          // <Tooltip title="Delete">
          //   <IconButton aria-label="Delete">
          //     <DeleteIcon />
          //   </IconButton>
          // </Tooltip>
          <Tooltip title="">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: "1px",
    height: "31px",
    fontSize: "11px",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    paddingLeft: "15px",
    paddingRight: "15px",
    overflowX: "auto",
  },
}));

function Product() {
  const classes = useStyles();
  const inputEl = useRef(null);

  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = useState(false);

  const [rows, setRows] = useState([]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectId, setSelectId] = useState(null);
  const [totalLength, setTotalLength] = useState(0);
  const [variants, setvariants] = useState([]);
  const [is_saleable, setIs_saleable] = useState(true);
  const [per, setPercentage] = useState("0");
  const [SendingCategory, setSendingCategory] = useState({});
  const [SendingTags, setSendingTags] = useState([]);
  const [formData, setformData] = useState({
    name: "",
    type: "",
    sku: "",
    category_id: "",
    supplier_id: "",
    printer_id: "",
    umt_id: "",
    tax_id: "",
    cost: "",
    price_type: "fixed",
    tax: "default",
    inclusive_price: 0,
    exclusive_price: 0,
  });
  const [category, setAllCategory] = useState([]);
  const [productTag, setProductTags] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [unit, setAllUnit] = useState([]);
  const [printer, setPrinter] = useState([]);
  const [products, setAllProduct] = useState([]);
  const [composits, setcomposits] = useState([]);
  const [allTax, setAllTax] = useState([]);
  const [imagePreview, setimagePreview] = useState();
  const [images, setimage] = useState();

  useEffect(() => {
    setformData({
      ...formData,
      inclusive_price:
        parseFloat(formData.exclusive_price) +
        (parseFloat(formData.exclusive_price) / 100) * parseInt(per),
    });
  }, [per]);
  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  let url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);
  useEffect(() => {
    axios
      .get(`${url}/admin/get-unit-measurement-list?limit=1000&page=1`)
      .then((res) => {
        if (res.data.unit_measurement.rows.length > 0) {
          let arr = [];
          res.data.unit_measurement.rows.map((v, k) => {
            arr.push({ id: v.id, value: v.value });
          });
          setAllUnit([...arr]);
        }
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${url}/admin/get-product-list?limit=1000&page=1&is_saleable=true`)
      .then((res) => {
        if (res.data.products.rows.length > 0) {
          let arr = [];
          res.data.products.rows.map((v, k) => {
            arr.push({ id: v.id, name: v.name });
          });
          setAllProduct([...arr]);
        }
      });
  }, []);
  useEffect(() => {
    axios.get(`${url}/admin/get-printer-list?limit=1000&page=1`).then((res) => {
      if (res.data.printers.rows.length > 0) {
        let arr = [];
        res.data.printers.rows.map((v, k) => {
          arr.push({ id: v.id, name: v.name });
        });
        setPrinter([...arr]);
      }
    });
  }, []);
  useEffect(() => {
    axios
      .get(`${url}/admin/get-category-list?limit=1000&page=1`)
      .then((res) => {
        if (res.data.categories.rows.length > 0) {
          let arr = [];
          res.data.categories.rows.map((v, k) => {
            arr.push({ id: v.id, name: v.name });
          });
          setAllCategory([...arr]);
        }
      });
  }, []);
  useEffect(() => {
    axios.get(`${url}/admin/get-tax-list?limit=1000&page=1`).then((res) => {
      if (res.data.taxes.rows.length > 0) {
        let arr = [];
        res.data.taxes.rows.map((v, k) => {
          arr.push({
            id: v.id,
            tax_category: v.tax_category,
            percentage: v.percentage,
          });
        });
        setAllTax([...arr]);
      }
    });
  }, []);
  useEffect(() => {
    axios.get(`${url}/admin/prod-tag-list?limit=1000&page=1`).then((res) => {
      if (res.data.prod_tags.rows.length > 0) {
        let arr = [];
        res.data.prod_tags.rows.map((v, k) => {
          arr.push({ id: v.id, name: v.name });
        });
        setProductTags([...arr]);
      }
    });
  }, []);
  useEffect(() => {
    axios
      .get(`${url}/admin/get-supplier-list?limit=1000&page=1`)
      .then((res) => {
        if (res.data.suppliers.rows.length > 0) {
          let arr = [];
          res.data.suppliers.rows.map((v, k) => {
            arr.push({ id: v.id, name: v.name });
          });
          setSupplier([...arr]);
        }
      });
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
    setformData({
      name: "",
      type: "",
      sku: "",
      category_id: "",
      supplier_id: "",
      printer_id: "",
      umt_id: "",
      tax_id: "",
      cost: "",
      price_type: "fixed",
      tax: "default",
      inclusive_price: "",
      exclusive_price: "",
    });
  };

  const handleClose = () => {
    setvariants([]);
    setcomposits([]);
    setSendingCategory([]);
    setSendingTags([]);
    setimage();
    setimagePreview();
    setformData({
      name: "",
      type: "",
      sku: "",
      category_id: "",
      supplier_id: "",
      printer_id: "",
      umt_id: "",
      tax_id: "",
      cost: "",
      price_type: "fixed",
      tax: "",
      inclusive_price: 0,
      exclusive_price: 0,
    });
    setOpen(false);
    setSelectId(null);
  };

  const getData = () => {
    axios
      .get(
        `${url}/admin/get-product-list?limit=${rowsPerPage}&page=${parseInt(
          page + 1
        )}`
      )
      .then((res) => {
        if (res?.data?.products?.rows.length > 0) {
          let state = [];
          res.data.products.rows.map((v, k) => {
            state.push(createData(v.id, v.name, v.type, v.sku, v.cost));
          });
          setRows(state);
          setTotalLength(res.data.products.count);
        }
      });
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    if (event.target.value === 10) {
      if (page + 1 <= 1) {
        setPage(0);
      } else {
        setPage(page - 1);
      }
    } else if (event.target.value === 25) {
      if (page + 1 <= 5) {
        setPage(0);
      } else {
        setPage(page - 5);
      }
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalLength - page * rowsPerPage);

  const handleRequest = async () => {
    if (formData.name === "") {
      toast.error("Please Enter a Name!");
    } else if (formData.type === "") {
      toast.error("Please Select a Type!");
    } else if (formData.sku === "") {
      toast.error("Please Enter a SKU");
    } else if (formData.sku.length < 4 || formData.sku.length > 12) {
      toast.error("SKU must be less Greater than 4 and Less than 12 !");
    } else if (formData.price_type === "") {
      toast.error("Please Enter Price type!");
    } else if (SendingCategory === null) {
      toast.error("Please Select Category!");
    } else if (formData.cost === "") {
      toast.error("Please Enter cost!");
    } else if (
      parseInt(formData.cost) > parseInt(formData.inclusive_price) ||
      parseInt(formData.cost) > parseInt(formData.exclusive_price)
    ) {
      toast.error("Price should  be less than or equal to exclusive price");
    } else if (formData.supplier_id === "") {
      toast.error("Please Select Supplier!");
    } else if (formData.tax_id === "") {
      toast.error("Please Select a Tax!");
    } else if (formData.umt_id === "") {
      toast.error("Please Selct UOM! ");
    } else if (formData.printer_id === "") {
      toast.error("Please Select a Printer !");
    } else {
      if (selectId !== null) {
        try {
          let tag = [];
          SendingTags.map((v, k) => {
            tag.push(v.id);
          });
          console.log();
          let obj = {
            type: formData.type,
            name: formData.name,
            sku: formData.sku,
            price_type: formData.price_type,
            cost: formData.cost,
            umt_id: formData.umt_id,
            category_id: SendingCategory.id,
            supplier_id: formData.supplier_id,
            tax_id: formData.tax_id,
            printer_id: formData.printer_id,
            is_saleable: is_saleable === true ? 1 : 0,
            variants: variants,
            composits: composits,
            inclusive_price: formData.inclusive_price,
            exclusive_price: formData.exclusive_price,
            pro_tag_ids: tag,
          };
          console.log(obj);

          axios
            .post(`${url}/admin/update-product`, {
              ...obj,
              id: selectId,
            })
            .then(async (res) => {
              var formData = new FormData();

              formData.append("file", images);
              formData.append("id", res.data.product.id);
              console.log(formData, "formData");
              if (images) {
                await axios.post(`${url}/admin/update-product-image`, formData);
              }

              toast.success("Product updated successfully!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              await handleClose();
              await getData();
            })
            .catch((err) => {
              console.log(err, "err");
              var vals = [];
              if (err.response.data.errors) {
                vals = Object.keys(err.response.data.errors).map(function(key) {
                  let str = err.response.data.errors[key]
                    .replace("This", key)
                    .toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              } else {
                vals = Object.keys(err.response.data.message).map(function(
                  key
                ) {
                  let str = err.response.data.message.toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              }
              toast.error(vals.length > 0 ? vals[0] : vals, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });

          await setTimeout(() => {
            getData();
            setOpen(false);
          }, 100);

          setSelectId(null);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let tag = [];
          SendingTags.map((v, k) => {
            tag.push(v.id);
          });
          let obj = {
            type: formData.type,
            name: formData.name,
            sku: formData.sku,
            price_type: formData.price_type,
            cost: formData.cost,
            umt_id: formData.umt_id,
            category_id: SendingCategory.id,
            supplier_id: formData.supplier_id,
            tax_id: formData.tax_id,
            printer_id: formData.printer_id,
            is_saleable: is_saleable === true ? 1 : 0,
            exclusive_price: parseFloat(formData.exclusive_price),
            inclusive_price: parseFloat(formData.inclusive_price),
            variants: variants,
            composits: composits,
            pro_tag_ids: tag,
          };
          axios
            .post(`${url}/admin/create-product`, obj)
            .then(async (res) => {
              var formData = new FormData();
              formData.append("file", images);
              formData.append("id", res.data.product.id);

              if (images) {
                await axios.post(`${url}/admin/update-product-image`, formData);
              }
              console.log(res.data.product.id, "enter");
              await handleClose();

              toast.success("Product Created Successfully !");
              await setTimeout(() => {
                getData();
              }, 200);
            })
            .catch((err) => {
              console.log(err, "err");
              var vals = [];
              if (err.response.data.errors) {
                vals = Object.keys(err.response.data.errors).map(function(key) {
                  let str = err.response.data.errors[key]
                    .replace("This", key)
                    .toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              } else {
                vals = Object.keys(err.response.data.message).map(function(
                  key
                ) {
                  let str = err.response.data.message.toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              }
              toast.error(vals.length > 0 ? vals[0] : vals);
            });
          // setTimeout(() => {
          //   getData();
          //   setOpen(false);
          // }, 100);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  const updateHandler = async (row) => {
    setOpen(true);
    setSelectId(row.id);

    axios.get(`${url}/admin/get-by-id-product/${row.id}`).then((res) => {
      setformData({
        type: res.data.product.type,
        name: res.data.product.name,
        sku: res.data.product.sku,
        price_type: res.data.product.price_type,
        cost: res.data.product.cost,
        umt_id: res.data.product.umt_id,
        category_id: res.data.product.category_id,
        supplier_id: res?.data?.product?.supplier?.id,
        tax_id: res.data.product.tax_id,
        printer_id: res.data.product.printer_id,
        is_saleable: res.data.product.is_saleable,
        variants: res.data.product.optional_groups,
        exclusive_price: res.data.product.exclusive_price,
        inclusive_price: res.data.product.inclusive_price,
        // composits: composits,
        // pro_tag_ids: selectedTag,
      });
      console.log(res.data, "product");
      let sCategory = [];
      let sTag = [];
      let sVariants = [];
      let sComposit = [];

      res.data.product.composit.map((v, k) => {
        sComposit.push({
          id: v?.child_product?.id,
          quantity: v?.quantity,
        });
      });
      setcomposits(sComposit);
      sCategory.push(res.data.product.category_id);
      let updatesCategory = category.filter((p) => sCategory.includes(p.id));
      updatesCategory.map((v) => {
        setSendingCategory({
          id: v?.id,
          name: v?.name,
        });
      });
      res.data.product.p_tag.map((v, k) => {
        sTag.push({ id: v.id, name: v.name });
      });
      setSendingTags(sTag);
      res.data.product.optional_groups.map((v, k) => {
        sVariants.push({
          name: v.name,
          at_least: v.at_least,
          maximum: v.maximum,
          options: [],
        });

        v.options.map((s) => {
          console.log(s);
          if (v?.options.length > 0) {
            sVariants[k].options.push({
              product_id: s.product_id,
              id: s.id,
              price: s.price,
              quantity: s.quantity,
            });
          }
        });
      });
      setvariants(sVariants);
      if (res.data.product.file_path) {
        setimagePreview(Utils.SERVER_URL + res.data.product.file_path);
      }
      let tax = allTax.find((v) => v.id === res.data.product.tax_id);
      setPercentage(tax?.percentage);
      console.log(res.data.product, "Datatatt");
    });
  };

  const GroupHandler = () => {
    setvariants([
      ...variants,
      {
        name: "",
        at_least: 0,
        maximum: 0,
        // you need to inset quantity here
        options: [{ id: "", price: "", quantity: "" }],
      },
    ]);
  };
  const optionHandler = (i) => {
    const obj = variants;
    obj[i].options.push({ product_id: 0, id: "", price: "" });
    setvariants([...obj]);
  };
  const deleteGroup = (f) => {
    let obj = variants;
    obj.splice(f, 1);
    setvariants([...obj]);
  };
  const deleteOption = (f, s) => {
    let obj = [...variants];
    obj[f].options.splice(s, 1);
    setvariants([...obj]);
  };
  const onChangeGroupName = (i, e) => {
    let obj = variants;
    obj[i][e.target.name] = e.target.value;
    setvariants([...obj]);
  };
  const addComponentHandler = () => {
    setcomposits([...composits, { id: "", quantity: "" }]);
  };
  const deleteComponentHandler = (i) => {
    let obj = composits;
    obj.splice(i, 1);
    setvariants([...obj]);
  };

  const UploadFile = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setimagePreview(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    setimage(e.target.files[0]);
  };
  const handleCloses = () => {
    setOpens(false);
    setSelectId(null);
  };
  const handleRequests = () => {
    axios
      .get(`${url}/admin/delete-product/${selectId}`)
      .then((res) => {
        setOpens(false);
        toast.success("Product Delete Successfully!");
        setTimeout(() => getData(), 200);
        setSelectId(null);
      })
      .catch((err) => {
        toast.error("Unable to Delete Product!");
      });
  };
  return (
    <>
      <AlertDialogSlide
        handleRequests={handleRequests}
        opens={opens}
        handleCloses={handleCloses}
        head="Product"
        body="Are You Sure You Want to Delete Product?"
      />
      <Dialog
        fullScreen
        fullWidth
        open={open}
        // onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            display: "flex ",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <ArrowBackIcon
            style={{ cursor: "pointer", marginTop: "-1px", marginRight: "5px" }}
            onClick={handleClose}
          />{" "}
          <span> {selectId !== null ? "Update" : "Add"} Product</span>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <Grid container spacing={2}>
            <Grid container item spacing={2} xs={8}>
              <Grid item xs={12}>
                <div
                  style={{ marginBottom: "-5px", fontSize: "11px" }}
                  className="-mb-4"
                >
                  Name
                </div>
                <TextField
                  name="name"
                  autoFocus
                  onChange={(e) => onChange(e)}
                  margin="dense"
                  value={formData.name}
                  id="currenct"
                  placeholder="Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  className=""
                  InputProps={{
                    classes: {
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{ marginBottom: "-5px", fontSize: "11px" }}
                  className="mb-1"
                >
                  Select Catgeory
                </div>
                <Select
                  disabled={selectId}
                  name="type"
                  value={formData.type}
                  onChange={(e) => onChange(e)}
                  fullWidth
                  displayEmpty
                  margin="dense"
                  id="currenct"
                  placeholder="Role"
                  variant="outlined"
                  style={{
                    borderRadius: "0px",
                    height: "31px",
                    fontSize: "11px",
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Catgeory
                  </MenuItem>
                  <MenuItem value="inventory">Inventory</MenuItem>
                  <MenuItem value="composit">Composit</MenuItem>

                  {/* <MenuItem value="store manager">Store Manager</MenuItem>
                <MenuItem value="cashier">Cashier</MenuItem>
                <MenuItem value="back office">Back Office</MenuItem> */}
                </Select>
              </Grid>
            </Grid>
            <Grid container item xs={4} spacing={2}>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    alt="res"
                    id="target"
                    style={{ width: "80px", height: "80px" }}
                    src={imagePreview}
                    onClick={() => inputEl.current.click()}
                  />
                  <p
                    onClick={() => inputEl.current.click()}
                    className="text-center mt-5"
                    style={{ cursor: "pointer" }}
                  >
                    Select an image
                  </p>

                  <input
                    //   onKeyPress={this.CheckKey}
                    className="form-control form-control-sm d-none"
                    onChange={(e) => UploadFile(e)}
                    type="file"
                    ref={inputEl}
                  ></input>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                SKU
              </div>
              <TextField
                onChange={(e) => onChange(e)}
                margin="dense"
                name="sku"
                value={formData.sku}
                id="currenct"
                placeholder="SKU"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Category
              </div>
              <Autocomplete
                value={SendingCategory}
                onChange={(event, newValue) => {
                  setSendingCategory(newValue);
                }}
                fullWidth
                size="small"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
                id="combo-box-demo"
                options={category}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Category"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Product Tags
              </div>
              <Autocomplete
                value={SendingTags}
                onChange={(event, newValue) => {
                  setSendingTags(newValue);
                }}
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
                multiple
                id="tags-outlined"
                options={productTag}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                size="small"
                renderInput={(params) => (
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    {...params}
                    variant="outlined"
                    placeholder="Product Tags"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Supplier
              </div>
              <Select
                name="supplier_id"
                value={formData.supplier_id}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder="Supplier"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                {/* <MenuItem value="" disabled>
                  Select Supplier
                </MenuItem> */}
                {supplier?.map((v, k) => (
                  <MenuItem key={k} value={v.id}>
                    {v.name}{" "}
                  </MenuItem>
                ))}

                {/* <MenuItem value="store manager">Store Manager</MenuItem>
                <MenuItem value="cashier">Cashier</MenuItem>
                <MenuItem value="back office">Back Office</MenuItem> */}
              </Select>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "-12px",
              }}
            >
              <Checkbox
                color="primary"
                checked={is_saleable}
                onChange={(e) => setIs_saleable(e.target.checked)}
              />
              Is this Product Saleable?
            </Grid>
            <Grid item xs={12}>
              <Typography className="mt-5" variant="h5">
                Price
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ display: "none" }}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Price Type
              </div>
              <Select
                name="price_type"
                value={formData.price_type}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder="Role"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                <MenuItem value="fixed">Fixed</MenuItem>
                <MenuItem value="variable">Variable</MenuItem>
                <MenuItem value="by unit">By Unit</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Cost
              </div>
              <TextField
                name="cost"
                onChange={(e) => onChange(e)}
                margin="dense"
                value={formData.cost}
                id="currenct"
                placeholder="Cost"
                type="number"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>

            {formData.price_type === "by unit" ? (
              <div
                style={{
                  width: "100%",
                  margin: "5px",
                  border: "1px solid #dbdbdb",
                  padding: "20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="-mb-4"
                    >
                      Tax Exclusive Price
                    </div>
                    <TextField
                      name="exclusive_price"
                      margin="dense"
                      onChange={(e) => onChange(e)}
                      value={
                        parseInt(formData.inclusive_price) -
                        (parseInt(formData.inclusive_price) * parseInt(per)) /
                          100
                      }
                      id="currenct"
                      type="text"
                      fullWidth
                      variant="outlined"
                      className=""
                      InputProps={{
                        classes: {
                          root: classes.input,
                        },
                      }}
                    />
                  </Grid>

                  <Grid
                    xs={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "18px",
                      marginTop: "20px",
                    }}
                  >
                    +
                  </Grid>

                  <Grid item xs={3}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="mb-1"
                    >
                      Tax
                    </div>
                    <Select
                      name="tax_id"
                      value={formData.tax_id}
                      onChange={(e) => {
                        onChange(e);
                        let test = allTax.findIndex(
                          (v) => v.id === e.target.value
                        );
                        if (test > -1) {
                          setPercentage(allTax[test].percentage);
                        } else {
                          setPercentage(1);
                        }
                      }}
                      fullWidth
                      displayEmpty
                      margin="dense"
                      id="currenct"
                      placeholder="Role"
                      variant="outlined"
                      style={{
                        borderRadius: "0px",
                        height: "31px",
                        fontSize: "11px",
                      }}
                    >
                      {allTax?.map((v, k) => (
                        <MenuItem key={k} value={v.id}>
                          {v.percentage}% : {v.tax_category}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid
                    xs={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "18px",
                      marginTop: "20px",
                    }}
                  >
                    =
                  </Grid>

                  <Grid item xs={3}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="-mb-4"
                    >
                      Tax Inclusive Price
                    </div>
                    <TextField
                      name="inclusive_price"
                      onChange={(e) => onChange(e)}
                      margin="dense"
                      value={formData.inclusive_price}
                      id="currenct"
                      placeholder="Cost"
                      type="number"
                      fullWidth
                      variant="outlined"
                      className=""
                      InputProps={{
                        classes: {
                          root: classes.input,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            ) : formData.price_type === "variable" ? (
              <>
                <Grid item xs={12}>
                  <div
                    style={{ marginBottom: "-5px", fontSize: "11px" }}
                    className="mb-1"
                  >
                    Tax
                  </div>
                  <Select
                    name="tax_id"
                    value={formData.tax_id}
                    onChange={(e) => {
                      onChange(e);
                      let test = allTax.findIndex(
                        (v) => v.id === e.target.value
                      );
                      if (test > -1) {
                        setPercentage(allTax[test].percentage);
                      } else {
                        setPercentage(1);
                      }
                    }}
                    fullWidth
                    displayEmpty
                    margin="dense"
                    id="currenct"
                    placeholder="Role"
                    variant="outlined"
                    style={{
                      borderRadius: "0px",
                      height: "31px",
                      fontSize: "11px",
                    }}
                  >
                    {allTax?.map((v, k) => (
                      <MenuItem key={k} value={v.id}>
                        {v.percentage}% : {v.tax_category}
                      </MenuItem>
                    ))}
                  </Select>{" "}
                </Grid>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  margin: "5px",
                  border: "1px solid #dbdbdb",
                  padding: "20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="mb-1"
                    >
                      UOM
                    </div>
                    <Select
                      name="umt_id"
                      value={formData.umt_id}
                      onChange={(e) => onChange(e)}
                      fullWidth
                      displayEmpty
                      margin="dense"
                      id="currenct"
                      placeholder="Role"
                      variant="outlined"
                      style={{
                        borderRadius: "0px",
                        height: "31px",
                        fontSize: "11px",
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Unit
                      </MenuItem>

                      {unit?.map((v, k) => (
                        <MenuItem key={k} value={v.id}>
                          {v.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={2}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="-mb-4"
                    >
                      Tax Exclusive Price
                    </div>
                    <TextField
                      name="exclusive_price"
                      margin="dense"
                      onChange={(e) => {
                        setformData({
                          ...formData,
                          exclusive_price: e.target.value,
                          inclusive_price:
                            parseFloat(e.target.value) +
                            (parseFloat(e.target.value) / 100) * parseInt(per),
                        });
                      }}
                      value={formData.exclusive_price}
                      // value={
                      //   parseInt(formData.inclusive_price) -
                      //   (parseInt(formData.inclusive_price) * parseInt(per)) /
                      //     100
                      // }
                      id="currenct"
                      type="text"
                      fullWidth
                      variant="outlined"
                      className=""
                      InputProps={{
                        classes: {
                          root: classes.input,
                        },
                      }}
                    />
                  </Grid>

                  <Grid
                    xs={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "18px",
                      marginTop: "2px",
                    }}
                  >
                    +
                  </Grid>

                  <Grid item xs={3}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="mb-1"
                    >
                      Tax
                    </div>
                    <Select
                      name="tax_id"
                      value={formData.tax_id}
                      onChange={(e) => {
                        onChange(e);
                        let test = allTax.findIndex(
                          (v) => v.id === e.target.value
                        );

                        if (test > -1) {
                          setPercentage(allTax[test].percentage);
                        } else {
                          setPercentage(0);
                        }
                      }}
                      fullWidth
                      displayEmpty
                      margin="dense"
                      id="currenct"
                      placeholder="Role"
                      variant="outlined"
                      style={{
                        borderRadius: "0px",
                        height: "31px",
                        fontSize: "11px",
                      }}
                    >
                      {allTax?.map((v, k) => (
                        <MenuItem key={k} value={v.id}>
                          {v.percentage}% : {v.tax_category}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid
                    xs={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "18px",
                      marginTop: "2px",
                    }}
                  >
                    =
                  </Grid>

                  <Grid item xs={3}>
                    <div
                      style={{ marginBottom: "-5px", fontSize: "11px" }}
                      className="-mb-4"
                    >
                      Tax Inclusive Price
                    </div>
                    <TextField
                      name="inclusive_price"
                      onChange={(e) => {
                        setformData({
                          ...formData,
                          inclusive_price: e.target.value,
                          exclusive_price:
                            parseFloat(e.target.value) +
                            (parseFloat(e.target.value) / 100) * parseInt(per),
                        });
                      }}
                      margin="dense"
                      value={formData.inclusive_price}
                      id="currenct"
                      placeholder="Cost"
                      type="number"
                      fullWidth
                      variant="outlined"
                      className=""
                      InputProps={{
                        classes: {
                          root: classes.input,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            <Grid item xs={12}>
              <Typography className="mt-5" variant="h5">
                Kitchen Printer
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Printer
              </div>
              <Select
                name="printer_id"
                value={formData.printer_id}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder="Role"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                {printer?.map((v, k) => (
                  <MenuItem key={k} value={v.id}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {formData.type === "composit" ? (
              <>
                <Grid item xs={12}>
                  <Typography className="mt-5" variant="h5">
                    Variants
                  </Typography>
                </Grid>

                {variants?.map((v, k) => (
                  <Grid
                    key={k}
                    container
                    spacing={2}
                    style={{ paddingLeft: "8px", paddingBottom: "8px" }}
                  >
                    <Grid item xs={5}>
                      <div
                        style={{ marginBottom: "-5px", fontSize: "11px" }}
                        className="-mb-4"
                      >
                        Group Name
                      </div>
                      <TextField
                        name="name"
                        value={v.name}
                        onChange={(e) => onChangeGroupName(k, e)}
                        margin="dense"
                        id="currenct"
                        placeholder="Group Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        className=""
                        InputProps={{
                          classes: {
                            root: classes.input,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <div
                        onClick={() => deleteGroup(k)}
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          paddingTop: "12px",
                          fontSize: "15px",
                          color: "#E23F33",
                          cursor: "pointer",
                        }}
                      >
                        <DeleteForeverOutlinedIcon /> Delete
                      </div>
                    </Grid>

                    <Grid item xs={5}></Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      At Least
                    </Grid>
                    <Grid item xs={1}>
                      <Select
                        name="at_least"
                        value={v?.at_least}
                        onChange={(e) => onChangeGroupName(k, e)}
                        fullWidth
                        displayEmpty
                        margin="dense"
                        id="currenct"
                        placeholder="Role"
                        variant="outlined"
                        style={{
                          borderRadius: "0px",
                          height: "31px",
                          fontSize: "11px",
                        }}
                      >
                        {new Array(21)?.fill(1)?.map((v, k) => (
                          <MenuItem key={k} value={k}>
                            {k}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Up to the maximum of
                    </Grid>
                    <Grid item xs={1}>
                      <Select
                        name="maximum"
                        value={v?.maximum}
                        onChange={(e) => onChangeGroupName(k, e)}
                        fullWidth
                        displayEmpty
                        margin="dense"
                        id="currenct"
                        placeholder="Role"
                        variant="outlined"
                        style={{
                          borderRadius: "0px",
                          height: "31px",
                          fontSize: "11px",
                        }}
                      >
                        {console.log(parseInt(v.at_least))}
                        {new Array(21 - parseInt(v.at_least))
                          ?.fill(1)
                          ?.map((s, k) => (
                            <MenuItem key={k} value={k + 1}>
                              {parseInt(v.at_least) + k}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>

                    <Grid item xs={6}></Grid>

                    <Grid
                      item
                      xs={2}
                      style={{
                        marginTop: "10px",
                        fontSize: "15px",
                        fontWeight: "semi-bold",
                      }}
                    >
                      Option Name
                    </Grid>
                    {/* <Grid
                      item
                      xs={2}
                      style={{
                        marginTop: "10px",
                        fontSize: "13px",
                        fontWeight: "semi-bold",
                      }}
                    >
                      Add To Price (tax-excluded)
                    </Grid> */}

                    <Grid
                      item
                      xs={2}
                      style={{
                        marginTop: "10px",
                        fontSize: "13px",
                        fontWeight: "semi-bold",
                      }}
                    >
                      Add To Price (tax-included)
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        marginTop: "10px",
                        fontSize: "13px",
                        fontWeight: "semi-bold",
                      }}
                    >
                      Quantity
                    </Grid>
                    <Grid item xs={6}></Grid>
                    {v?.options?.map((value, i) => (
                      <Grid
                        key={i}
                        container
                        spacing={2}
                        style={{ paddingLeft: "8px" }}
                      >
                        <Grid item xs={2} className="mt-3">
                          <Autocomplete
                            value={
                              selectId === null
                                ? products.find(
                                    (e) => variants[k].options[i].id === e.id
                                  )
                                : products.find(
                                    (e) =>
                                      variants[k].options[i].product_id === e.id
                                  )
                            }
                            onChange={(event, newValue) => {
                              if (newValue) {
                                let obj = variants;
                                if (selectId === null) {
                                  obj[k].options[i].product_id = 0;
                                  obj[k].options[i].id = newValue.id;
                                } else {
                                  obj[k].options[i].id = 0;
                                  obj[k].options[i].product_id = newValue.id;
                                }
                                setvariants([...obj]);
                              }
                            }}
                            fullWidth
                            size="small"
                            style={{
                              borderRadius: "0px",
                              height: "31px",
                              fontSize: "11px",
                            }}
                            id="combo-box-demo"
                            options={products}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Category"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        {/* <Grid item xs={2}>
                          <TextField
                            disabled={true}
                            margin="dense"
                            id="currenct"
                            placeholder="tax incude Price"
                            type="text"
                            fullWidth
                            variant="outlined"
                            className=""
                            value={value.price}
                            InputProps={{
                              classes: {
                                root: classes.input,
                              },
                            }}
                          />
                        </Grid> */}

                        <Grid item xs={2}>
                          <TextField
                            onChange={(event) => {
                              let obj = variants;
                              obj[k].options[i].price = event.target.value;
                              setvariants([...obj]);
                            }}
                            value={value.price}
                            margin="dense"
                            id="currenct"
                            placeholder="Price"
                            type="number"
                            fullWidth
                            variant="outlined"
                            className=""
                            InputProps={{
                              classes: {
                                root: classes.input,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            onChange={(event) => {
                              let obj = variants;
                              obj[k].options[i].quantity = event.target.value;
                              setvariants([...obj]);
                            }}
                            value={value.quantity}
                            margin="dense"
                            id="currenct"
                            placeholder="Quantity"
                            type="number"
                            fullWidth
                            variant="outlined"
                            className=""
                            InputProps={{
                              classes: {
                                root: classes.input,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            onClick={() => deleteOption(k, i)}
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              paddingTop: "6px",
                              fontSize: "15px",
                              color: "#E23F33",
                              cursor: "pointer",
                            }}
                          >
                            <DeleteForeverOutlinedIcon /> Delete
                          </div>
                        </Grid>
                        <Grid item xs={4}></Grid>
                      </Grid>
                    ))}

                    <Grid item xs={3}>
                      <Typography
                        style={{ cursor: "pointer" }}
                        className="ml-4"
                        color="primary"
                        onClick={() => optionHandler(k)}
                      >
                        <AddCircleIcon /> Add another Option
                      </Typography>
                    </Grid>
                    <Grid item xs={7}></Grid>
                  </Grid>
                ))}

                <Grid item xs={3}>
                  <Typography
                    style={{ cursor: "pointer" }}
                    color="primary"
                    onClick={() => GroupHandler()}
                  >
                    <AddCircleIcon /> Add another Group
                  </Typography>
                </Grid>
                <Grid item xs={9}></Grid>

                <Grid item xs={12}>
                  <Typography
                    className="mt-5"
                    variant="h5"
                    style={{ cursor: "pointer" }}
                  >
                    Inventory
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <p>
                    *Components can only consist of products with tracked
                    inventory and not other composite products.
                  </p>
                </Grid>

                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <StyleTableRow>
                      <StyledTableCell style={{ fontSize: "14px" }}>
                        Component Name
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        style={{ fontSize: "14px" }}
                      >
                        Default Usage
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ fontSize: "14px" }}
                      >
                        Action
                      </StyledTableCell>
                    </StyleTableRow>
                  </TableHead>
                  <TableBody>
                    {composits.map((v, k) => (
                      <StyleTableRow>
                        <StyledTableCell>
                          {" "}
                          <Autocomplete
                            value={products.find(
                              (e) => composits[k].id === e.id
                            )}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                let obj = composits;
                                obj[k].id = newValue.id;
                                setcomposits([...obj]);
                              }
                            }}
                            fullWidth
                            size="small"
                            style={{
                              borderRadius: "0px",
                              height: "31px",
                              fontSize: "11px",
                              marginTop: "4px",
                            }}
                            id="combo-box-demo"
                            options={products}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Category"
                                variant="outlined"
                              />
                            )}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextField
                            onChange={(event) => {
                              let obj = composits;
                              obj[k].quantity = event.target.value;
                              setcomposits([...obj]);
                            }}
                            value={v.quantity}
                            margin="dense"
                            id="currenct"
                            placeholder="quantity"
                            type="number"
                            fullWidth
                            variant="outlined"
                            className=""
                            InputProps={{
                              classes: {
                                root: classes.input,
                              },
                            }}
                          />
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {" "}
                          <div
                            onClick={() => deleteComponentHandler(k)}
                            style={{
                              fontSize: "15px",
                              color: "#E23F33",
                              cursor: "pointer",
                            }}
                          >
                            <DeleteForeverOutlinedIcon /> Delete
                          </div>
                        </StyledTableCell>
                      </StyleTableRow>
                    ))}
                  </TableBody>
                </Table>
                <Grid item xs={12}>
                  <Typography
                    onClick={() => addComponentHandler()}
                    style={{ cursor: "pointer" }}
                    className=" mt-5 mb-5"
                    color="primary"
                  >
                    <AddCircleIcon /> Add Component
                  </Typography>
                </Grid>
              </>
            ) : (
              ""
            )}

            {/* <Grid
              container
              spacing={2}
              style={{ border: "1px solid #dbdbdb " }}
            >
              <Grid item xs={5} style={{ padding: "15px 15px 15px 20px" }}>
                <b> Component Name</b>
              </Grid>
              <Grid item xs={5} style={{ padding: "15px 15px 15px 20px" }}>
                <b> Default Usage</b>
              </Grid>
              <Grid item xs={2} style={{ padding: "15px 15px 15px 20px" }}>
                <b> Action</b>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ color: "#dbdbdb ", marginTop: "5px" }} />
              </Grid>

              <Grid item xs={5} className="ml-4 mt-3 mb-2">
                <Autocomplete
                  fullWidth
                  size="small"
                  style={{
                    borderRadius: "0px",
                    height: "31px",
                    fontSize: "11px",
                  }}
                  id="combo-box-demo"
                  options={products}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Category"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={5}
                className="mb-2 "
                style={{ marginTop: "1.5px" }}
              >
                <TextField
                  onChange={(event) => {}}
                  margin="dense"
                  id="currenct"
                  placeholder="Price"
                  type="number"
                  fullWidth
                  variant="outlined"
                  className=""
                  InputProps={{
                    classes: {
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid xs={2}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    paddingTop: "12px",
                    fontSize: "15px",
                    color: "#E23F33",
                    cursor: "pointer",
                  }}
                >
                  <DeleteForeverOutlinedIcon /> Delete
                </div>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{ cursor: "pointer" }}
                  className=" mt-5 mb-5"
                  color="primary"
                >
                  <AddCircleIcon /> Add Component
                </Typography>
              </Grid>
            </Grid> */}

            {/* <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Sale Layout
              </div>
              <TextField
                onChange={(e) => onChange(e)}
                margin="dense"
              
              value={formData}  id="currenct"
                placeholder="Sale"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: "25px" }}>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleRequest}
            color="primary"
            variant="contained"
            style={{ color: "white" }}
          >
            {selectId !== null ? "Update" : "ADD"}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <div className="p-5 d-flex justify-content-lg-between align-items-center ">
            <Typography variant="h5">Product</Typography>
            <Button
              style={{ color: "white" }}
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Add New Product
            </Button>
          </div>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                length={rows.length}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={totalLength}
              />
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced - table - checkbox - ${index}`;

                    return (
                      <StyleTableRow
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <span style={{ paddingLeft: "23px" }}>{row.id}</span>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.type}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.sku}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.cost}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <span>
                            <button
                              style={{ marginLeft: "-25px" }}
                              onClick={() => updateHandler(row)}
                              className="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                              title="Edit details"
                              ng-click="UpdateData(role)"
                            >
                              <span className="svg-icon svg-icon-md">
                                <svg viewBox="0 0 24 24" version="1.1">
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect
                                      x="0"
                                      y="0"
                                      width="24"
                                      height="24"
                                    ></rect>
                                    <path
                                      d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                      transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                    ></path>
                                    <path
                                      d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                      opacity="0.3"
                                    ></path>
                                  </g>
                                </svg>
                              </span>
                            </button>
                            <button
                              onClick={() => {
                                setOpens(true);
                                setSelectId(row.id);
                              }}
                              className="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                              title="Delete"
                            >
                              <span className="svg-icon svg-icon-md">
                                <svg viewBox="0 0 24 24" version="1.1">
                                  <g
                                    stroke="none"
                                    stroke-width="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect
                                      x="0"
                                      y="0"
                                      width="24"
                                      height="24"
                                    ></rect>
                                    <path
                                      d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                    ></path>
                                    <path
                                      d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                      fill="#000000"
                                      opacity="0.3"
                                    ></path>
                                  </g>
                                </svg>
                              </span>
                            </button>
                          </span>
                        </StyledTableCell>
                      </StyleTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalLength}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}

export default Product;
