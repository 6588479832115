import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import clsx from "clsx";
import PropTypes from "prop-types";
import lighten from "@material-ui/core/styles/makeStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Utils from "../../../Utils/Utils";
import AlertDialogSlide from "../../component/DeleteModal/index";
import StyledTableCell from "../../component/styleCell";
import StyleTableRow from "../../component/styleRow";

import {
  Checkbox,
  Select,
  MenuItem,
  Grid,
  Switch,
  IconButton,
  Tooltip,
  Typography,
  Paper,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
function createData(id, name, check, enable) {
  return { id, name, check, enable };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

let url = process.env.REACT_APP_API_URL;
const headRows = [
  {
    id: "id",
    numeric: true,
    center: false,
    disablePadding: false,
    label: "Serial No.",
  },
  { id: "name", numeric: true, disablePadding: false, label: "Name" },
  {
    id: "check",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Off/ON",
  },
  {
    id: "Events",
    center: true,
    numeric: true,
    disablePadding: false,
    label: "Events ",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    length,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <StyledTableCell
            key={row.id}
            align={`${row.center === true ? "center" : "left"}`}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={length > 0 ? orderBy === row.id : ""}
              direction={length > 0 ? order : ""}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Customer Ads
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <></>
        ) : (
          // <Tooltip title="Delete">
          //   <IconButton aria-label="Delete">
          //     <DeleteIcon />
          //   </IconButton>
          // </Tooltip>
          <Tooltip title="">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: "0px",
    height: "31px",
    fontSize: "11px",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    paddingLeft: "15px",
    paddingRight: "15px",
    overflowX: "auto",
  },
}));

function CustomerAds() {
  const classes = useStyles();
  const inputEl = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    permotion_text: "",
  });
  const [is_saleable, setIs_saleable] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [imageArray, setImageArray] = React.useState([]);
  const [imagePreviewArray, setImagePreviewArray] = useState([]);
  const [rows, setRows] = useState([]);
  const [products, setAllProduct] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectId, setSelectId] = useState(null);
  const [opens, setOpens] = useState(false);

  const [feildData, setfeildData] = useState("");
  const [totalLength, setTotalLength] = useState(0);
  const [selectedProducts, setSelectProduct] = useState([]);

  let url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectId(null);
    setSelectProduct([]);
    setIs_saleable(false);
    setFormData({
      name: "",
      type: "",
      permotion_text: "",
    });
    setImagePreviewArray([]);
    setImageArray([]);
  };
  const getData = () => {
    axios
      .get(
        `${url}/admin/get-list?limit=${rowsPerPage}&page=${parseInt(page + 1)}`
      )
      .then((res) => {
        if (res.data.ads.rows.length > 0) {
          let state = [];
          res.data.ads.rows.map((v, k) => {
            state.push(createData(v.id, v.name, v.enable));
          });
          setRows(state);
          setTotalLength(res.data.ads.count);
        }
      });
  };

  useEffect(() => {
    axios.get(`${url}/admin/get-stores-list?limit=1000&page=1`).then((res) => {
      if (res.data.stores.rows.length > 0) {
        let arr = [];
        res.data.stores.rows.map((v, k) => {
          arr.push({ id: v.id, name: v.name });
        });
        setAllProduct([...arr]);
      }
    });
  }, []);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    if (event.target.value === 10) {
      if (page + 1 <= 1) {
        setPage(0);
      } else {
        setPage(page - 1);
      }
    } else if (event.target.value === 25) {
      if (page + 1 <= 5) {
        setPage(0);
      } else {
        setPage(page - 5);
      }
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalLength - page * rowsPerPage);

  const handleRequest = async () => {
    if (selectId !== null) {
      try {
        var form = new FormData();
        let arr = [];
        selectedProducts.map((v) => {
          arr.push(v.id);
        });

        form.append("id", selectId);
        form.append("name", formData.name);
        form.append("enable", is_saleable ? 1 : 0);
        form.append("type", formData.type);
        form.append("stores", JSON.stringify(arr));
        form.append("text", formData.permotion_text);

        if (formData.name === "") {
          toast.error("Name is Required");
        } else if (formData.type === "") {
          toast.error("Type is Required");
        } else if (formData.type === "images" && imagePreviewArray.length < 1) {
          toast.erorr("Please Select At Least One Image !");
        } else {
          axios
            .post(`${url}/admin/update-ad`, form)
            .then((res) => {
              if (res.data.message === "ad updated successfully.") {
                toast.success("Ad Updated Successfully!");
                setOpen(false);
                setFormData({
                  name: "",
                  type: "",
                  permotion_text: "",
                });
                setIs_saleable(false);

                setSelectProduct([]);
                setImageArray([]);
                setImagePreviewArray([]);
                setTimeout(() => {
                  getData();
                }, 100);
              } else {
                toast.error(res.data.message, {
                  position: "top-right",
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            })
            .catch((err) => {
              var vals = [];
              if (err.response.data.errors) {
                vals = Object.keys(err.response.data.errors).map(function(key) {
                  let str = err.response.data.errors[key]
                    .replace("This", key)
                    .toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              } else if (err.response.data.message) {
                vals = Object?.keys(err?.response?.data?.message)?.map(function(
                  key
                ) {
                  let str = err.response.data.message.toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              }
              toast.error(vals.length > 0 ? vals[0] : vals, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        var form = new FormData();
        let arr = [];
        selectedProducts.map((v) => {
          arr.push(v.id);
        });
        for (let i = 0; i < imageArray.length; i++) {
          form.append("files", imageArray[i]);
        }

        form.append("name", formData.name);
        form.append("enable", is_saleable ? 1 : 0);
        form.append("type", formData.type);
        form.append("stores", JSON.stringify(arr));
        form.append("text", formData.permotion_text);
        if (formData.name === "") {
          toast.error("Name is Required");
        } else if (formData.type === "") {
          toast.error("Type is Required");
        } else if (formData.type === "images" && imagePreviewArray.length < 1) {
          toast.error("Please Select At Least One Image !");
        } else {
          axios
            .post(`${url}/admin/create-ad`, form)
            .then((res) => {
              if (res.data.message === "Ad created successfully.") {
                toast.success("Ad Create Successfully!", {
                  position: "top-right",
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setOpen(false);
                setFormData({
                  name: "",
                  type: "",
                  permotion_text: "",
                });
                setSelectProduct([]);
                setImageArray([]);
                setImagePreviewArray([]);
                setIs_saleable(false);

                setTimeout(() => {
                  getData();
                }, 100);
              } else {
                toast.error(res.data.message, {
                  position: "top-right",
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            })
            .catch((err) => {
              var vals = [];
              if (err.response.data.errors) {
                vals = Object.keys(err.response.data.errors).map(function(key) {
                  let str = err.response.data.errors[key]
                    .replace("This", key)
                    .toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              } else {
                vals = Object.keys(err.response.data.message).map(function(
                  key
                ) {
                  let str = err.response.data.message.toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              }
              toast.error(vals.length > 0 ? vals[0] : vals, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const updateHandler = async (cid, name) => {
    let img = [];
    setOpen(true);
    setSelectId(cid);

    axios.get(`${url}/admin/get-by-id/${cid}`).then((res) => {
      setFormData({
        name: res.data.ad.name,
        type: res.data.ad.type,
        permotion_text: res.data.ad.text,
      });
      if (res.data.ad.imageURL1) {
        img.push(Utils.SERVER_URL + "public/images/" + res.data.ad.imageURL1);
      }
      if (res.data.ad.imageURL2) {
        img.push(Utils.SERVER_URL + "public/images/" + res.data.ad.imageURL2);
      }

      if (res.data.ad.imageURL3) {
        img.push(Utils.SERVER_URL + "public/images/" + res.data.ad.imageURL3);
      }

      if (res.data.ad.imageURL4) {
        img.push(Utils.SERVER_URL + "public/images/" + res.data.ad.imageURL4);
      }
      setImagePreviewArray([...img]);

      let a = [];
      res.data.ad.stores.map((v) => {
        products.find((e) =>
          e.id === v.store_id ? a.push({ id: e.id, name: e.name }) : ""
        );
      });
      setSelectProduct([...a]);
      setIs_saleable(res.data.ad.enable);
    });
  };
  const EnableHandler = (status, id) => {
    axios.get(`${url}/admin/get-by-id/${id}`).then((res) => {
      let store = [];
      res.data.ad.stores.map((v, k) => {
        store.push(v.store_id);
      });
      let form = new FormData();
      form.append("id", res.data.ad.id);
      form.append("name", res.data.ad.name);
      form.append("type", res.data.ad.type);
      form.append("stores", JSON.stringify(store));
      form.append("text", res.data.ad.text);
      form.append("enable", !status === true ? 1 : 0);

      axios.post(`${url}/admin/update-ad`, form).then((res) => {
        toast.success("Status Updated Succesfully!");
        setTimeout(() => getData(), 100);
      });
    });
  };
  const UploadFile = (e) => {
    let images = [...imageArray];
    let imagePreview = [...imagePreviewArray];
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        imagePreview.push(reader.result);
        setImagePreviewArray([...imagePreview]);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    images.push(e.target.files[0]);
    setImageArray([...images]);
    if (selectId) {
      let arr = [];
      selectedProducts.map((v) => {
        arr.push(v.id);
      });

      let form = new FormData();
      form.append("id", selectId);
      form.append("name", formData.name);
      form.append("enable", is_saleable ? 1 : 0);
      form.append("type", formData.type);
      form.append("stores", JSON.stringify(arr));
      form.append("text", formData.permotion_text);
      form.append("file", e.target.files[0]);
      form.append("image_location", imagePreview.length + 1);
      axios.post(`${url}/admin/update-ad`, form);
    }
  };
  const clearImage = async (i) => {
    let imagePre = [...imagePreviewArray];
    let images = [...imageArray];

    imagePre.splice(i, 1);
    images.splice(i, 1);
    if (selectId) {
      axios.post(`${url}/admin/delete-ad-image`, {
        id: selectId,
        image_location: i + 1,
      });
    }

    await setImageArray([...images]);
    await setImagePreviewArray([...imagePre]);
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleCloses = () => {
    setOpens(false);
    setSelectId(null);
  };
  const handleRequests = () => {
    axios
      .get(`${url}/admin/delete-ad/${selectId}`)
      .then((res) => {
        setOpens(false);
        toast.success("Ad Delete Succesfully!");
        setSelectId(null);
        setTimeout(() => getData(), 300);
      })
      .catch((err) => {
        toast.error("Unable to Delete Ad!");
      });
  };
  return (
    <>
      <AlertDialogSlide
        handleRequests={handleRequests}
        opens={opens}
        handleCloses={handleCloses}
        head="Customer Ads"
        body="Are You Sure You Want to Delete Ad?"
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        // onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {selectId !== null ? "Update" : "Add"} Customer Ads
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Customer Display Ads Name
              </div>
              <TextField
                name="name"
                value={formData.name}
                onChange={(e) => onChange(e)}
                autoFocus
                size="small"
                margin="dense"
                id="currenct"
                placeholder=" Name"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Store
              </div>
              <Autocomplete
                value={selectedProducts}
                onChange={(event, newValue) => {
                  setSelectProduct(newValue);
                }}
                limitTags={2}
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
                multiple
                id="tags-outlined"
                options={products}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                size="small"
                renderInput={(params) => (
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    {...params}
                    variant="outlined"
                    placeholder="Stores"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Type
              </div>
              <Select
                name="type"
                value={formData.type}
                onChange={(e) => onChange(e)}
                fullWidth
                displayEmpty
                margin="dense"
                id="currenct"
                placeholder=" Cutomer Type"
                variant="outlined"
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
              >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="images">Images</MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "-12px",
              }}
            >
              <Checkbox
                color="primary"
                checked={is_saleable}
                onChange={(e) => setIs_saleable(e.target.checked)}
              />
              Is this Ad Enable?
            </Grid>
            {formData.type === "text" ? (
              <Grid item xs={12}>
                <div
                  style={{ marginBottom: "-5px", fontSize: "11px" }}
                  className="-mb-4"
                >
                  Advertisement text
                </div>
                <TextField
                  name="permotion_text"
                  id="outlined-multiline-static"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={formData.permotion_text}
                  multiline
                  rows={4}
                  size="small"
                  margin="dense"
                  placeholder="Advertisement text"
                  type="text"
                  fullWidth
                  variant="outlined"
                  className=""
                />
              </Grid>
            ) : formData.type === "images" ? (
              <>
                {imagePreviewArray.map((v, k) => (
                  <Grid item xs={3}>
                    <div
                      style={{
                        height: "60px",
                        width: "80%",
                        backgroundImage: `url(${v})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat  ",
                        position: "relative",
                      }}
                    >
                      <CloseIcon
                        style={{ position: "relative", left: "80%" }}
                        onClick={() => clearImage(k)}
                      />
                    </div>
                  </Grid>
                ))}
                <Grid item xs={3}>
                  <span className="svg-icon menu-icon">
                    {imagePreviewArray.length > 3 ? (
                      ""
                    ) : (
                      <i
                        class="fas fa-plus-circle"
                        onClick={() => inputEl.current.click()}
                        style={{ fontSize: "40px" }}
                      ></i>
                    )}

                    <input
                      //   onKeyPress={this.CheckKey}
                      className="form-control form-control-sm d-none "
                      onChange={(e) => UploadFile(e)}
                      accept="image/*"
                      type="file"
                      ref={inputEl}
                    ></input>
                  </span>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRequest} color="primary">
            {selectId !== null ? "Update" : "ADD"}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <div className="p-5 d-flex justify-content-lg-between align-items-center ">
            <Typography variant="h5">Customer Ads</Typography>
            <Button
              style={{ color: "white" }}
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Add New Ads
            </Button>
          </div>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                length={rows.length}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={totalLength}
              />
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyleTableRow
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <span style={{ paddingLeft: "23px" }}> {row.id}</span>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Switch
                            onChange={(e) => EnableHandler(row.check, row.id)}
                            size="small"
                            color="primary"
                            checked={row.check}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <button
                            onClick={() => updateHandler(row.id)}
                            class="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                            title="Edit details"
                            ng-click="UpdateData(role)"
                          >
                            <span class="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                  ></path>
                                  <path
                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button>
                          <button
                            class="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                            title="Delete"
                            onClick={() => {
                              setOpens(true);
                              setSelectId(row.id);
                            }}
                          >
                            <span class="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                  ></path>
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button>
                        </StyledTableCell>
                      </StyleTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalLength}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}

export default CustomerAds;
