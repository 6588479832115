import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";

import moment from "moment";
import {
  Checkbox,
  Paper,
  Button,
  TextField,
  DialogActions,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";

import axios from "axios";
function createData(
  indexV,
  id,
  business_name,
  display_price_tax,
  enable_rounding,
  apply_rounding,
  group_same_items,
  closing_time
) {
  return {
    indexV,
    id,
    business_name,
    display_price_tax,
    enable_rounding,
    apply_rounding,
    group_same_items,
    closing_time,
  };
}

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: "0px",
    height: "31px",
    fontSize: "11px",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    paddingLeft: "15px",
    paddingRight: "15px",
    overflowX: "auto",
  },
}));

function AccountSetting() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectId, setSelectId] = useState(null);

  const [totalLength, setTotalLength] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [allTimeZone, setAllTimeZone] = useState([]);
  const [allTax, setAllTax] = useState([]);
  const [allMinCurrecy, setAllMinCurrecy] = useState([]);
  const [allCountry, setAllCountry] = useState([]);
  const [selectedMin, setSelectedMin] = useState({});
  const [selectedTax, setSelectedTax] = useState({});
  const [selectedTimeZone, setSelectedTimeZone] = useState({});
  const [selectedTag, setSelectedTag] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [currency, setAllCurrency] = useState([{ id: 0, name: "" }]);
  const [formData, setformData] = useState({
    business_name: "",
    time_zone_id: "",
    tax_id: "",
    country_id: "",
    min_currency_id: "",
    display_price_tax: "",
    enable_rounding: 0,
    apply_rounding: "",
    group_same_items: "",
    closing_time: "",
    currency_id: "",
  });
  let history = useHistory();

  let url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const getData = () => {
    axios
      .get(
        `${url}/admin/get-account-list?limit=${rowsPerPage}&page=${parseInt(
          page + 1
        )}`
      )
      .then((res) => {
        if (res.data.account_settings.rows.length > 0) {
          let state = [];
          res.data.account_settings.rows.map((v, k) => {
            state.push(
              createData(
                k + 1,
                v.id,
                v.business_name,
                v.display_price_tax,
                v.enable_rounding,
                v.apply_rounding,
                v.group_same_items,
                v.closing_time
              )
            );
          });
          setRows(state);
          setTotalLength(res.data.account_settings.count);
        }
      });
  };

  useEffect(() => {
    let allCountryf = [];
    let allTimeZonef = [];
    let currencyf = [];
    let allcountryf = [];
    let min_currenciesf = [];
    let taxf = [];
    axios
      .get(`${url}/admin/get-currency-list?limit=1000&page=1`)
      .then((res) => {
        if (res.data.currencies.rows.length > 0) {
          console.log(res.data.currencies.rows, " res.data.currencies");
          let arr = [];
          res.data.currencies.rows.map((v) => {
            arr.push({
              id: v.id,
              name: v.symbol,
            });
          });
          currencyf = arr;
          setAllCurrency([...arr]);
        }
      });
    axios
      .get(`${url}/admin/get-time-zone-list?limit=1000&page=1`)
      .then((res) => {
        if (res.data.time_zones.rows.length > 0) {
          let arr = [];
          res.data.time_zones.rows.map((v) => {
            arr.push({
              id: v.id,
              name: v.name,
            });
          });
          allTimeZonef = arr;
          setAllTimeZone(arr);
        }
      });
    axios.get(`${url}/admin/get-country-list?limit=1000&page=1`).then((res) => {
      if (res.data.countries.rows.length > 0) {
        let arr = [];
        res.data.countries.rows.map((v) => {
          arr.push({
            id: v.id,
            name: v.name,
          });
        });
        allcountryf = arr;
        setAllCountry(arr);
      }
    });
    axios
      .get(`${url}/admin/get-currencies-list?limit=1000&page=1`)
      .then((res) => {
        if (res.data.min_currencies.rows.length > 0) {
          let arr = [];
          res.data.min_currencies.rows.map((v) => {
            arr.push({
              id: v.id,
              name: v.value.toString(),
            });
          });
          min_currenciesf = arr;
          setAllMinCurrecy(arr);
        }
      });
    axios.get(`${url}/admin/get-tax-list?limit=1000&page=1`).then((res) => {
      if (res.data.taxes.rows.length > 0) {
        let arr = [];
        res.data.taxes.rows.map((v) => {
          arr.push({
            id: v.id,
            name: v.tax_category,
          });
        });
        taxf = arr;
        setAllTax(arr);
      }
    });
    axios
      .get(`${url}/admin/get-by-id-account/1`)
      .then((res) => {
        console.log(res.data.account_setting);
        setSelectId(1);
        setformData({
          business_name: res.data.account_setting.business_name,
          time_zone_id: res.data.account_setting.time_zone_id,
          tax_id: res.data.account_setting.tax_id,
          country_id: res.data.account_setting.country_id,
          min_currency_id: res.data.account_setting.min_currency_id,
          display_price_tax: res.data.account_setting.display_price_tax,
          enable_rounding: res.data.account_setting.enable_rounding,
          apply_rounding: res.data.account_setting.apply_rounding,
          group_same_items: res.data.account_setting.group_same_items,
          closing_time: moment(res.data.account_setting.closing_time, [
            "h:mm A",
          ]).format("HH:mm"),
        });
        setSelectedMin(
          min_currenciesf.find(
            (e) => res.data.account_setting.min_currency_id === e.id
          )
        );
        setSelectedTax(
          taxf.find((e) => res.data.account_setting.tax_id === e.id)
        );
        setSelectedTimeZone(
          allTimeZonef.find(
            (e) => res.data.account_setting.time_zone_id === e.id
          )
        );
        setSelectedCountry(
          allcountryf.find((e) => res.data.account_setting.country_id === e.id)
        );
        setSelectedCurrency(
          currencyf.find((e) => res.data.account_setting.currency_id === e.id)
        );
        console.log(currencyf, "hee");
      })

      .catch((err) => setSelectId(null));
  }, []);

  const handleRequest = async () => {
    if (selectId !== null) {
      console.log(formData);
      if (formData.business_name === "") {
        toast.error("Business Name Should Not Be Null !");
      } else if (formData.apply_rounding === "") {
        toast.error("Apply Rounding Is Required!");
      } else if (formData.group_same_items === "") {
        toast.error("Group Same Item is Required!");
      } else if (formData.display_price_tax === "") {
        toast.error("Display Price Tax is Required!");
      } else if (formData.time_zone_id === "") {
        toast.error("Time Zone is Required!");
      } else if (formData.tax_id === "") {
        toast.error("Tax is Required!");
      } else if (formData.min_currency_id === "") {
        toast.error("Min Currency is Required!");
      } else if (formData.country_id === "") {
        toast.error("Country is Required!");
      } else if (formData.closing_time === "") {
        toast.error("Time is Required!");
      } else {
        try {
          let obj = {
            ...formData,
            enable_rounding: formData.enable_rounding === true ? 1 : 0,
            account_setting_id: selectId,
          };
          axios
            .post(`${url}/admin/update-account-setting`, obj)
            .then((res) => {
              console.log(res);
            })
            .then((res) => {
              toast.success("Setting Updated Succesfully!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setOpen(false);
            })
            .catch((err) => {
              var vals = [];
              console.log(err?.response.data?.errors, "eooo");
              if (err.response.data.errors) {
                vals = Object.keys(err.response.data.errors).map(function(key) {
                  let str = err.response.data.errors[key]
                    .replace("This", key)
                    .toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              } else {
                vals = Object.keys(err.response.data.message).map(function(
                  key
                ) {
                  let str = err.response.data.message.toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      try {
        let tag = [];
        selectedTag.map((v, k) => {
          tag.push(v.id);
        });
        if (formData.business_name === "") {
          toast.error("Business Name Should Not Be Null !");
        } else if (formData.apply_rounding === "") {
          toast.error("Apply Rounding Is Required!");
        } else if (formData.group_same_items === "") {
          toast.error("Group Same Item is Required!");
        } else if (formData.display_price_tax === "") {
          toast.error("Display Price Tax is Required!");
        } else if (formData.time_zone_id === "") {
          toast.error("Time Zone is Required!");
        } else if (formData.tax_id === "") {
          toast.error("Tax is Required!");
        } else if (formData.min_currency_id === "") {
          toast.error("Min Currency is Required!");
        } else if (formData.country_id === "") {
          toast.error("Country is Required!");
        } else if (formData.closing_time === "") {
          toast.error("Time is Required!");
        } else {
          console.log(formData);
          axios
            .post(`${url}/admin/create-account-setting`, {
              ...formData,
              enable_rounding: formData.enable_rounding === true ? 1 : 0,
            })
            .then((res) => {
              if (
                res.data.message === "account setting created successfully."
              ) {
                toast.success("Account Setting Created Succesfully!");
              }
              setOpen(false);
            })
            .catch((err) => {
              var vals = [];
              if (err.response.data.errors) {
                vals = Object.keys(err.response.data.errors).map(function(key) {
                  let str = err.response.data.errors[key]
                    .replace("This", key)
                    .toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              } else {
                vals = Object.keys(err.response.data.message).map(function(
                  key
                ) {
                  let str = err.response.data.message.toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              }
              toast.error(vals.length > 0 ? vals[0] : vals);
            });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            height: "9vh",
            marginTop: "-70px",
            alignItems: "center",
          }}
        >
          <span>
            <Checkbox
              color="primary"
              checked={formData.enable_rounding}
              onChange={(e) => {
                setformData({
                  ...formData,
                  enable_rounding: !formData.enable_rounding,
                });
              }}
            />
          </span>
          Enable Rounding
        </div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div
              style={{ marginBottom: "-5px", fontSize: "11px" }}
              className="-mb-4"
            >
              Business Name
            </div>
            <TextField
              name="business_name"
              value={formData.business_name}
              onChange={(e) => onChange(e)}
              autoFocus
              size="small"
              margin="dense"
              id="currenct"
              placeholder=" Name"
              type="text"
              fullWidth
              variant="outlined"
              className=""
              InputProps={{
                classes: {
                  root: classes.input,
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{ marginBottom: "-5px", fontSize: "11px" }}
              className="mb-1"
            >
              Apply Rounding
            </div>
            <Select
              name="apply_rounding"
              value={formData.apply_rounding}
              onChange={(e) => onChange(e)}
              fullWidth
              displayEmpty
              margin="dense"
              id="currenct"
              placeholder="Apply"
              variant="outlined"
              style={{
                borderRadius: "0px",
                height: "31px",
                fontSize: "11px",
              }}
            >
              <MenuItem value="all transactions">All Transactions</MenuItem>
              <MenuItem value="cash only">Cash Only</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{ marginBottom: "-5px", fontSize: "11px" }}
              className="mb-1"
            >
              Group Same Items
            </div>
            <Select
              name="group_same_items"
              value={formData.group_same_items}
              onChange={(e) => onChange(e)}
              fullWidth
              displayEmpty
              margin="dense"
              id="currenct"
              placeholder=""
              variant="outlined"
              style={{
                borderRadius: "0px",
                height: "31px",
                fontSize: "11px",
              }}
            >
              <MenuItem value="ungroup">Ungroup</MenuItem>
              <MenuItem value="group">Group</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={6}>
            <div
              style={{ marginBottom: "-5px", fontSize: "11px" }}
              className="mb-1"
            >
              Display Price Tax
            </div>
            <Select
              name="display_price_tax"
              value={formData.display_price_tax}
              onChange={(e) => onChange(e)}
              fullWidth
              displayEmpty
              margin="dense"
              id="currenct"
              placeholder=""
              variant="outlined"
              style={{
                borderRadius: "0px",
                height: "31px",
                fontSize: "11px",
              }}
            >
              <MenuItem value="exclusive">Exclusive</MenuItem>
              <MenuItem value="inclusive">Inclusive</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={6}>
            <div
              style={{ marginBottom: "-5px", fontSize: "11px" }}
              className="mb-1"
            >
              Time Zone
            </div>

            <Autocomplete
              value={selectedTimeZone}
              onChange={(event, newValue) => {
                setformData({ ...formData, time_zone_id: newValue?.id });
                setSelectedTimeZone(newValue);
              }}
              style={{
                borderRadius: "0px",
                height: "31px",
                fontSize: "11px",
              }}
              id="time_zone_id"
              options={allTimeZone}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              size="small"
              renderInput={(params) => (
                <TextField
                  InputProps={{
                    classes: {
                      root: classes.input,
                    },
                  }}
                  {...params}
                  variant="outlined"
                  placeholder="Time zone"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{ marginBottom: "-5px", fontSize: "11px" }}
              className="-mb-4"
            >
              Closing Time
            </div>
            <TextField
              name="closing_time"
              value={formData.closing_time}
              onChange={(e) => onChange(e)}
              size="small"
              margin="dense"
              id="currenct"
              placeholder="First Name"
              type="time"
              fullWidth
              variant="outlined"
              className=""
              InputProps={{
                classes: {
                  root: classes.input,
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <div
              style={{ marginBottom: "-5px", fontSize: "11px" }}
              className="mb-1"
            >
              Min Currency
            </div>

            <Autocomplete
              value={selectedMin}
              onChange={(event, newValue) => {
                setformData({ ...formData, min_currency_id: newValue?.id });
                setSelectedMin(newValue);
              }}
              style={{
                borderRadius: "0px",
                height: "31px",
                fontSize: "11px",
              }}
              id="time_zone_id"
              options={allMinCurrecy}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              size="small"
              renderInput={(params) => (
                <TextField
                  InputProps={{
                    classes: {
                      root: classes.input,
                    },
                  }}
                  {...params}
                  variant="outlined"
                  placeholder="Min Currency"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{ marginBottom: "-5px", fontSize: "11px" }}
              className="mb-1"
            >
              Country
            </div>

            <Autocomplete
              value={selectedCountry}
              onChange={(event, newValue) => {
                setformData({ ...formData, country_id: newValue?.id });
                setSelectedCountry(newValue);
              }}
              style={{
                borderRadius: "0px",
                height: "31px",
                fontSize: "11px",
              }}
              id="time_zone_id"
              options={allCountry}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              size="small"
              renderInput={(params) => (
                <TextField
                  InputProps={{
                    classes: {
                      root: classes.input,
                    },
                  }}
                  {...params}
                  variant="outlined"
                  placeholder="Country"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{ marginBottom: "-5px", fontSize: "11px" }}
              className="mb-1"
            >
              Currency
            </div>

            <Autocomplete
              value={selectedCurrency}
              onChange={(event, newValue) => {
                setformData({ ...formData, currency_id: newValue?.id });
                setSelectedCurrency(newValue);
              }}
              style={{
                borderRadius: "0px",
                height: "31px",
                fontSize: "11px",
              }}
              id="currency_id"
              options={currency}
              getOptionLabel={(option) => option?.name || ""}
              filterSelectedOptions
              size="small"
              renderInput={(params) => (
                <TextField
                  InputProps={{
                    classes: {
                      root: classes.input,
                    },
                  }}
                  {...params}
                  variant="outlined"
                  placeholder="Currency"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{ marginBottom: "-5px", fontSize: "11px" }}
              className="mb-1"
            >
              Tax
            </div>

            <Autocomplete
              value={selectedTax}
              onChange={(event, newValue) => {
                setformData({ ...formData, tax_id: newValue?.id });
                setSelectedTax(newValue);
              }}
              style={{
                borderRadius: "0px",
                height: "31px",
                fontSize: "11px",
              }}
              id="tax_id"
              options={allTax}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              size="small"
              renderInput={(params) => (
                <TextField
                  InputProps={{
                    classes: {
                      root: classes.input,
                    },
                  }}
                  {...params}
                  variant="outlined"
                  placeholder="Tax"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <Button
                onClick={() => history.goBack()}
                color="primary"
                variant="outlined"
              >
                Back
              </Button>
              <Button
                onClick={handleRequest}
                color="primary"
                variant="contained"
                style={{ color: "white" }}
              >
                Save
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
        <Paper className={classes.paper}></Paper>
      </div>
    </>
  );
}

export default AccountSetting;
