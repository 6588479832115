import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import StyledTableCell from "../../component/styleCell";
import StyleTableRow from "../../component/styleRow";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import PropTypes from "prop-types";
import lighten from "@material-ui/core/styles/makeStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  IconButton,
  Tooltip,
  Typography,
  Paper,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

import AlertDialogSlide from "../../component/DeleteModal/index";

import axios from "axios";
function createData(
  name,
  city,
  fulfill_method,
  country,
  phone_number,
  email,
  company_name,
  id,
  qr_code,
  extra_info,
  BR_number,
  sst_id_no,
  GST_id,
  website,
  operation_hours,
  state,
  address,
  post_code,
  receipt_template_id,
  sale_layout_id
) {
  return {
    name,
    city,
    fulfill_method,
    country,
    phone_number,
    email,
    company_name,
    id,
    qr_code,
    extra_info,
    BR_number,
    sst_id_no,
    GST_id,
    website,
    operation_hours,
    state,
    address,
    post_code,
    receipt_template_id,
    sale_layout_id,
  };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "name",
    numeric: true,
    center: false,
    disablePadding: false,
    label: "Name",
  },
  { id: "city", numeric: true, disablePadding: false, label: "City" },
  {
    id: "fulfill_methord",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Fullfill Method ",
  },
  {
    id: "country",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Country",
  },
  {
    id: "phone_number",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "email",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "company_name",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Company Name",
  },
  {
    id: "Events",
    center: true,
    numeric: true,
    disablePadding: false,
    label: "Events ",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    length,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <StyledTableCell
            key={row.id}
            align={`${row.center === true ? "center" : "left"}`}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={length > 0 ? orderBy === row.id : ""}
              direction={length > 0 ? order : ""}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Stores
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <></>
        ) : (
          // <Tooltip title="Delete">
          //   <IconButton aria-label="Delete">
          //     <DeleteIcon />
          //   </IconButton>
          // </Tooltip>
          <Tooltip title="">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: "1px",
    height: "31px",
    fontSize: "11px",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    paddingLeft: "15px",
    paddingRight: "15px",
    overflowX: "auto",
  },
}));

function Store() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const [rows, setRows] = useState([]);
  const [allSaleLayout, setAllSaleLayout] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectId, setSelectId] = useState(null);
  const [opens, setOpens] = useState(false);

  const [allTemplate, setAllTemplate] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [selectedSaleLayout, setSelectedSaleLayout] = useState({});
  const [formData, setformData] = useState({
    name: "",
    city: "",
    post_code: "",
    fulfill_method: "Pick-up",
    online: "1",
    address: "",
    state: "",
    country: "",
    phone_number: "",
    operation_hours: "",
    email: "",
    website: "",
    company_name: "",
    GST_id: "",
    sst_id_no: "",
    BR_number: "",
    extra_info: "",
    qr_code: "",
    sale_layout_id: selectedSaleLayout?.id,
    receipt_template_id: selectedTemplate?.id,
  });
  const onChange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  let url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);
  useEffect(() => {
    let temp = [];
    axios
      .get(`${url}/admin/get-template-list?limit=1000&page=1`)
      .then((res) => {
        res.data.receipt_templates.rows.map((v) => {
          temp.push({ id: v.id, name: v.name });
        });
        setAllTemplate(temp);
      });
  }, []);
  useEffect(() => {
    let temp = [];
    axios
      .get(`${url}/admin/get-ipad-layout-list?limit=1000&page=1`)
      .then((res) => {
        res.data.ipad_layouts.rows.map((v) => {
          temp.push({ id: v.id, name: v.name });
        });
        setAllSaleLayout(temp);
      });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedTemplate();
    setOpen(false);
    setSelectId(null);

    setformData({
      name: "",
      city: "",
      post_code: "",
      fulfill_method: "Pick-up",
      online: "1",
      address: "",
      state: "",
      country: "",
      phone_number: "",
      operation_hours: "",
      email: "",
      website: "",
      company_name: "",
      GST_id: "",
      sst_id_no: "",
      BR_number: "",
      extra_info: "",
      qr_code: "",
    });
    setSelectedSaleLayout("");
    setSelectedSaleLayout("");
  };
  const getData = () => {
    axios
      .get(
        `${url}/admin/get-stores-list?limit=${rowsPerPage}&page=${parseInt(
          page + 1
        )}`
      )
      .then((res) => {
        if (res.data.stores.rows.length > 0) {
          let state = [];
          res.data.stores.rows.map((v, k) => {
            state.push(
              createData(
                v.name,
                v.city,
                v.fulfill_method,
                v.country,
                v.phone_number,
                v.email,
                v.company_name,
                v.id,
                v.qr_code,
                v.extra_info,
                v.BR_number,
                v.sst_id_no,
                v.GST_id,
                v.website,
                v.operation_hours,
                v.state,
                v.address,
                v.post_code,
                v.receipt_template_id,
                v.sale_layout_id
              )
            );
          });
          setRows(state);
          setTotalLength(res.data.stores.count);
        }
      });
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    if (event.target.value === 10) {
      if (page + 1 <= 1) {
        setPage(0);
      } else {
        setPage(page - 1);
      }
    } else if (event.target.value === 25) {
      if (page + 1 <= 5) {
        setPage(0);
      } else {
        setPage(page - 5);
      }
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, totalLength - page * rowsPerPage);

  const handleRequest = async () => {
    if (formData.name === "") {
      toast.error("Please Enter Store Name!");
    } else if (formData.city === "") {
      toast.error("Please Enter City Name!");
    } else if (formData.fulfill_method === "") {
      toast.error("Please Enter Full fill Methord!");
    } else if (formData.address === "") {
      toast.error("Please Enter Address!");
    } else if (formData.state === "") {
      toast.error("Please Enter State!");
    } else if (formData.country === "") {
      toast.error("Please Enter Country!");
    } else if (formData.operation_hours === "") {
      toast.error("Please Enter Operational Hour!");
    } else if (formData.email === "") {
      toast.error("Please Enter Email!");
    } else if (
      !new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      ).test(formData.email)
    ) {
      toast.error("Please Enter Valid Email!");
    } else if (formData.company_name === "") {
      toast.error("Please Enter Company Name!");
    } else if (formData.address === "") {
      toast.error("Please Enter Address!");
    } else if (formData.extra_info === "") {
      toast.error("Please Enter Extra Information!");
    } else if (selectedSaleLayout.id === undefined) {
      toast.error("Please Select Sales Layout");
    } else if (formData.qr_code === "") {
      toast.error("Please Enter Qr Code !");
    } else {
      if (selectId !== null) {
        try {
          toast.success("Updating store...", {
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          let obj = {
            ...formData,
            store_id: selectId,
            sale_layout_id: selectedSaleLayout?.id,
            receipt_template_id: selectedTemplate?.id,
          };
          axios
            .post(`${url}/admin/update-store`, obj)
            .then((res) => {
              if (res.data.message === "store updated successfully.") {
                toast.success("Store Updated Succesfully", {
                  position: "top-right",
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setTimeout(() => {
                  getData();
                }, 200);
                setOpen(false);
                setformData({
                  name: "",
                  city: "",
                  post_code: "",
                  fulfill_method: "Pick-up",
                  online: "1",
                  address: "",
                  state: "",
                  country: "",
                  phone_number: "",
                  operation_hours: "",
                  email: "",
                  website: "",
                  company_name: "",
                  GST_id: "",
                  sst_id_no: "",
                  BR_number: "",
                  extra_info: "",
                  qr_code: "",
                });

                setSelectId(null);
                setSelectedTemplate({});
                setSelectedSaleLayout({});
              } else {
                toast.error(res.data.message, {
                  position: "top-right",
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            })
            .catch((err) => {
              var vals = [];
              if (err.response.data.errors) {
                vals = Object.keys(err.response.data.errors).map(function(key) {
                  let str = err.response.data.errors[key]
                    .replace("This", key)
                    .toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              } else {
                vals = Object.keys(err.response.data.message).map(function(
                  key
                ) {
                  let str = err.response.data.message.toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              }
              toast.error(vals.length > 0 ? vals[0] : vals, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        toast.success("Creating store...", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        try {
          let obj = {
            ...formData,
            sale_layout_id: selectedSaleLayout?.id,
            receipt_template_id: selectedTemplate?.id,
          };
          axios
            .post(`${url}/admin/create-store`, obj)

            .then((res) => {
              setOpen(false);
              setformData({
                name: "",
                city: "",
                post_code: "",
                fulfill_method: "Pick-up",
                online: "1",
                address: "",
                state: "",
                country: "",
                phone_number: "",
                operation_hours: "",
                email: "",
                website: "",
                company_name: "",
                GST_id: "",
                sst_id_no: "",
                BR_number: "",
                extra_info: "",
                qr_code: "",
              });
              setTimeout(() => {
                getData();
              }, 200);

              setSelectId(null);
              setSelectedTemplate({});
              setSelectedSaleLayout({});
              toast.success("Store is created successfully", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              var vals = [];
              if (err.response.data.errors) {
                vals = Object.keys(err.response.data.errors).map(function(key) {
                  let str = err.response.data.errors[key]
                    .replace("This", key)
                    .toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              } else {
                vals = Object.keys(err.response.data.message).map(function(
                  key
                ) {
                  let str = err.response.data.message.toLowerCase();
                  return str[0].toUpperCase() + str.slice(1).toLowerCase();
                });
              }
              toast.error(vals.length > 0 ? vals[0] : vals, {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  const updateHandler = async (row) => {
    setOpen(true);
    setSelectId(row.id);

    setformData({
      name: row.name,
      city: row.city,
      post_code: row.post_code,
      fulfill_method: "Pick-up",
      online: "1",
      address: row.address,
      state: row.state,
      country: row.country,
      phone_number: row.phone_number,
      operation_hours: row.operation_hours,
      email: row.email,
      website: row.website,
      company_name: row.company_name,
      GST_id: row.GST_id,
      sst_id_no: row.sst_id_no,
      BR_number: row.BR_number,
      extra_info: row.extra_info,
      qr_code: row.qr_code,
    });
    if (row?.receipt_template_id !== null) {
      let b = allTemplate.find((e) => e.id === row?.receipt_template_id);
      setSelectedTemplate(b);
    }
    if (row?.sale_layout_id !== null) {
      let a = allSaleLayout.find((e) => e.id === row?.sale_layout_id);
      setSelectedSaleLayout(a);
    }
  };

  const handleCloses = () => {
    setOpens(false);
    setSelectId(null);
  };
  const handleRequests = () => {
    axios
      .get(`${url}/admin/delete-store/${selectId}`)
      .then((res) => {
        setOpens(false);
        toast.success("Store Delete Succesfully!");
        setSelectId(null);
        setTimeout(() => getData(), 300);
      })
      .catch((err) => {
        toast.error("Unable to Delete Store!");
      });
  };

  return (
    <>
      <AlertDialogSlide
        handleRequests={handleRequests}
        opens={opens}
        handleCloses={handleCloses}
        head="Store"
        body="Are You Sure You Want to Delete Store?"
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        // onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {selectId !== null ? "Update" : "Add"} Store
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Name
              </div>
              <TextField
                name="name"
                onChange={(e) => onChange(e)}
                value={formData.name}
                autoFocus
                size="small"
                margin="dense"
                id="currenct"
                placeholder="Name"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                City
              </div>
              <TextField
                name="city"
                onChange={(e) => onChange(e)}
                value={formData.city}
                margin="dense"
                id="currenct"
                placeholder="City"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Post Code
              </div>
              <TextField
                name="post_code"
                onChange={(e) => onChange(e)}
                value={formData.post_code}
                margin="dense"
                id="currenct"
                placeholder="Post"
                type="number"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Fullfill Method
              </div>
              <TextField
                name="fulfill_method"
                disabled={true}
                onChange={(e) => onChange(e)}
                value={formData.fulfill_method}
                margin="dense"
                id="currenct"
                placeholder="Fullfill"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Address
              </div>
              <TextField
                name="address"
                onChange={(e) => onChange(e)}
                value={formData.address}
                margin="dense"
                id="currenct"
                placeholder="Address"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                State
              </div>
              <TextField
                name="state"
                onChange={(e) => onChange(e)}
                value={formData.state}
                margin="dense"
                id="currenct"
                placeholder="State"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Country
              </div>
              <TextField
                name="country"
                onChange={(e) => onChange(e)}
                value={formData.country}
                margin="dense"
                id="currenct"
                placeholder="Country"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Phone Number *
              </div>
              <InputMask
                mask="+99 9-9999 9999"
                disabled={false}
                maskChar=" "
                value={formData.phone_number}
                onChange={(e) => {
                  onChange(e);
                }}
              >
                {() => (
                  <TextField
                    name="phone_number"
                    // value={formData.contactNo}
                    // onChange={(e) => {
                    //   const re = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/;
                    //   if (e.target.value.length < 15) {
                    //     if (e.target.value === "" || re.test(e.target.value)) {
                    //       onChange(e);
                    //     }
                    //   }
                    // }}
                    margin="dense"
                    id="currenct"
                    placeholder="Number"
                    type="text"
                    fullWidth
                    variant="outlined"
                    className=""
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                  />
                )}
              </InputMask>
              {/* <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Phone Number
              </div>
              <TextField
                name="phone_number"
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value.length < 12) {
                    if (e.target.value === "" || re.test(e.target.value)) {
                      onChange(e);
                    }
                  }
                }}
                value={formData.phone_number}
                margin="dense"
                id="currenct"
                placeholder="Number"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              /> */}
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Operation Hour
              </div>
              <TextField
                name="operation_hours"
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value.length < 12) {
                    if (e.target.value === "" || re.test(e.target.value)) {
                      onChange(e);
                    }
                  }
                }}
                min="0"
                value={formData.operation_hours}
                margin="dense"
                id="currenct"
                placeholder="Hour"
                type="number"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Email
              </div>
              <TextField
                name="email"
                onChange={(e) => onChange(e)}
                value={formData.email}
                margin="dense"
                id="currenct"
                placeholder="Email"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Website
              </div>
              <TextField
                name="website"
                onChange={(e) => onChange(e)}
                value={formData.website}
                margin="dense"
                id="currenct"
                placeholder="Website"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Company Name
              </div>
              <TextField
                name="company_name"
                onChange={(e) => onChange(e)}
                value={formData.company_name}
                margin="dense"
                id="currenct"
                placeholder=" Company Name"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                GST Id
              </div>
              <TextField
                name="GST_id"
                onChange={(e) => onChange(e)}
                value={formData.GST_id}
                margin="dense"
                id="currenct"
                placeholder="GST"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                SST ID NO
              </div>
              <TextField
                name="sst_id_no"
                onChange={(e) => onChange(e)}
                value={formData.sst_id_no}
                margin="dense"
                id="currenct"
                placeholder="SST"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                BR Number
              </div>
              <TextField
                name="BR_number"
                onChange={(e) => onChange(e)}
                value={formData.BR_number}
                margin="dense"
                id="currenct"
                placeholder="BR"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Extra Info
              </div>
              <TextField
                name="extra_info"
                onChange={(e) => onChange(e)}
                value={formData.extra_info}
                margin="dense"
                id="currenct"
                placeholder="Extra"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Receipt Template
              </div>
              <Autocomplete
                value={selectedTemplate}
                onChange={(event, newValue) => {
                  setSelectedTemplate(newValue);
                }}
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
                id="tags-outlined"
                options={allTemplate}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                size="small"
                renderInput={(params) => (
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    {...params}
                    variant="outlined"
                    placeholder="Reciept Tamplate"
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="mb-1"
              >
                Sale Layout
              </div>
              <Autocomplete
                value={selectedSaleLayout}
                onChange={(event, newValue) => {
                  setSelectedSaleLayout(newValue);
                }}
                style={{
                  borderRadius: "0px",
                  height: "31px",
                  fontSize: "11px",
                }}
                id="tags-outlined"
                options={allSaleLayout}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                size="small"
                renderInput={(params) => (
                  <TextField
                    InputProps={{
                      classes: {
                        root: classes.input,
                      },
                    }}
                    {...params}
                    variant="outlined"
                    placeholder="Sale Layout"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                QR Code
              </div>
              <TextField
                name="qr_code"
                onChange={(e) => onChange(e)}
                value={formData.qr_code}
                margin="dense"
                id="currenct"
                placeholder="QR Code"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <div
                style={{ marginBottom: "-5px", fontSize: "11px" }}
                className="-mb-4"
              >
                Sale Layout
              </div>
              <TextField
                onChange={(e) => onChange(e)}
                margin="dense"
              
              value={formData}  id="currenct"
                placeholder="Sale"
                type="text"
                fullWidth
                variant="outlined"
                className=""
                InputProps={{
                  classes: {
                    root: classes.input,
                  },
                }}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: "25px" }}>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleRequest}
            color="primary"
            variant="contained"
            style={{ color: "white" }}
          >
            {selectId !== null ? "Update" : "ADD"}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <div className="p-5 d-flex justify-content-lg-between align-items-center ">
            <Typography variant="h5">Stores</Typography>
            <Button
              style={{ color: "white" }}
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Add New Store
            </Button>
          </div>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                length={rows.length}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={totalLength}
              />
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyleTableRow
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.city}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.fulfill_method}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.country}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.phone_number}
                        </StyledTableCell>{" "}
                        <StyledTableCell align="left">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.company_name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <button
                            onClick={() => updateHandler(row)}
                            class="btn btn-xs btn-default btn-text-primary btn-hover-primary btn-icon btn-icon-primary mr-2"
                            title="Edit details"
                            ng-click="UpdateData(role)"
                          >
                            <span class="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) "
                                  ></path>
                                  <path
                                    d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button>
                          <button
                            class="btn btn-xs btn-default btn-text-primary btn-hover-danger btn-icon btn-icon-danger "
                            title="Delete"
                            onClick={() => {
                              setOpens(true);
                              setSelectId(row.id);
                            }}
                          >
                            <span class="svg-icon svg-icon-md">
                              <svg viewBox="0 0 24 24" version="1.1">
                                <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  ></rect>
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                  ></path>
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                  ></path>
                                </g>
                              </svg>
                            </span>
                          </button>
                        </StyledTableCell>
                      </StyleTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalLength}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}

export default Store;
