import React, { useEffect, useState } from "react";
import moment from "moment";
import clsx from "clsx";
import PropTypes from "prop-types";
import lighten from "@material-ui/core/styles/makeStyles";
import StyledTableCell from "../../../component/styleCell";
import StyleTableRow from "../../../component/styleRow";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  IconButton,
  Tooltip,
  Typography,
  Paper,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  TextField,
  Grid,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { toast } from "react-toastify";
import axios from "axios";
import FilterView from "../../../component/filter";

function createData(payment_type, total_amount, total_discount, total_tax) {
  return { payment_type, total_amount, total_discount, total_tax };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  console.log(array);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "payment_type",
    numeric: true,
    center: false,
    disablePadding: false,
    label: "Payment Type",
  },
  {
    id: "total_amount",
    numeric: true,
    center: false,
    disablePadding: false,
    label: "Total Amount",
  },
  {
    id: "total_discount",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Total Discount",
  },
  {
    id: "total_tax",
    center: false,
    numeric: true,
    disablePadding: false,
    label: "Total Tax",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <StyledTableCell
            key={row.id}
            align={`${row.center === true ? "center" : "left"}`}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Currency
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <></>
        ) : (
          // <Tooltip title="Delete">
          //   <IconButton aria-label="Delete">
          //     <DeleteIcon />
          //   </IconButton>
          // </Tooltip>
          <Tooltip title="">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    paddingLeft: "15px",
    paddingRight: "15px",
    overflowX: "auto",
  },
}));

function SalesCash() {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [time, setTime] = useState("");

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [storeId, setStoreId] = useState("");

  const [totalLength, setTotalLength] = useState();
  const [startingDate, setStartingDate] = useState("");
  const [groupBy, setGroupBy] = useState("");

  const [endingDate, setEndingDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  let url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    getData("", "", "", "", "");
  }, []);

  const getData = (s, e, t, g, store) => {
    axios
      .post(`${url}/admin/sale-cash-report`, {
        start_date: s,
        end_date: e,
        view_by: t,
        group_by: g === true ? 1 : 0,
        store_id: store,
      })
      .then((res) => {
        if (res?.data?.sale_payments_report?.length > 0) {
          let state = [];
          res.data.sale_payments_report.map((v, k) => {
            state.push(
              createData(
                v.payment_type,
                v.total_amount,
                v.total_discount,
                v.total_tax
              )
            );
          });
          setRows(state);
          //   setTotalLength(res.data?.taxes?.count);
        } else {
          setRows([]);
        }
      });
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const onStartingDate = (value) => {
    getData(value, endingDate, time, groupBy);
    setStartingDate(value);
  };
  const onEndingDate = (value) => {
    getData(startingDate, value, time, groupBy);

    setEndingDate(value);
  };

  const onChangeTime = (e) => {
    setTime(e.target.value);
    getData(startingDate, endingDate, e.target.value, groupBy);
  };
  const onChangeGroupBy = (e) => {
    setGroupBy(e.target.checked);
    getData(startingDate, endingDate, time, e.target.checked);
  };
  const onChangeStore = (id) => {
    setStoreId(id);
  };
  const handleSearch = () => {
    getData(startingDate, endingDate, time, groupBy, storeId);
  };

  return (
    <>
      <div className={classes.root}>
        <FilterView
          handleSearch={handleSearch}
          onChangeStore={onChangeStore}
          onChangeGroupBy={onChangeGroupBy}
          time={time}
          onChangeTime={onChangeTime}
          onEndingDate={onEndingDate}
          onStartingDate={onStartingDate}
          mainLabel="Sales Cash"
        />
        <Paper className={classes.paper}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <div className={classes.tableWrapper} style={{ paddingTop: "35px" }}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={totalLength}
              />
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy)).map(
                  (row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyleTableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {row.payment_type}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.total_amount}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.total_discount}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.total_tax}
                        </StyledTableCell>
                      </StyleTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
            {rows?.length === 0 ? (
              <>
                <p
                  style={{
                    paddingTop: "14px",
                    fontSize: "15px",
                    fontWeight: "500",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  NO DATA FOUND
                </p>
              </>
            ) : (
              ""
            )}
          </div>
        </Paper>
      </div>
    </>
  );
}

export default SalesCash;
